import { Component, ContentChild, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { convertItems, inputType, metadatetree, newmetadate, style } from './domain/datatable-domain';
import { MenuItem, SortEvent } from 'primeng/api';
import { UtilService } from 'src/app/services/util.service';
import { FormControl, FormGroup } from '@angular/forms';
import { Menu } from 'primeng/menu';
import { ContextMenu } from 'primeng/contextmenu';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DialogTableComponent } from 'src/app/dialog-table/dialog-table.component';

@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.css'],
  providers: [DynamicDialogRef]

})
export class DatatableComponent implements OnInit {

  @ViewChild('dt1') table: Table
  @Input() dataTable: any[];
  @Input() title: string = ''
  @Input() metadataTable: newmetadate[] = [];
  @Input() metadataTableTree: metadatetree[] = [];
  @Input() filter: boolean = true;
  footer: boolean = false; //Pasar a variable publica
  @Input() filterGlobal: boolean = false;
  @Input() precarga: boolean = false;
  @Input() caption: boolean = false;
  @Input() paginacion: boolean = true;
  @Input() addRow: boolean = false;
  @Input() rowEdit: boolean = false;
  @Input() style: style;
  @Input() rows: any[] = [20, 30, 50]
  @Input() contextMenu: MenuItem[]
  @Input() rowDialog: boolean = false
  @Input() dialogItems: string[] = []
  @Input() preload: boolean = false
  @Input() exportData: boolean = false
  // @Input() function?: (row: any) => any

  //PARA MILTIPLES DATOS

  //PARA DATOS UNICOS DE LA TABLA
  @Input() key: string = ''

  @ContentChild('addHeaderStart', { static: true }) addHeaderStart!: TemplateRef<any>;
  @ContentChild('addBodyStart', { static: true }) addBodyStart!: TemplateRef<any>;
  @ContentChild('addHeaderEnd', { static: true }) addHeaderEnd!: TemplateRef<any>;
  @ContentChild('addBodyEnd', { static: true }) addBodyEnd!: TemplateRef<any>;
  @ContentChild('addHeaderTop', { static: true }) addHeaderTop!: TemplateRef<any>;


  @Output() Row = new EventEmitter<any>()
  onRowSelect(event) {
    this.Row.emit(event.data)
  }

  visible: boolean;
  mensajeDialog: any
  showDialog(row: any, title: string, field: string) {

    if (this.rowDialog) {
      this.visible = true
      this.mensajeDialog = { title: title, message: row[field] ? row[field] : 'NO SE ENCONTRARON ' + title.toUpperCase() }
    }

  }

  showDinamicDialog(row) {
    const conf: DynamicDialogConfig = {
      data: row,
      header: 'Viaje',
      width: '90%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: true,
      closable: true
    }

    const ref = this.util.show(DialogTableComponent, this.ref, conf)

    ref.onClose.subscribe((product?: any) => {
      this.metadataTable.forEach(r => {
        this.group.get(r.field).setValue(product["nombrenave"])
      })
    });
  }

  cols: any[] = [];
  _selectedColumns: any[] = [];
  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {

    if (Array.isArray(val) && val.every(item => item.field)) {
      const selectedFields = val.map(item => item.field);
      this._selectedColumns = this.cols.filter(col => selectedFields.includes(col.field))['field'];
    } else {
      this._selectedColumns = [];
    }
  }

  constructor(public readonly util: UtilService, public ref: DynamicDialogRef) {

  }

  ngAfterContentInit() {

  }

  @ViewChild('menu') menu: Menu;
  @ViewChild('expmenu') expmenu: ContextMenu

  @Output() selectedRowData = new EventEmitter<any>()
  onRowRightClick(event: MouseEvent, rowdata: any) {
    event.preventDefault();
    this.selectedRowData.emit(rowdata);
  }

  selectedProduct: any;
  statuses: any[];
  loading: boolean = false;
  activityValues: number[] = [0, 100];
  filtrosGlobal: any[] = []
  // key: string = ''
  JJ: string = ''
  styleHeader: { [key: string]: string } = {}
  styleBody: { [key: string]: string } = {}
  private nextKey: number = 1;//DANIEL200824}
  originalMetadataTable


  metadata: newmetadate[] = []
  data: any[] = []
  load: boolean = false
  ngOnChanges(changes: SimpleChanges): void {

    this.footer = false

    this.metadata = this.metadataTable
    const conversionList: convertItems[] = [
      { old: 'decimal', new: 'numeric' },
      { old: 'int', new: 'numeric' },
      { old: 'dropdown', new: 'text' },
      { old: 'text', new: 'text' },
      { old: 'icon', new: 'text' }
    ]

    function convertInputType(type?: inputType): string {
      if (!type) return '';
      const conversion = conversionList.find(r => r.old === type as inputType);
      return conversion ? conversion.new : '';
    }

    this.metadata = this.metadata.map(r => ({
      ...r,
      type: convertInputType(r.inputType)
    }))

    this.load = this.preload

    if (this.load === true && this.dataTable.length === 0) {

      for (let index = 0; index < 5; index++) {
        const item = {}; // Crear un nuevo objeto vacío para cada iteración

        this.metadata.forEach(r => {
          item[r.field] = null; // Agregar un campo al objeto con valor null (o vacío)
        });

        this.data.push(item); // Agregar el objeto al array 'data'
      }
    } else {
      this.data = this.dataTable
    }

    if (this.data.length > 20 && this.paginacion == true) { this.footer = true }
    
  }
  totales: boolean = false
  ngOnInit() {

    // if (this.dataTable.length > 20) { this.footer = true }
    if (this.style) {
      if (this.style.header) {
        const r = this.style.header
        this.styleHeader = {};
        if (r.align) { this.styleHeader['text-align'] = r.align }
        if (r.size) { this.styleHeader['font-size'] = r.size }
        if (r.justify) { this.styleHeader['justify-content'] = r.justify }
      }
      if (this.style.body) {
        const r = this.style.body
        this.styleBody = {};
        if (r.align) { this.styleBody['text-align'] = r.align }
        if (r.size) { this.styleBody['font-size'] = r.size }
        if (r.justify) { this.styleBody['justify-content'] = r.justify }
      }
    }

    if (this.dataTable.length > 0) {
      this.loading = false
      const maxKey = Math.max(...this.dataTable.map(row => parseInt(row[this.key], 10) || 0), this.nextKey);//DANIEL200824
      this.nextKey = maxKey + 1;
    }

    this.metadata.forEach(r => {
      if (r.rowFooter) {
        this.totales = true
      }
    })

    this.caption = this.filterGlobal === true || this.addRow === true || this.title != '' ? true : false

    this.metadataTable.forEach(r => {
      this.filtrosGlobal.push(r.field)
      this.cols.push(r)
    })

    this.originalMetadataTable = JSON.parse(JSON.stringify(this.metadataTable));
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) result = -1;
      else if (value1 != null && value2 == null) result = 1;
      else if (value1 == null && value2 == null) result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string') result = value1.localeCompare(value2);
      else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

      return event.order * result;
    });
  }

  clonedProducts: { [s: string]: any } = {};
  editingNew: boolean = false
  onRowEditInit(product: any) {
    this.clonedProducts[product[this.key]] = { ...product };
    this.initForm()
    setTimeout(() => {
      this.setValue(product)
    }, 0);
  }

  group: FormGroup
  initForm() {
    const controls = {};
    this.metadataTable.forEach(item => {
      controls[item.field] = new FormControl(null);
    });
    this.group = new FormGroup(controls);
  }

  setValue(row) {
    this.metadataTable.forEach(item => {
      if (item.inputType == 'autocomplete' || item.inputType == 'dropdown') {
        if (item.dropdown) {
          this.group.get(item.field).setValue(item.dropdown.find(option => option.label === row[item.field]));
        }
      }
      if (item.inputType == 'text' || item.inputType == 'int') {
        this.group.get(item.field).setValue(row[item.field]);
      }
    });
  }

  onRowEditSave(product: any, index: number) {
    setTimeout(() => {
      this.setValue(product)
    }, 0);

    setTimeout(() => {
      this.table.editingRowKeys[product[this.key]] = false
    }, 1000);

    // delete this.clonedProducts[product[this.key]]
  }

  onRowEditCancel(product: any, index: number) {
    this.dataTable[index] = this.clonedProducts[product[this.key]]
    delete this.clonedProducts[product[this.key]]
    //this.valued=false;

    this.dataTable = this.dataTable.filter(r => r !== undefined && r !== null)
  }

  generateUniqueKey(): string {//DANIEL200824
    // Busca la clave máxima actual en dataTable y genera una nueva clave a partir de ella
    const maxKey = Math.max(...this.dataTable.map(row => parseInt(row[this.key], 10) || 0), this.nextKey);
    this.nextKey = maxKey + 1;
    return this.nextKey.toString();
  }

  addEmptyRow() {

    let newRow: any;

    if (this.dataTable.length > 0) {
      const firstRow = this.dataTable[0];
      newRow = Object.keys(firstRow).reduce((row, key) => {
        row[key] = '';
        return row;
      }, {});

      newRow[this.key] = parseInt(this.generateUniqueKey());
    } else {
      newRow = { [this.key]: parseInt(this.generateUniqueKey()) };
    }

    this.dataTable = [...this.dataTable, newRow];
    const ultimo = parseInt(newRow[this.key])

    this.table.editingRowKeys[ultimo] = true
  }

  convertToDecimal(value: any, decimalPlaces: number = 2): string {
    const numberValue = parseFloat(value);

    // Verifica si el valor es un número válido
    if (isNaN(numberValue)) return '0.00';

    // Usa toFixed para asegurarse de que el número tenga los decimales correctos
    const fixedNumber = numberValue.toFixed(decimalPlaces);

    // Usa una expresión regular para agregar separadores de miles
    const [integerPart, decimalPart] = fixedNumber.split('.');
    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // Devuelve el número con separadores de miles y los decimales
    return decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;
  }

  viewData() {
    console.log('DATATABLE', this.dataTable)
    console.log('PRECARGA', this.precarga)
    console.log('KEY', this.key)
    console.log('COLS', this.cols)
    console.log('FILTRO GLOBAL', this.filtrosGlobal)
    console.log('METADATATABLE', this.metadataTable)
    console.log('CAPTION', this.caption)
    console.log('TABLE PRIME', this.table)
    if (this.group) {
      console.log('GROUP', this.group);
      console.log('GROUP VALUE', this.group.value);
    }

  }

  handleDropdownChange(metadata: any, children: string) {

    this.metadataTable.forEach(row => {
      const originalRow = this.originalMetadataTable.find(r => r.field === row.field);
      if (originalRow) {
        row.dropdown = JSON.parse(JSON.stringify(originalRow.dropdown));
      }
    });

    // Aplicar el filtro solo al dropdown correspondiente
    // this.metadataTable.forEach(row => {
    //   if (row.field === children) {
    //     console.log('Filtrando row.dropdown:', row.dropdown);
    //     row.dropdown = row.dropdown.filter(item => item.tipo === this.group.value[metadata].label);
    //   }
    // });

  }

  getTotal(data: any[], field: string): number {
    let total = 0
    data.forEach(r => {
      total += r[field] ? r[field] : 0
    })
    return total
  }


  generateStyle(data: any): { [key: string]: string } {
    let value = {}
    if (data) {
      if (data.align) { value['text-align'] = data.align }
      if (data.size) { value['font-size'] = data.size }
      if (data.justify) { value['justify-content'] = data.justify }
    }
    return value
  }

  getRowFooter(data: any[], field: string, type: string, datatype): string {
    let total = 0
    if (type == 'sum') {
      data.forEach(r => {
        total += r[field] ? r[field] : 0
      })

      return this.formatNumber(total, datatype)
    }

    return total.toString()
  }

  export() {
    let nuevadata = []

    this.dataTable.forEach(row => {
      const newItem = {};

      this.metadata.forEach(e => {
        newItem[e.title.toUpperCase()] = row[e.field];
      });

      nuevadata.push(newItem);
    });

    const data = [{ data: nuevadata, cabeceras: [] }]
    this.util.ExportarExcel(data, 'Reportes Comercial.xlsx', false)
  }

  clear(table: Table) {
    table.clear();
  }

  getDataRow(data: any, type: inputType = 'text'): any {
    switch (type) {
      case 'text' || 'dropdown':

        break;
      case 'decimal':

        return this.formatNumber(data, type)

        break;
      case 'int':
        console.log(this.formatNumber(data, type))
        return this.formatNumber(data, type)
        break;
      default:
        break;
    }

    return ''
  }

  formatNumber(value: any, type: string): string {

    const numberValue = parseFloat(value);

    // Verifica si el valor es un número válido
    if (isNaN(numberValue)) return type === 'decimal' ? '0.00' : '0';

    // Define la cantidad de decimales según el tipo
    let decimalPlaces = type === 'decimal' ? 2 : (Math.floor(numberValue) === numberValue ? 0 : 1);

    // Usa toFixed para asegurarse de que el número tenga los decimales correctos
    const fixedNumber = numberValue.toFixed(decimalPlaces);

    // Usa una expresión regular para agregar separadores de miles
    const [integerPart, decimalPart] = fixedNumber.split('.');
    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // Devuelve el número con separadores de miles y los decimales
    return decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;
  }

  validateOptionsTable() {

  }
}
