// import { DialogConfig } from '@angular/cdk/dialog';
import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { newmetadate, style } from 'src/app/shared/components/datatable/domain/datatable-domain';

@Component({
  selector: 'app-dialog-table',
  templateUrl: './dialog-table.component.html',
  styleUrls: ['./dialog-table.component.css']
})
export class DialogTableComponent {
  estilos: style = {
    header: {
      align: 'left',
      justify: 'center',
      size: '10px'
    },
    body: {
      size: '9px'
    }
  }
  constructor(public readonly data: DynamicDialogConfig, public readonly ref: DynamicDialogRef) {

  }

  serviciosytarifas_NaveViajePorPuerto: any[] = []
  fila:any
  key:any
  ngOnInit() {

    console.log("dd:  ", this.data.data)

    if (this.data.data) {
      this.serviciosytarifas_NaveViajePorPuerto = this.data.data
      console.log("Los datos de Servicios: ", this.serviciosytarifas_NaveViajePorPuerto);
    } else {
      console.log("No hay datos de SERVICIOS o this.datos es null");
    }

  }


  metadatableNaveViajePorPuerto: newmetadate[] = [
    { title: 'CodigoViaje', field: 'codigoviaje', filterType: 'text', inputType: 'text', inputStatus: 'lock' },
    { title: 'Nombre_Nave', field: 'nombrenave', filterType: 'text', inputType: 'text', inputStatus: 'lock' },
    { title: 'Viaje_Vuelo', field: 'viaje_vuelo', filterType: 'text', inputType: 'text', inputStatus: 'lock' },
    { title: 'Transportista', field: 'transportista', filterType: 'text', inputType: 'text', popup: 'y' },
    { title: 'Puerto', field: 'puerto', filterType: 'text', inputType: 'text', inputStatus: 'lock' },
    { title: 'ETA_ETD', field: 'eta_etd', filterType: 'text', inputType: 'date', inputStatus: 'lock' },
    { title: 'Fecha_Cierre_Direccionamiento', field: 'fechacierredireccionamiento', filterType: 'text', inputType: 'date' },
    { title: 'Fecha_Nave_Entregada', field: 'fechanaveentregada', filterType: 'text', inputType: 'date' },
    { title: 'Estado', field: 'estado', filterType: 'text', inputType: 'text' },
    { title: 'Feeder', field: 'feeder', filterType: 'boolean', inputType: 'checkbox' },
  ]

  row: any
  close() {
    if (this.row){
      this.ref.close(this.row)
      this.fila=this.row
      console.log("Datos en el dialog: ",this.fila)

//       console.log("Nueva NAVE:", this.fila["nombrenave"])
//       // Aquí asignas el valor de `key`
//       this.key = 13398; // Asegúrate de que este valor es el identificador correcto

//       const nombreNaveValue = this.fila["nombrenave"];

//       // Encuentra la fila correspondiente usando `this.key`
//       const rowIndex = this.serviciosytarifas_NaveViajePorPuerto.findIndex(row => row.codigoviaje === this.key);

//       if (rowIndex > -1) {
//         this.serviciosytarifas_NaveViajePorPuerto[rowIndex].nombrenave = nombreNaveValue;
//         console.log(`Fila con codigoviaje ${this.key} actualizada con el nuevo nombre de nave: ${nombreNaveValue}`);
//       } else {
//         console.log(`No se encontró una fila con codigoviaje ${this.key}`);
// }
    }
  }


}
