<div class="main">

    <p-table #dt1 [value]="data" [dataKey]="key" [rows]="rows[0]" [showCurrentPageReport]="true"
        [rowsPerPageOptions]="rows" [loading]="loading" [paginator]="footer"
        currentPageReportTemplate=" {first} de {totalRecords}" [globalFilterFields]="filtrosGlobal"
        selectionMode="single" editMode="row" styleClass="p-datatable-sm p-datatable-gridlines" rowGroupMode="subheader"
        groupRowsBy="representative" (onRowSelect)="onRowSelect($event)" [scrollable]="true" scrollHeight="400px"
        [resizableColumns]="true">

        <ng-template pTemplate="caption" *ngIf="caption">
            <div class="flex componentes-header">
                <div *ngIf="filterGlobal">
                    <span class="p-input-icon-left ml-auto">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="dt1.filterGlobal($event.target['value'], 'contains')"
                            placeholder="Buscar" class="p-inputtext-sm" />
                    </span>
                </div>
                <button pButton class="p-button-outlined" icon="pi pi-filter-slash" (click)="clear(dt1)"
                    *ngIf="filter"></button>

                <button pButton type="button" icon="pi pi-plus" (click)="addEmptyRow()" class="add-button"
                    *ngIf="addRow"></button>
                <!-- <button pButton type="button" icon="pi pi-eye" (click)="viewData()" class="add-button"></button> -->

                <div class="title-table">
                    {{title}}
                </div>
            </div>
        </ng-template>

        <ng-template pTemplate="header">
            <ng-container *ngTemplateOutlet="addHeaderTop"></ng-container>
            <tr class="header-table">
                <ng-container *ngTemplateOutlet="addHeaderStart"></ng-container>
                <th *ngIf="metadataTableTree.length != 0"></th>
                <th [pSortableColumn]="item.field" pResizableColumn *ngFor="let item of metadata" class="header">
                    <div class="flex align-items-center items-header" [ngStyle]="styleHeader">
                        {{item.title}}
                        <p-sortIcon [field]="item.field"></p-sortIcon>
                        <p-columnFilter type="{{item['type']}}" field="{{item.field}}" display="menu" *ngIf="filter"
                            appendTo="body"></p-columnFilter>
                    </div>
                </th>
                <th *ngIf="rowEdit"></th>
                <ng-container *ngTemplateOutlet="addHeaderEnd"></ng-container>
            </tr>
        </ng-template>

        <ng-template pTemplate="groupheader" let-rowdata let-rowIndex="rowIndex" let-expanded="expanded"
            *ngIf="metadataTableTree.length != 0 && !load">
            <tr class="body-table" (contextmenu)="onRowRightClick($event, rowdata)" #rowcontext>
                <td>
                    <button type="button" pButton pRipple [pRowToggler]="rowdata"
                        class="p-button-text p-button-rounded p-button-plain mr-2"
                        [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                </td>
                <td *ngFor="let item2 of metadataTable; let i = index" class="body">
                    <span class="font-bold ml-2">{{rowdata[item2.field]}}</span>
                </td>
                <ng-container *ngTemplateOutlet="addBodyEnd; context: { row: rowdata }"></ng-container>
            </tr>

            <p-contextMenu #expmenu [model]="contextMenu" [global]="false" appendTo="body"
                [target]="rowcontext"></p-contextMenu>
        </ng-template>


        <!-- <p-menu #menu [model]="items" [popup]="true" appendTo="body"></p-menu> -->

        <ng-template pTemplate="rowexpansion" let-rowdata let-rowIndex="rowIndex" class="template-tree"
            *ngIf="metadataTableTree.length != 0">
            <tr class="header-table-tree">
                <th></th>
                <th *ngFor="let item of metadataTableTree" class="header-tree">
                    <div class="flex align-items-center items-header">
                        {{item.title}}
                    </div>
                </th>
            </tr>
            <tr class="body-table" *ngFor="let item of rowdata['listadoserviciosasociados']">
                <td class="body-tree-footer"></td>
                <td *ngFor="let item2 of metadataTableTree" class="body-tree">
                    {{item[item2.field]}}
                </td>
            </tr>
            <tr>
                <td class="body-tree-footer"></td>
                <td *ngFor="let item2 of metadataTableTree" class="body-tree-footer">
                    {{item2.type == 'numeric' ? getTotal(rowdata['listadoserviciosasociados'],item2.field):'' }}
                </td>
            </tr>
        </ng-template>


        <ng-template pTemplate="body" let-rowdata let-i="rowIndex" let-editing="editing" let-expanded="expanded">

            <tr class="body-table" [pSelectableRow]="rowdata" [pEditableRow]="rowdata" *ngIf="!load">
                <td *ngIf="metadataTableTree.length != 0 && !load">
                    <button type="button" pButton pRipple [pRowToggler]="rowdata"
                        class="p-button-text p-button-rounded p-button-plain"
                        [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                </td>
                <ng-container *ngTemplateOutlet="addBodyStart; context: { row: rowdata }"></ng-container>
                <td *ngFor="let item2 of metadata" class="body">
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <form [formGroup]="group">
                                <input pInputText [(ngModel)]="rowdata[item2.field]" *ngIf="item2.inputType == 'text'"
                                    [type]="item2.inputType" required [formControlName]="item2.field">
                                <p-dropdown [(ngModel)]="rowdata[item2.field]" *ngIf="item2.inputType == 'dropdown'"
                                    [options]="item2.dropdown" class="datos-body" appendTo="body"
                                    [style]="{'width':'100%'}" [formControlName]="item2.field">
                                </p-dropdown>

                                <p-inputNumber [(ngModel)]="rowdata[item2.field]" *ngIf="item2.inputType == 'int'"
                                    mode="decimal" [useGrouping]="false" [disabled]="item2.inputStatus === 'lock'"
                                    [formControlName]="item2.field">
                                </p-inputNumber>

                                <p-inputNumber [(ngModel)]="rowdata[item2.field]" *ngIf="item2.inputType == 'decimal'"
                                    mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="5"
                                    [disabled]="item2.inputStatus === 'lock'" [formControlName]="item2.field">
                                </p-inputNumber>

                                <div class="datos-body">
                                    <p-checkbox [(ngModel)]="rowdata[item2.field]" [binary]="true" inputId="binary"
                                        *ngIf="item2.inputType == 'checkbox'">
                                    </p-checkbox>
                                </div>


                                <p-calendar [(ngModel)]="rowdata[item2.field]" *ngIf="item2.inputType == 'date'"
                                    appendTo="body" [formControlName]="item2.field">
                                </p-calendar>


                                <p-dropdown [(ngModel)]="rowdata[item2.field]" [options]="item2.dropdown"
                                    appendTo="body" placeholder="Nombre" *ngIf="item2.inputType === 'autocomplete'"
                                    [filter]="true" [showClear]="true"
                                    (onChange)="handleDropdownChange(item2.field,item2.children)"
                                    [formControlName]="item2.field" optionLabel="label">
                                </p-dropdown>

                                <p-button style="width: 15%; margin: 0 10px 0 10px;" icon="pi-caret-down"
                                    (click)="showDinamicDialog(item2.datosDialogTable)" *ngIf="item2.popup == 'y'">
                                </p-button>

                            </form>

                        </ng-template>

                        <ng-template pTemplate="output">

                            <span class="datos-body"
                                *ngIf="item2.inputType == 'text' || item2.inputType == 'dropdown' || item2.inputType == 'autocomplete' || item2.inputType == 'autocomplete_1'"
                                [ngStyle]="generateStyle(item2.style)">

                                {{rowdata[item2.field]}}
                            </span>

                            <!-- <span class="datos-body">
                                {{getDataRow(rowdata[item2.field],item2.inputType)}}
                            </span> -->

                            <!--Para numeros decimales-->
                            <span class="datos-body" *ngIf="item2.inputType == 'decimal'">
                                {{ rowdata['motivo'] ? (rowdata['motivo'] === 'TEUS' || rowdata['motivo'] === 'Budget'
                                ||
                                rowdata['motivo'].includes('Teus') ?
                                formatNumber(rowdata[item2.field], 'int') : rowdata['motivo'] === '% Gasto' ?
                                formatNumber((rowdata[item2.field] * 100).toFixed(0), 'int') :
                                convertToDecimal(rowdata[item2.field])):convertToDecimal(rowdata[item2.field])
                                }}
                            </span>

                            <span class="datos-body" *ngIf="item2.inputType == 'int'">
                                {{ formatNumber(rowdata[item2.field], 'int')}}
                            </span>

                            <!--Para checkbox-->
                            <div class="datos-body">
                                <p-checkbox [(ngModel)]="rowdata[item2.field]" [binary]="true" inputId="binary"
                                    *ngIf="item2.inputType == 'checkbox'">
                                </p-checkbox>
                            </div>

                            <span class="datos-body" *ngIf="item2.inputType == 'date'">
                                {{util.formatearfecha(rowdata[item2.field],'dd/mm/yyyy')}}
                            </span>

                            <span class="datos-body" *ngIf="item2.inputType == 'datetime'">
                                {{util.formatearfecha(rowdata[item2.field],'dd/mm/yyyy hh:mm')}}
                            </span>
                            <div class="row-button-table"
                                *ngIf="item2.inputType == 'dialog' && rowdata[item2.field] != null">
                                <p>{{rowdata[item2.field]}}</p>
                                <p-button pRipple icon="pi pi-eye" (click)="showDialog(rowdata,item2.title,item2.field)"
                                    class="p-button-rounded p-button-text p-button-danger" pTooltip="Ver Detalle"
                                    [tooltipPosition]="'left'"></p-button>
                            </div>

                            <div class="row-button-table" *ngIf="item2.inputType == 'icon'">
                                <img [src]="item2.function(rowdata)" alt="" style="height: 20px;">
                            </div>

                            <div class="row-button-table" *ngIf="item2.inputType == 'texticon'"
                                style="display: flex; justify-content: center;align-items: center;gap: 10px;">
                                <div style="width: 20px;text-align: right">{{rowdata[item2.field]}}</div>
                                <img [src]="item2.function(rowdata)" alt="" style="height: 15px;">
                            </div>

                            <div class="row-button-table" *ngIf="item2.inputType == 'textcolor'"
                                style="display: flex; justify-content: center;align-items: center;gap: 10px;">
                                <div [style]="'color: ' + item2.function(rowdata) "> {{ rowdata['motivo'] ?
                                    (rowdata['motivo'] === 'TEUS' || rowdata['motivo'] === 'Budget'
                                    ||
                                    rowdata['motivo'].includes('Teus') ?
                                    formatNumber(rowdata[item2.field], 'int') : rowdata['motivo'] === '% Gasto' ?
                                    formatNumber((rowdata[item2.field] * 100).toFixed(0), 'int') :
                                    convertToDecimal(rowdata[item2.field])):convertToDecimal(rowdata[item2.field])
                                    }}</div>
                            </div>

                        </ng-template>
                    </p-cellEditor>
                </td>
                <td *ngIf="rowEdit">
                    <div class="flex align-items-center justify-content-center gap-2">
                        <p-button *ngIf="!editing" pRipple pInitEditableRow icon="pi pi-pencil"
                            (click)="onRowEditInit(rowdata)" class="p-button-rounded p-button-text"></p-button>
                        <p-button *ngIf="editing" pRipple pSaveEditableRow icon="pi pi-check"
                            (click)="onRowEditSave(rowdata,i)"
                            class="p-button-rounded p-button-text p-button-success mr-2"></p-button>
                        <p-button *ngIf="editing" pRipple pCancelEditableRow icon="pi pi-times"
                            (click)="onRowEditCancel(rowdata, i)"
                            class="p-button-rounded p-button-text p-button-danger"></p-button>
                    </div>
                </td>

                <ng-container *ngTemplateOutlet="addBodyEnd; context: { row: rowdata }"></ng-container>

            </tr>

            <tr *ngIf="load">
                <td *ngFor="let item2 of metadata"><p-skeleton></p-skeleton></td>
            </tr>
        </ng-template>

        //FOOTER -- SE AGREGARON TOTALES
        <ng-template pTemplate="footer">
            <tr *ngIf="totales">
                <td *ngFor="let item2 of metadata" class="body" style="text-align: center;">
                    {{item2.rowFooter ? getRowFooter(data,item2.field,item2.rowFooter,item2.inputType) + ' '
                    :'' }}
                </td>
            </tr>
        </ng-template>


        <!-- <ng-template pTemplate="rowexpansion" let-product>
            <tr>
                <td colspan="7">
                    <div class="p-3">
                        <p-table [value]="product['listadoserviciosasociados']" dataKey="id">
                            <ng-template pTemplate="header">
            <tr class="header-table-tree">
                <th *ngFor="let item of metadataTableTree" class="header-tree">
                    <div class="flex align-items-center items-header">
                        {{item.title}}
                    </div>
                </th>

            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-rowdata>
            <tr class="body-table">
                <td *ngFor="let item2 of metadataTableTree" class="body">
                    {{rowdata[item2.field]}}
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
</td>
</tr>
</ng-template> -->












        <ng-template pTemplate="summary" *ngIf="exportData">
            <div style="display: flex;justify-content: right;" *ngIf="exportData">
                <p-button icon="pi pi-file-excel" styleClass="p-button-sm p-button-success p-button-outlined"
                    (onClick)="export()"></p-button>
            </div>
        </ng-template>
    </p-table>




    <p-dialog header="Header" [(visible)]="visible" [style]="{width: '60vw'}" appendTo="body">
        <ng-template pTemplate="header">
            <span class="text-xl font-bold title-dialog"></span>
        </ng-template>

        <ng-container *ngIf="visible">
            <div class="title-dialog">{{mensajeDialog.title}}</div>
            <div class="dialog-mesaje">
                {{mensajeDialog.message}}
            </div>
        </ng-container>
    </p-dialog>

</div>