import { Observable } from "rxjs";
import { BuscarNavesItinerarioRequest, InsertItinerarioRefferRequest, InsertServicioRefferRequest, ListarDropdownRequest, ListarItinerariosRefferRequest, ListarNavesRequest, ListarNuevasReservaRequest, ListarNuevosClientesRequest, ListarServiciosRefferRequest, NuevaReservaRequest, NuevoPerfilClienteRequest, operacionesNaveRequest, transbordoReservaRequest, transbordoTrackingRequest, UpdateNuevosClienteRequest, updatePasswordRefferRequest, updateReservaRefferRequest } from "../comercial/reffer/domain/reffer-request";
import { BuscarNavesItinerarioResponse, GeneralResponse, ListarDropdownResponse, ListarItinerariosRefferResponse, ListarNavesResponse, ListarNuevaReservaResponse, ListarNuevosClientesResponse, ListarServiciosRefferResponse, transbordoTrackingResponse } from "../comercial/reffer/domain/reffer-response";
import { CrudOrdenesVentaRequest, listAllOVRequest, ListarOrdenesVentaRequest, listNaveViajePorPuertoRequest } from "../comercial/orden-venta/domain/request/comercial-orden-venta-request";
import { CrudOrdenesVentaResponse, ListarOrdenesVentaResponse } from "../comercial/orden-venta/domain/response/comercial-orden-venta-response";
import { searchTrackingShipmentRequest } from "../comercial/tracking-shipment/domain/tracking-shipment-request";
import { searchTrackingShipmentResponse } from "../comercial/tracking-shipment/domain/tracking-shipment-response";

export abstract class ComercialRepository {

    abstract NuevoPerfil(fd: NuevoPerfilClienteRequest): Observable<GeneralResponse>;
    abstract ListarNuevosClientes(fd: ListarNuevosClientesRequest): Observable<ListarNuevosClientesResponse>;
    abstract UpdateNuevosCliente(fd: UpdateNuevosClienteRequest): Observable<GeneralResponse>;
    abstract ListarDropdown(fd: ListarDropdownRequest): Observable<ListarDropdownResponse>;
    abstract NuevaReserva(fd: NuevaReservaRequest): Observable<GeneralResponse>;
    abstract ListarNuevasReserva(fd: ListarNuevasReservaRequest): Observable<ListarNuevaReservaResponse>;
    abstract InsertServicioReffer(fd: InsertServicioRefferRequest): Observable<GeneralResponse>;
    abstract ListarServiciosReffer(fd: ListarServiciosRefferRequest): Observable<ListarServiciosRefferResponse>;
    abstract InsertItinerarioReffer(fd: InsertItinerarioRefferRequest): Observable<GeneralResponse>;
    abstract ListarItinerariosReffer(fd: ListarItinerariosRefferRequest): Observable<ListarItinerariosRefferResponse>;
    abstract ListarNaves(): Observable<ListarDropdownResponse>;
    abstract updateReservaReffer(fd: updateReservaRefferRequest): Observable<GeneralResponse>;
    abstract updatePasswordReffer(fd: updatePasswordRefferRequest): Observable<GeneralResponse>;
    abstract BuscarNavesItinerario(fd: BuscarNavesItinerarioRequest): Observable<BuscarNavesItinerarioResponse>;
    abstract ListarNavesNext(fd: ListarNavesRequest): Observable<ListarNavesResponse>;
    abstract operacionesNave(fd: operacionesNaveRequest): Observable<GeneralResponse>;
    abstract transbordoReserva(fd: transbordoReservaRequest): Observable<GeneralResponse>;
    abstract transbordoTracking(fd: transbordoTrackingRequest): Observable<transbordoTrackingResponse>;
    abstract searchTrackingShipment(fd: searchTrackingShipmentRequest): Observable<searchTrackingShipmentResponse>;

    abstract ListarOrdenesVenta(fd: ListarOrdenesVentaRequest): Observable<ListarOrdenesVentaResponse>;
    abstract CrudOrdenesVenta(fd: CrudOrdenesVentaRequest): Observable<CrudOrdenesVentaResponse>;
    abstract listAllOV(fd: listAllOVRequest): any;
    //Daniel 03/09/24
    abstract listNaveViajePorPuerto(fd: listNaveViajePorPuertoRequest): any;
 
 
 
}
