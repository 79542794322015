import { Injectable, OnInit, Output } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { ConfirmComponent } from '../shared/components/confirm/confirm.component';
import { ExportComponent } from '../shared/components/export/export.component';
import { AlertdialogComponent } from '../shared/components/alertdialog/alertdialog.component';
import * as XLSX from 'xlsx-js-style';
import { MessageConfirmRquest } from '../shared/components/confirm/domain/message-confirm-request';
import { FormateoFechas, Parametros } from './domain/services-domain';
import { ListarTodosWebRequest, requestejecutivo } from '../proforma/domain/request/proforma_request';
import { ListParametros } from '../proforma/domain/response/proforma_response';
import { ProformaRepository } from '../proforma/domain/proforma.repository';
import { LinearScale } from 'chart.js';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';



//@ts-ignore
@Injectable({
  providedIn: 'root'
})

export class UtilService {

  @Output() parametros: Parametros[] = [];
  constructor(private readonly dialog: MatDialog,
    private readonly notifier: MatSnackBar,
    private readonly bottomSheet: MatBottomSheet,
    private readonly proformaService: ProformaRepository,
    private readonly dialogService: DialogService
 
  ) {
    this.ngOnInit()
  }


  async Parametros(): Promise<Parametros[]> {
    const datos: Parametros[] = []
    try {
      const requestEjecutivo = <requestejecutivo>{}
      requestEjecutivo.entc_Codigo = 3

      const response = await this.proformaService.listarejecutivo(requestEjecutivo).toPromise()

      for (let i = 0; i < response.datos.result.length; i++) {
        const item: Parametros = {
          tipo: 'EJC',
          nombre: response.datos.result[i].nombreejecutivo,
          codigo: response.datos.result[i].codejecutivo.toString(),
          via: null,
          regimen: null,
          lineaNegocio: null
        };
        datos.push(item);
      }
    } catch (error) {

    }

    try {
      const request2 = <ListarTodosWebRequest>{}
      request2.tipo = null
      request2.codigo = null
      const response2 = await this.proformaService.ListarTodosWeb(request2).toPromise()

      for (let i = 0; i < response2.datos.result.length; i++) {
        const item: Parametros = {
          tipo: response2.datos.result[i].tipo,
          nombre: response2.datos.result[i].nombre.toUpperCase(),
          codigo: response2.datos.result[i].codigo.toString(),
          via: response2.datos.result[i].via,
          regimen: response2.datos.result[i].regimen,
          lineaNegocio: response2.datos.result[i].lineaNegocio

        };
        datos.push(item);
      }
    } catch (error) {

    }

    return datos
  }

  async ngOnInit(): Promise<void> {
    this.parametros = await this.Parametros()
  }

  async loadParametros(): Promise<Parametros[]> {
    const datos: Parametros[] = [];
    try {
      const requestEjecutivo: requestejecutivo = { entc_Codigo: 3 };
      const response = await this.proformaService.listarejecutivo(requestEjecutivo).toPromise();

      for (const item of response.datos.result) {
        datos.push({
          tipo: 'EJC',
          nombre: item.nombreejecutivo,
          codigo: item.codejecutivo.toString(),
          via: null,
          regimen: null,
          lineaNegocio: null
        });
      }
    } catch (error) {
      console.error('Error fetching ejecutivos:', error);
    }

    try {
      const request2: ListarTodosWebRequest = { tipo: null, codigo: null };
      const response2 = await this.proformaService.ListarTodosWeb(request2).toPromise();

      for (const item of response2.datos.result) {
        datos.push({
          tipo: item.tipo,
          nombre: item.nombre.toUpperCase(),
          codigo: item.codigo.toString(),
          via: item.via,
          regimen: item.regimen,
          lineaNegocio: item.lineaNegocio
        });
      }
    } catch (error) {
      console.error('Error fetching todos web:', error);
    }

    return datos;
  }
  async getParametros(): Promise<Parametros[]> {
    // Opcional: Puedes manejar datos en caché aquí si es necesario
    if (this.parametros.length === 0) {
      this.parametros = await this.loadParametros();
    }
    return this.parametros;
  }

  // async getParametros(): Promise<Parametros[]> {
  //   setTimeout(() => {

  //   }, 1000);
  //   return this.parametros
  // }

  openModal(classComponent: any,
    options: { [s: string]: string | boolean | number },
    returnReference: boolean = false
  ): Observable<any> | any {

    const reference: MatDialogRef<typeof classComponent> =
      this.dialog.open(
        classComponent,
        options
      );

    if (returnReference) {

      return reference;

    } else {

      return reference.afterClosed();

    }

  }

  showMessage(message: string): void {
    this.notifier.open(message, null,
      {
        duration: 5000, horizontalPosition: 'end',
        verticalPosition: 'top', panelClass: ['blue-snackbar']
      })
  }

  showMessageError(message: string): void {
    this.notifier.open(message, null,
      {
        duration: 5000, horizontalPosition: 'end',
        verticalPosition: 'top', panelClass: ['message-error']
      })
  }

  confirm(message: string = ""): Observable<any> {

    const referenceConfirm = this.openModal(
      ConfirmComponent,
      {
        with: "320px",
        disableClose: true,
      },
      true
    );

    if (message) {

      referenceConfirm.componentInstance.message = message;
    }
    return referenceConfirm.afterClosed();

  }

  ShowMessageConfirm(data: MessageConfirmRquest): Observable<any> {

    const referenceConfirm = this.openModal(
      ConfirmComponent,
      {
        with: "320px",
        disableClose: true,
      },
      true
    );

    if (data.mensaje) {

      referenceConfirm.componentInstance.message = data.mensaje;
    }
    return referenceConfirm.afterClosed();

  }


  openSheet() {
    this.bottomSheet.open(ExportComponent);

  }

  formatearfecha(fecha, formato: FormateoFechas) {
    if (fecha === null || fecha === "") {
      return ""
    }
    var fechaFormateada = '';
    var fechaDate: Date
    if (fecha != null || fecha != '' || fecha != undefined) {
      fecha = fecha instanceof Date ? fecha : new Date(fecha);


      if (formato === 'dd/mm/yyyy') {
        fechaFormateada = String(fecha.getDate()).padStart(2, '0') + '/' + String(fecha.getMonth() + 1).padStart(2, '0') + '/' + fecha.getFullYear();
      } else if (formato === 'mm/yyyy') {
        const nombresMeses = ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'];
        const mesAbreviado = nombresMeses[fecha.getMonth()];
        fechaFormateada = mesAbreviado + '/' + fecha.getFullYear();
      } else if (formato === 'dd-mm-yyyy') {
        fechaFormateada = String(fecha.getDate()).padStart(2, '0') + '-' + String(fecha.getMonth() + 1).padStart(2, '0') + '-' + fecha.getFullYear();
      } else if (formato === 'yyyy-mm-dd') {
        fechaFormateada = fecha.getFullYear() + '-' + String(fecha.getMonth() + 1).padStart(2, '0') + '-' + String(fecha.getDate()).padStart(2, '0');
      } else if (formato === 'yyyy/mm/dd') {
        fechaFormateada = fecha.getFullYear() + '/' + String(fecha.getMonth() + 1).padStart(2, '0') + '/' + String(fecha.getDate()).padStart(2, '0');
      } else if (formato === 'hh:mm') {
        fechaFormateada = String(fecha.getHours()).padStart(2, '0') + ':' + String(fecha.getMinutes()).padStart(2, '0');
      } else if (formato === 'hh:mm:ss') {
        fechaFormateada = String(fecha.getHours()).padStart(2, '0') + ':' + String(fecha.getMinutes()).padStart(2, '0') + ':' + String(fecha.getSeconds()).padStart(2, '0');
      } else if (formato === 'dd/mm/yyyy hh:mm') {
        fechaFormateada = String(fecha.getDate()).padStart(2, '0') + '/' + String(fecha.getMonth() + 1).padStart(2, '0') + '/' + fecha.getFullYear() + ' ' +
          String(fecha.getUTCHours()).padStart(2, '0') + ':' +  // Cambio aquí
          String(fecha.getMinutes()).padStart(2, '0');
      } else if (formato === 'yyyy-MM-ddTHH:mm:ss') {
        fechaFormateada = fecha.toISOString().slice(0, 19).replace('T', ' ');
      } else if (formato === 'fechaInicioHora') {
        fechaDate = new Date(fecha.getFullYear(), fecha.getMonth(), fecha.getDate(), 0, 0, 0);
      } else if (formato === 'fechaFinHora') {
        fechaDate = new Date(fecha.getFullYear(), fecha.getMonth(), fecha.getDate(), 23, 59, 59);
      } else if (formato === 'dd/mm') {
        fechaFormateada = String(fecha.getDate()).padStart(2, '0') + '/' + String(fecha.getMonth() + 1).padStart(2, '0');
      } else if (formato === 'yyyy-mm-dd hh:mm:ss') {

        if (fecha == '' || fecha == undefined) {
          fechaFormateada = ''
        } else {


          const dia = String(fecha.getDate()).padStart(2, '0');
          const mes = String(fecha.getMonth() + 1).padStart(2, '0');
          const anio = fecha.getFullYear();
          const horas = String(fecha.getHours()).padStart(2, '0');
          const minutos = String(fecha.getMinutes()).padStart(2, '0');
          const segundos = String(fecha.getSeconds()).padStart(2, '0');

          fechaFormateada = `${dia}/${mes}/${anio} ${horas}:${minutos}:${segundos}`;
        }

      }
      else if (formato === 'fechaconT') {

        const dia = String(fecha.getDate()).padStart(2, '0');
        const mes = String(fecha.getMonth() + 1).padStart(2, '0');
        const anio = fecha.getFullYear();
        const horas = String(fecha.getHours()).padStart(2, '0');
        const minutos = String(fecha.getMinutes()).padStart(2, '0');
        const segundos = String(fecha.getSeconds()).padStart(2, '0');

        fechaFormateada = `${anio}-${mes}-${dia}T${horas}:${minutos}:${segundos}`;



      }
    } else {
      fechaFormateada = ''
    }

    return fechaFormateada;
  }

  formateaFechaHoras(fecha, formato: string) {

    var fechaFormateada = '1900-01-01 00:00:00'
    if (fecha != "") {
      if (formato === 'fechaInicioHora') {
        fechaFormateada = fecha.getFullYear() + '-' +
          (fecha.getMonth() + 1).toString().padStart(2, '0') + '-' +
          fecha.getDate().toString().padStart(2, '0') + ' 00:00:00';
      } else if (formato === 'fechaFinHora') {
        fechaFormateada = fecha.getFullYear() + '-' +
          (fecha.getMonth() + 1).toString().padStart(2, '0') + '-' +
          fecha.getDate().toString().padStart(2, '0') + ' 23:59:59';
      }
    }
    return fechaFormateada
  }

  // NuevoMensaje(titulo, mensaje, icono, referencia, cerrar) {

  //   const dialogRef = this.dialog.open(AlertdialogComponent, {
  //     // width: '250px',
  //     data: { message: mensaje, icono: icono, titulo: titulo, },
  //     panelClass: 'transparent-background'
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log('El cuadro de diálogo se cerró', result);
  //     if (cerrar == true) {
  //       referencia.close();
  //     }
  //   });
  // }

  NuevoMensaje(titulo?: string, mensaje?: string, icono?: string, referencia?: any, cerrar: boolean = false, autocerrar: boolean = false, tiempo: number = 3000, tipo: string = 'ok') {

    const dialogRef = this.dialog.open(AlertdialogComponent, {
      data: { message: mensaje, icono: icono, titulo: titulo, tipo: tipo },
      panelClass: 'transparent-background'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (cerrar) {
        referencia?.close();  // El operador de opción segura (?.) evita errores si referencia es null o undefined
      }
    });

    if (autocerrar == true) {
      setTimeout(() => {
        dialogRef.close();
      }, tiempo);
    }
  }

  ShowMessage(options: {
    titulo?: string,
    mensaje?: string,
    icono?: string,
    cerrar?: boolean,
    referencia?: any,
    autocerrar?: boolean,
    tiempo?: number,
    tipo?: "ok" | "warning" | "error",
    detalle?: string
  }) {
    // Crear un objeto que contenga las opciones y utilice los valores predeterminados si no se proporcionan
    const showMessageOptions = {
      titulo: options.titulo || '',
      mensaje: options.mensaje || '',
      icono: options.icono || 'icono-predeterminado',
      tipo: options.tipo || 'ok',
      referencia: options.referencia,
      cerrar: options.cerrar || false,
      autocerrar: options.autocerrar || false,
      tiempo: options.tiempo || 3000,
      detalle: options.detalle || ''
    };

    // Abre el diálogo de alerta con las opciones proporcionadas
    const dialogRef = this.dialog.open(AlertdialogComponent, {
      data: { message: showMessageOptions.mensaje, icono: showMessageOptions.icono, titulo: showMessageOptions.titulo, tipo: showMessageOptions.tipo, detalle: showMessageOptions.detalle },
      panelClass: 'transparent-background'
    });

    // Suscribirse al evento afterClosed del diálogo
    dialogRef.afterClosed().subscribe(result => {
      // Cerrar la referencia si se proporciona la opción de cerrar
      if (showMessageOptions.cerrar) {
        showMessageOptions.referencia?.close();
      }
    });

    // Autocerrar el diálogo después del tiempo especificado si se proporciona la opción de autocerrar
    if (showMessageOptions.autocerrar) {
      setTimeout(() => {
        dialogRef.close();
      }, showMessageOptions.tiempo);
    }
  }

  formatearNumero(numero: number): string {
    // Verificar si el número tiene decimales
    const tieneDecimales = numero % 1 !== 0;

    // Convertir el número a string y separar las partes enteras y decimales
    const [parteEntera, parteDecimal] = numero.toFixed(2).split('.');

    // Agregar separadores de miles a la parte entera
    const parteEnteraFormateada = parteEntera.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // Si el número original tiene decimales, mantenerlos, de lo contrario agregar '00'
    const parteDecimalFormateada = tieneDecimales ? '.' + parteDecimal : '.00';

    // Unir las partes y devolver el resultado
    return parteEnteraFormateada + parteDecimalFormateada;
  }

  ExportarExcel(colecciones: { data: any[], cabeceras: string[] }[], FileName: string = "documento.xlsx", footer: boolean = true) {
    console.log(colecciones)
    if (colecciones.length > 0) {
      const wb: XLSX.WorkBook = XLSX.utils.book_new();

      for (let index = 0; index < colecciones.length; index++) {
        const data = colecciones[index].data;
        this.NewExcelSheet(data, ['ITEM', 'PRODUCTO', 'PRECIO'], wb, footer);
      }

      XLSX.writeFile(wb, FileName);

    } else {
      console.log('No hay colecciones para exportar');
    }
  }

  NewExcelSheet(dato: any[], cabeceras: string[], wb, footer: boolean = true) {
    console.log(dato)
    if (dato.length > 0) {

      if (dato.length > 1) {
        let datos: any[] = []

        datos = dato.slice()

        let inicioy = 0
        let iniciox = 0
        let iniciototales = 7

        //ESTABLECEMOS EL TAMAÑO DE LA HOJA
        const y = datos.length  // caso excepcion por las cabeceras
        const x = (datos.length > 0 ? Object.keys(datos[0]).length : 0);

        const data = [];
        for (let i = 1; i <= y + inicioy + 30; i++) { //PARA LA CANTIDAD DE FILAS SUMAR TITULOS + DATA + 2 ESPACIOS LIBRES
          const row = [];
          for (let j = 1; j <= x + 6 + iniciox; j++) { //PARA LA CANTIDAD DE COLUMNAS AUMENTAR 1 O 2
            row.push('');
          }
          data.push(row);
        }

        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data);

        const colinicio = this.getExcelColumnName(iniciox)

        //CABECERAS
        XLSX.utils.sheet_add_json(ws, [datos[0]], { header: Object.keys(datos[0]), skipHeader: false, origin: colinicio + (inicioy + 1) });

        //CONTENIDO
        for (let index = 0; index < datos.length; index++) {
          XLSX.utils.sheet_add_json(ws, [datos[index]], { header: Object.keys(datos[index]), skipHeader: true, origin: colinicio + (inicioy + index + 2) });
        }


        let coorx = x - 1 + (iniciox)
        let coory = y + (inicioy)
        let totales = footer ? (coory + 1) : coory

        //ESTILOS FLETE
        for (var i in ws) {
          // console.log(ws[i]);
          if (typeof ws[i] != 'object') continue;
          let cell = XLSX.utils.decode_cell(i);

          //ESTILOS PARA TODA LA HOJA
          ws[i].s = {
            font: {
              name: 'Aptos Narrow',
              sz: 10,
            },
            fill: {
              patternType: 'solid',
              fgColor: { rgb: 'ffffff' },
            },
            alignment: {
              vertical: 'center',
              horizontal: 'center',
              wrapText: false,
            }
          };

          if (cell.r <= totales && cell.c <= coorx && cell.r >= inicioy && cell.c >= iniciox) {
            ws[i].s.border = {
              top: { style: 'medium' },
              bottom: { style: 'medium' },
              left: { style: 'medium' },
              right: { style: 'medium' },
            }

            if (cell.r == inicioy) {
              ws[i].s.fill = {
                patternType: 'solid',
                fgColor: { rgb: '156082' },
              }
              ws[i].s.font = {
                name: 'Aptos Narrow',
                sz: 10,
                bold: true,
                color: { rgb: 'ffffff' }
              }
              ws[i].s.border.left = cell.c == iniciox ? { style: 'medium' } : { style: 'thin' }
              ws[i].s.border.right = cell.c == coorx ? { style: 'medium' } : { style: 'thin' }
            }
            else if (cell.r == totales) {
              // if (cell.c == iniciox) {
              //   ws[i].v = 'TOTALES'
              // }

              // ws[i].z = '#,##0.00';

              // for (var k = iniciototales; k <= (coorx - 2); k++) { // -2 caso especifico
              //   const col = this.getExcelColumnName(k);
              //   XLSX.utils.sheet_set_array_formula(ws, {
              //     s: { c: k, r: totales }, // Coordenada de inicio
              //     e: { c: k, r: totales }  // Coordenada de fin
              //   }, "SUM(" + col + "" + (inicioy + 2) + ":" + col + "" + (totales) + ")",)
              // }

              // ws[i].s.font.bold = { bold: true }

              ws[i].s.border.left = cell.c == iniciox ? { style: 'medium' } : { style: 'thin' }
              ws[i].s.border.right = cell.c == coorx ? { style: 'medium' } : { style: 'thin' }
              ws[i].s.border.top = { style: 'none' }
              ws[i].s.border.bottom = { style: 'thin' }
            } else {
              ws[i].s.border = {
                top: { style: 'none' },
                bottom: { style: 'none' },
                left: cell.c == iniciox ? { style: 'medium' } : { style: 'thin' },
                right: cell.c == coorx ? { style: 'medium' } : { style: 'thin' },
              }
            }
          }
        }

        const colsConfig = Array.from({ length: coorx + 1 }, (_, index) => ({
          wch: index === 0 ? 20 : (index === 0 ? 20 : (index === 1 ? 27 : (index === 2 ? 50 : (index === 5 ? 50 : 18))))
        }));

        ws['!cols'] = colsConfig;

        const rangoflete1 = { s: { r: totales, c: iniciox }, e: { r: totales, c: iniciox + (iniciototales - 1) } };
        // ws['!merges'] = [rangoflete1];

        XLSX.utils.book_append_sheet(wb, ws);
      }

    } else {
      console.log('El array está vacío - no hay datos para exportar');
    }
  }

  getExcelColumnName(index: number): string {
    let columnName = "";
    while (index >= 0) {
      columnName = String.fromCharCode(index % 26 + 65) + columnName;
      index = Math.floor(index / 26) - 1;
    }
    return columnName;
  }

  show(classComponent: any, ref: DynamicDialogRef, options: DynamicDialogConfig) {
   
    ref = this.dialogService.open(classComponent, options);

    ref.onMaximize.subscribe((value) => {
    });


    return ref
  }

}

export interface DataExportExcel {
  ColeccionExcel: ItemsDataExportExcel
}

export interface ItemsDataExportExcel {
  data: any[]
  cabeceras: string[]
}