import { ListaArchivosDescargaProformaInterna, ListaDetalleProforma, ListaGastoProformaInterna, listaejecutivos, listcontacto } from './../../../../proforma/domain/response/proforma_response';
import { ProformaArchivosRequest, ProformaCostoServicioRequest, ProformaGastoAdicionalesRequest, ProformaInternaBultosRequest, ProformaInternaRequest, contactoRequest, contenedorRequest, listaClienteRequest, listaProformaInternaRequest, proformaDetalleRequest, requestAlmacen, requestBultos, requestGenerico, requestTransportista, requestcontacto, requestejecutivo, requestimagen, rucInternoRequest, sunatRequest } from './../../../../proforma/domain/request/proforma_request';
import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { Form, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, forkJoin, map, startWith } from 'rxjs';
import { MetadataTable } from 'src/app/interfaces/metada-table.interface';
import { Proforma } from 'src/app/proforma/domain/entity-proforma';
import { ProformaRepository } from 'src/app/proforma/domain/proforma.repository';
import { guardaRequisisionesRequest, imoRequest, incoterRequest, proformaRequest, puertoRequest } from 'src/app/proforma/domain/request/proforma_request';
import { ListContenedor, ListImo, ListIncoter, ListItem, ListPrtoD, ListPrtoO, ListaContactoJ, ListaCostoProformaInterna, ListaProformaInterna, ListaTransportista, ProformaInternaCostoResponse } from 'src/app/proforma/domain/response/proforma_response';
import { UtilService } from 'src/app/services/util.service';
import { DatosCompartidosService } from 'src/app/services/datos-compartidos.service';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { requestrequerimiento } from 'src/app/requerimiento/domain/request/request_requerimiento';
import { Listarrequerimientodetalle, responserequerimientodet, tablaBultos, tablaCosto, tablaGasto } from 'src/app/requerimiento/domain/response/response_requerimiento';
import { MatCheckboxChange } from '@angular/material/checkbox';
import autoTable, { Row } from 'jspdf-autotable';
import jsPDF from 'jspdf';
import { StorageService } from 'src/app/services/storage.service';
import { each } from 'chart.js/dist/helpers/helpers.core';
import { ArchivosService } from 'src/app/services/archivos.service';
import { ContactoComponent } from 'src/app/proforma/presentacion/components/contacto/contacto.component';
import { ElementRef } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-requerimientomant',
  templateUrl: './requerimientomant.component.html',
  styleUrls: ['./requerimientomant.component.css']
})
export class RequerimientomantComponent {
  constructor(
    private util: UtilService,
    private readonly proformaService: ProformaRepository,
    private sharedService: DatosCompartidosService,
    private readonly router: Router,
    private readonly storage: StorageService,
    private archivosService: ArchivosService
  ) { }
  archivosSeleccionados = this.archivosService.archivosSeleccionados;
  //FILAS SELECCIONADAS
  selectedRows: any[] = [];
  debeOcultar: boolean = true;
  condicionMuestra: boolean = false
  selectAll(event: MatCheckboxChange) {
    if (event.checked) {
      this.selectedRows = [...this.dataContenedor];
    } else {
      this.selectedRows = [];
    }
  }

  onRowSelect(event: MatCheckboxChange, row: any) {
    if (event.checked) {
      this.selectedRows.push(row);
    } else {
      const index = this.selectedRows.indexOf(row);
      if (index !== -1) {
        this.selectedRows.splice(index, 1);
      }
    }
  }

  //PARA SABER SI ES PRICING O EJECUTIVO
  pricing: boolean = false

  Sedebever: boolean
  SedebeverAereo: boolean
  titulotabpricing: string
  //AUTOCOMPLETE TRANSPORTISTA
  transportistaCont = new FormControl();
  filteredOptions: Observable<ListPrtoD[]>;
  @ViewChild('autoTransportista') autoTransportista: MatAutocomplete;

  //COMPONENTE PARA CREAR CONTACTO NUEVO
  contactoComponent = ContactoComponent;

  //CODIGO TRANSPORTISTA TABLA
  transportistaTabla: number
  //filtra agentes
  opcionesFiltradas: string[] = [];
  agenteControl = new FormControl();

  //TABLAS QUE SE AGREGAN UNO A UNO
  listaTablaContenedor: Listarrequerimientodetalle
  listaTemporalContenedor: Listarrequerimientodetalle[] = []
  dataContenedor: Listarrequerimientodetalle[] //Listarrequerimientodetalle
  //------------------------------------------
  listaTablaCosto: tablaCosto
  listaTemporalCosto: tablaCosto[] = []
  dataCosto: tablaCosto[]
  //-----------------------------------------
  listaTablaGasto: tablaGasto
  listaTemporalGasto: tablaGasto[] = []
  dataGasto: tablaGasto[]
  //tabla BULTOS
  listaTablaBultos: tablaBultos
  listaTemporalBultos: tablaBultos[] = []
  dataBultos: tablaBultos[]

  //CONDICIONdataBultos
  condicion: boolean = false
  tablaDatos2: any[] = [];
  columnas: string[] = ['seleccionar', 'contenedor', 'cantidad'];

  tablaDatos: any[] = [];
  groupRequerimiento: FormGroup
  groupContenedor: FormGroup
  dataTable: Proforma[]
  group: FormGroup
  groupPricing: FormGroup
  //-------------
  tabContenedor: FormGroup
  tabCosto: FormGroup
  tabGasto: FormGroup
  filasSeleccionadas: any[] = [];

  //valores Razon Social y RUC
  codigoRazonSocial: string;
  razonSocial: string;
  ruc: string;
  codigoIncoter: string;
  limitedOptions: string[] = [];
  listaAgente: string[] = []

  listaTransportista: string[] = [];
  listaTodosTransportistas: ListaTransportista[] = [];
  //Via Regimen
  listRazonSocial: string[] = [];
  proformaInterna: ProformaInternaRequest[] = []
  proformaInternaBultos: ProformaInternaBultosRequest[] = []
  proformaCostoServicio: ProformaCostoServicioRequest[] = []
  proformaGastoAdicionales: ProformaGastoAdicionalesRequest[] = []
  proformaArchivos: ProformaArchivosRequest[] = []
  listReg: ListItem[] = [];
  listVia: ListItem[] = [];
  listImo: ListImo[] = [];
  listContenedor: ListContenedor[] = [];
  listCondicion: ListItem[] = [];

  listEstado: ListItem[] = [];

  //LISTA PUERTOS
  listPrtoO: ListPrtoO[] = [];
  listPrtoD: ListPrtoD[] = [];

  //Lista Incoter
  listIncoter: ListIncoter[] = [];
  listUnidadMedida: ListIncoter[] = [];
  listAlmacen: ListIncoter[] = [];
  listAlmacenGasto: ListIncoter[] = [];
  listAgente: ListIncoter[] = [];
  listTransportistaP: ListIncoter[] = [];
  listCostoServicioConcepto: ListIncoter[] = [];
  listaMoneda: ListIncoter[] = [];
  listGastoTipoGasto: ListIncoter[] = [];
  listGastoConcepto: ListIncoter[] = [];


  listpaquetes: ListIncoter[] = []


  Modo: string;
  ReqNro: number;

  condicionGasto: string
  condicionCosto: string

  inicializaFormulario(accion: string, row: ListaProformaInterna) {

    if (accion == "I") {
      this.groupRequerimiento = new FormGroup({
        nrorequerimiento: new FormControl(null, null),
        ptoOrigen: new FormControl(null, Validators.required),
        ptoDestino: new FormControl(null, Validators.required),
        estado: new FormControl('001', null),
        razonSocial: new FormControl(null, Validators.required),
        ruc: new FormControl(null, Validators.required),
        tCarga: new FormControl(null, null),
        modalidad: new FormControl(null, Validators.required),
        imo: new FormControl(null, null),
        mercaderia: new FormControl(null, null),
        regimen: new FormControl(null, Validators.required),
        via: new FormControl(null, Validators.required),
        fechaCarga: new FormControl(null, null),
        fechaVigencia: new FormControl(null, null),
        dirEntrega: new FormControl(null, null),
        dirRecojo: new FormControl(null, null),
        tarjet: new FormControl(null, null),
        incoter: new FormControl(null, Validators.required),
        un: new FormControl(null, null),
        observacionEjec: new FormControl(null, null),
        observacionIngles: new FormControl(null, null),
        datosAdicionales: new FormControl(null, null),
        contacto: new FormControl(null, null),
        diasLibres: new FormControl(null, null)
      });

      this.groupContenedor = new FormGroup({
        contenedor: new FormControl(null, null),
        cantidad: new FormControl(null, null)
      })

      this.group = new FormGroup({
        cantBultos: new FormControl(0, [Validators.required]),
        largo: new FormControl(null, null),
        ancho: new FormControl(null, null),
        alto: new FormControl(null, null),
        peso: new FormControl(null, null),
        volumen: new FormControl(null, null),
        umvolumen: new FormControl('006', null),
        umpeso: new FormControl('003', null)
      })

      this.groupPricing = new FormGroup({
        agente: new FormControl(null, null),
        transportistaP: new FormControl(null, null),
        tiempoTransito: new FormControl(null, null),
        unidadMedida: new FormControl(null, null),
        fleteMonto: new FormControl(null, null),
        fleteCosto: new FormControl(null, null),
        diasLibres: new FormControl(null, null),
        almacen: new FormControl(null, null),
        fechaVigenciaIni: new FormControl(null, null),
        fechaVigenciaFin: new FormControl(null, null),
        moneda: new FormControl(null, null),
        pesoRecurrente: new FormControl(null, null),
        observacionesEjecutivosPricing: new FormControl(null, null),
        //transportistaCont: new FormControl(null,null),
        //valorCont: new FormControl(null,null),
      })

      //tabContendor
      this.tabContenedor = new FormGroup({
        transportistaCont: new FormControl(null, null),
        valorCont: new FormControl(null, null),
        diasLibresCont: new FormControl(null, null),
        costo: new FormControl(null, null),
        fechaVigenciaIni: new FormControl(null, null),
        fechaVigenciaFin: new FormControl(null, null),
        paquetes: new FormControl(null, null)
      })

      this.tabCosto = new FormGroup({
        conceptoCos: new FormControl(null, null),
        montoCos: new FormControl(null, null),
        baseCos: new FormControl(null, null),
        aplicacionCos: new FormControl(null, null),
        costo: new FormControl(null, null),
        detalleCos: new FormControl(null, null),
        tdi: new FormControl(null, null),
        carga: new FormControl(null, null),
        almacenMonto: new FormControl(null, null),
        aereolineaCosto: new FormControl(null, null),
        rutaCosto: new FormControl(null, null),
        frecuenciaCosto: new FormControl(null, null),
      })

      this.tabGasto = new FormGroup({
        tipoGasto: new FormControl(null, null),
        conceptoGasto: new FormControl(null, null),
        monto: new FormControl(null, null),
        costo: new FormControl(null, null),
        tdiGasto: new FormControl(null, null),
        almacen: new FormControl(null, null),
        aereolineaGasto: new FormControl(null, null),
        notaGasto: new FormControl(null, null),
        pesoC: new FormControl(null, null),
      })
    } else {

      var tipocarga: string
      if (row.creQ_CargaPeligrosa == false) {

        tipocarga = "2"
      } else {
        tipocarga = "1"
      }
      this.Modo = 'U'
      // console.log('IMPRIME AL ACTUALIZAR')
      // console.log(row.observacionEjecutivo)
      this.groupRequerimiento = new FormGroup({
        nrorequerimiento: new FormControl(row.creQ_NROREQ, null),
        ptoOrigen: new FormControl(row.puertoOrigen, null),
        ptoDestino: new FormControl(row.puertoDestino, null),
        estado: new FormControl(row.cprO_CODESTADO, null),
        razonSocial: new FormControl(null, null),
        ruc: new FormControl(null, null),
        tCarga: new FormControl(tipocarga, null),
        modalidad: new FormControl(row.tipO_CODFLE, null),
        imo: new FormControl(row.conS_CODIMO, null),
        mercaderia: new FormControl(row.creQ_MERCADERIA, null),
        regimen: new FormControl(row.conS_CodRGM, null),
        via: new FormControl(row.conS_CodVia, null),
        fechaCarga: new FormControl(row.creQ_FECHACARGA, null),
        fechaVigencia: new FormControl(row.creQ_FecTarifaHasta, null),
        dirEntrega: new FormControl(row.creQ_DireccionEntrega, null),
        dirRecojo: new FormControl(row.creQ_DireccionRecojo, null),
        tarjet: new FormControl(row.creQ_TARGET, null),
        incoter: new FormControl(row.conS_CODINC, null),
        diasLibres: new FormControl(row.creQ_DIASLIBRES, null),
        un: new FormControl(row.creq_un, null),
        observacionEjec: new FormControl(row.observacionEjecutivo, null),
        observacionIngles: new FormControl(row.observacionesIngles, null),
        datosAdicionales: new FormControl(row.datoS_ADICIONALES, null),
        contacto: new FormControl(row.entC_CodContacto, null),

      });

      this.codigoRazonSocial = row.entC_CodCliente.toString();

      this.groupContenedor = new FormGroup({
        contenedor: new FormControl(null, null),
        cantidad: new FormControl(null, null)
      })

      this.group = new FormGroup({
        cantBultos: new FormControl(null, [Validators.required]),
        largo: new FormControl(null, null),
        ancho: new FormControl(null, null),
        alto: new FormControl(null, null),
        peso: new FormControl(null, null),
        volumen: new FormControl(null, null),
        umvolumen: new FormControl('006', null),
        umpeso: new FormControl('003', null)
      })
      this.groupPricing = new FormGroup({
        agente: new FormControl(row.entC_AgentePricing, null),
        transportistaP: new FormControl(row.entC_CodAgente, null),
        tiempoTransito: new FormControl(row.creQ_TIEMPOTRAN, null),
        unidadMedida: new FormControl(row.tipO_CODUNM, null),
        fleteMonto: new FormControl(row.creQ_FLETEMONTO, null),
        fleteCosto: new FormControl(row.creQ_FLETECOSTO, null),
        diasLibres: new FormControl(row.diaslibreconfirmado, null),
        almacen: new FormControl(row.entC_Almacen, null),
        fechaVigenciaIni: new FormControl(row.creQ_FecVigenciaIni, null),
        fechaVigenciaFin: new FormControl(row.creQ_FecVigenciaFin, null),
        diasDisponibles: new FormControl(row.diasdisponiblerequisicion, null),
        moneda: new FormControl(row.tipO_CodMND, null),
        observaciones: new FormControl(row.creQ_Observaciones, null),
        observacionesEjecutivosPricing: new FormControl(row.observacionEjecutivo, null),
        pesoRecurrente: new FormControl(row.creQ_PesoRecurrente, null),
      })
      //tabContendor
      this.tabContenedor = new FormGroup({
        transportistaCont: new FormControl(null, null),
        valorCont: new FormControl(null, null),
        diasLibresCont: new FormControl(null, null),
        fechaVigenciaIni: new FormControl(null, null),
        fechaVigenciaFin: new FormControl(null, null),
        costo: new FormControl(null, null),
        paquetes: new FormControl(null, null)
      })

      this.tabCosto = new FormGroup({
        conceptoCos: new FormControl(null, null),
        montoCos: new FormControl(null, null),
        baseCos: new FormControl(null, null),
        aplicacionCos: new FormControl(null, null),
        costo: new FormControl(null, null),
        detalleCos: new FormControl(null, null),
        tdi: new FormControl(null, null),
        carga: new FormControl(null, null),
        almacenMonto: new FormControl(null, null),
        aereolineaCosto: new FormControl(null, null),
        rutaCosto: new FormControl(null, null),
        frecuenciaCosto: new FormControl(null, null),
      })

      this.tabGasto = new FormGroup({
        tipoGasto: new FormControl(null, null),
        conceptoGasto: new FormControl(null, null),
        monto: new FormControl(null, null),
        costo: new FormControl(null, null),
        tdiGasto: new FormControl(null, null),
        almacen: new FormControl(null, null),
        aereolineaGasto: new FormControl(null, null),
        notaGasto: new FormControl(null, null),
        pesoC: new FormControl(null, null),
      })
    }


  }

  datos: any
  numeroReq: number //Numero Requerimiento
  ngOnInit(): void {
    //ROL EJECUTIVO = 4
    //ROL PRICING = 8

    const metadata = this.storage.get('rol');
    const rol = this.storage.get('rol');

    if (rol == '8') {
      this.pricing = true;

    } else {
      this.pricing = false;
    }

    console.log(this.pricing)

    if (localStorage.getItem('dato') == null) {
      this.ReqNro = 0;
      this.Modo = "I"
      this.inicializaFormulario(this.Modo, null);
      this.cargaCondicion('FLE')
      this.cargaLista('RGM')
      this.cargaLista('VIA')
      this.cargaImo()
      this.cargaContenedor()
      this.cargaIncoter('INC')
      this.cargaIncoter('CSC')
      this.cargaIncoter('UNM')
      this.cargaIncoter('GAG')
      this.cargaIncoter('GAC')
      this.cargaLista('WEB')
      this.cargaAlmacenGasto('O')
      this.cargaTodosAgentes()
      this.cargaTodosAgente()
      this.cargaTodosAlmacenes()
      this.cargaTodosTransportista()
      this.cargaMonedas()
      this.cargaUnidadMedidaAbreviado()
      this.listadocontacto()

      console.log('tamano de la lsita this.listAgente: ' + this.listAgente.length)
      for (let i = 0; this.listAgente.length > 0; i++) {
        console.log(this.listAgente[i].nombre == 'TAMPA CARGO S.A. SUCURSAL PERUANA' ? 'ENCUENTRA EL AGENTE TAMPA' : 'NO ENCUENTRA EL AGENTE TAMPA')
      }



      this.groupRequerimiento.controls['fechaCarga'].setValue(this.util.formatearfecha(new Date(), 'yyyy-MM-ddTHH:mm:ss'));
      this.groupRequerimiento.controls['fechaVigencia'].setValue(this.util.formatearfecha(new Date(), 'yyyy-MM-ddTHH:mm:ss'));
      //this.cargaTransportista('%')
    } else {
      this.Modo = "U"
      const datosSerializados = localStorage.getItem('dato')// params['datos'];
      this.datos = JSON.parse(datosSerializados); //this.DatosRecibidos;//JSON.parse(decodeURIComponent(datosSerializados));

      //llamada a las funciones para evaluar si las tablas tienen datos
      if (this.datos.condicion == 'LCL/LCL' || this.datos.condicion == 'LCL/FCL') {
        this.condicionMuestra = true
      } else {
        this.condicionMuestra
      }

      this.numeroReq = this.datos.creQ_Numero
      this.agenteSeleccionadoCarga(this.datos.entC_AgentePricing)
      this.almacenSeleccionadoCarga(this.datos.creQ_CODALM)
      this.listaProformaInternaCosto(this.datos.creQ_Numero)
      this.listaProformaInternaGasto(this.datos.creQ_Numero)
      this.listaProformaInternaBultos(this.datos.creQ_Numero)
      this.codigoAgentePricing = this.datos.entC_CodAgentePricing
      this.nombreAgentePricing = this.datos.entC_AgentePricing
      this.codigoAlmacenPricing = this.datos.creQ_CODALM
      this.nombreAlmacenPricing = this.datos.entC_Almacen

      this.listaProformaInternaArchivoDescarga(this.datos.creQ_Numero)

      //this.condicionGasto = this.listaProformaInternaGasto.toString()
      this.ListarDetalleRequirimiento(this.datos.creQ_Numero)

      // console.log(datos)
      // console.log(datos.puertoOrigen)

      localStorage.removeItem('dato'); //PARA QUE ES ESTO?
      this.inicializaFormulario(this.Modo, this.datos);
      this.codPtoDestino = this.datos.pueR_CodDestino
      this.nomPtoDestino = this.datos.puertoDestino
      this.codPtoOrigen = this.datos.pueR_CodOrigen
      this.nomPtoOrigen = this.datos.puertoOrigen
      console.log(this.nomPtoOrigen + " " + this.nomPtoDestino)
      //CARGA AGENT
      this.cargaCondicion('FLE')
      this.cargaLista('RGM')
      this.cargaLista('VIA')
      this.cargaImo()
      this.cargaContenedor()
      this.cargaIncoter('INC')
      this.cargaIncoter('CSC')
      this.cargaIncoter('UNM')
      this.cargaIncoter('GAG')
      this.cargaIncoter('GAC')
      this.cargaLista('WEB')
      this.cargaTodosTransportista()
      this.cargaTodosAgentes()
      this.cargaTodosAgente()
      this.cargaTodosAlmacenes()
      this.cargaMonedas()
      this.cargaUnidadMedidaAbreviado()
      this.listadocontacto()

      const razonSocialControl = this.groupRequerimiento.get('razonSocial');
      const agentePricingControl = this.groupPricing.get('agente')
      const almacenPricingControl = this.groupPricing.get('almacen')
      const agente = this.groupPricing.get('transportistaP')
      //const rucControl = this.groupRequerimiento.get('ruc');
      razonSocialControl.setValue(null); // O razonSocialControl.setValue('');
      agentePricingControl.setValue(null)
      agente.setValue(null)
      // Buscar y seleccionar la opción correspondiente para el puerto origen
      const puertoOrigen = this.datos.puertoOrigen;
      //rucControl.setValue(null); // O rucControl.setValue('');

      setTimeout(() => {
        razonSocialControl.setValue(this.datos.cliente);
        agentePricingControl.setValue(this.datos.entC_AgentePricing)
        almacenPricingControl.setValue(this.datos.entC_Almacen)
        agente.setValue(this.datos.agente)
        this.codigoTransportistaPricing = this.datos.entC_CodAgente
        this.cargaRucInterno('I')
      }, 0);


      for (let j = 0; j < this.listAgente.length; j++) {
        if (this.groupPricing.get('agente').value === this.listAgente[j].codigo) {
          this.groupPricing.get('agente').setValue(this.listAgente[j].nombre)
        }
      }

      //INICIALIZA LAS LISTAS
      //FIN DE LA INICIALIZACION DE LAS LISTAS
      //ESTO LO QUE HACE ES CARGAR LA TABLA
      this.ListarDetalleRequirimiento(this.datos.creQ_Numero)


      this.ReqNro = this.datos.creQ_Numero
      //this.cargaTransportista('%')

      if (this.datos.condicion == 'FCL/FCL') {

        this.titulotabpricing = 'FCL'

        this.Sedebever = false

      }

      if (this.datos.conS_CodVia == '001') {

        this.SedebeverAereo = false
      }

      if (this.datos.conS_CodVia == '002') {

        this.titulotabpricing = 'LCL AEREO'
        this.SedebeverAereo = true
      }

      if (this.datos.condicion == 'LCL/LCL') {

        this.titulotabpricing = 'LCL'

        this.Sedebever = true

      }
      //ESTO INICIA LAS LISTAS]

    }





  }



  metadataTable: MetadataTable[] = [

    { field: "Item", title: "ITEM" },
    { field: "Contenedor", title: "CONTENEDOR" },
    { field: "Cantidad", title: "CANTIDAD" }
  ];

  metaDataTableContenedorOnly: MetadataTable[] = [
    { field: 'dreQ_Seleccionada', title: '', type: 'CHECKBOX' }, // Columna de checkboxes
    { field: 'pacK_Desc', title: 'DESCRIPCION', type: 'TEXT' },
    { field: 'dreQ_Cantidad', title: 'CANTIDAD', type: 'TEXT' }
  ];

  metaDataTableContenedor: MetadataTable[] = [

    { field: 'dreQ_ITEM', title: 'ITEM', type: 'TEXT' },
    { field: 'pacK_Desc', title: 'DESCRIPCION', type: 'TEXT' },
    { field: 'dreQ_Cantidad', title: 'CANTIDAD', type: 'TEXT' },
    { field: 'nombreTransportista', title: 'TRANSPORTISTA', type: 'TEXT' },
    { field: 'dreQ_PrecioUniVenta', title: 'VALOR', type: 'TEXT' },
    { field: 'dreQ_DiasLibres', title: 'DIAS LIBRES', type: 'DATE' },
    { field: 'dreQ_FechaInicio', title: 'FECHA INICIO', type: 'DATE' },
    { field: 'dreQ_FechaFin', title: 'FECHA FIN', type: 'DATE' }

  ];

  metaDataTableCosto: MetadataTable[] = [
    { field: 'aerolinia', title: 'AEROLINEA' },
    { field: 'concepto', title: 'CONCEPTO', type: 'CHECKBOX' },
    { field: 'cS_COSTO', title: 'COSTO', type: 'TEXT' },
    { field: 'cS_MONTO', title: 'VENTA', type: 'TEXT' },
    { field: 'base', title: 'BASE', type: 'TEXT' },
    { field: 'cS_APLICACION', title: 'NOTAS', type: 'TEXT' },
    // { field: 'tdi', title: 'TDI', type: 'TEXT' },
    { field: 'almacenMonto', title: 'ALMACEN MONTO', type: 'TEXT' },
    { field: 'cS_CTOTAL', title: 'COSTO TOTAL', type: 'TEXT' },
    { field: 'rutaCosto', title: 'RUTA', type: 'TEXT' },
    { field: 'frecuenciaCosto', title: 'FRECUENCIA', type: 'TEXT' }
  ];


  metaDataTableCosto2: MetadataTable[] = [
    { field: 'aerolinia', title: 'AEROLINEA' },
    { field: 'concepto', title: 'CONCEPTO', type: 'CHECKBOX' },
    { field: 'cS_MONTO', title: 'VENTA', type: 'TEXT' },
    { field: 'base', title: 'BASE', type: 'TEXT' },
    { field: 'cS_APLICACION', title: 'NOTAS', type: 'TEXT' },
    // { field: 'tdi', title: 'TDI', type: 'TEXT' },
    { field: 'almacenMonto', title: 'ALMACEN MONTO', type: 'TEXT' },
    { field: 'cS_CTOTAL', title: 'COSTO TOTAL', type: 'TEXT' },
    { field: 'rutaCosto', title: 'RUTA', type: 'TEXT' },
    { field: 'frecuenciaCosto', title: 'FRECUENCIA', type: 'TEXT' }
  ];


  metaDataTableGasto: MetadataTable[] = [
    { field: 'gasto', title: 'TIPO DE GASTO', type: 'CHECKBOX' },
    { field: 'concepto', title: 'CONCEPTO', type: 'TEXT' },
    { field: 'cS_MONTO', title: 'VENTA', type: 'TEXT' },
    { field: 'cS_COSTO', title: 'COSTO', type: 'TEXT' },
    // { field: 'gA_TDI', title: 'TDI', type: 'TEXT' },
    // { field: 'almacen', title: 'ALMACEN GASTO', type: 'TEXT' },
    // { field: 'aerolinea', title: 'AEROLINEA'},
    { field: 'nota', title: 'NOTA' },
    { field: 'pesoCargable', title: 'PESOCARGABLE', type: 'TEXT' }
  ];

  metaDataTableGasto2: MetadataTable[] = [
    { field: 'gasto', title: 'TIPO DE GASTO', type: 'CHECKBOX' },
    { field: 'concepto', title: 'CONCEPTO', type: 'TEXT' },
    { field: 'cS_MONTO', title: 'VENTA', type: 'TEXT' },
    // { field: 'gA_TDI', title: 'TDI', type: 'TEXT' },
    // { field: 'almacen', title: 'ALMACEN GASTO', type: 'TEXT' },
    // { field: 'aerolinea', title: 'AEROLINEA'},
    { field: 'nota', title: 'NOTA' },
    { field: 'pesoCargable', title: 'PESOCARGABLE', type: 'TEXT' }
  ];

  metaDataTableBultos: MetadataTable[] = [
    { field: 'creqB_LARGO', title: 'LARGO' },
    { field: 'creqB_ANCHO', title: 'ANCHO' },
    { field: 'creqB_ALTO', title: 'ALTO' },
    { field: 'creqB_PESO', title: 'PESO' },
    { field: 'creqB_DESUNIDMPESO', title: 'UM' },
    { field: 'creqB_VOLUMEN', title: 'VOLUMEN' },
    { field: 'creqB_DESUNIDMVOLUMEN', title: 'UM' },
    { field: 'creqB_CANT', title: 'CANTIDAD' },
    // { field: 'total_CANTIDAD', title: 'CANTIDAD 2' }
  ];


  //Carga las listas de VIA y REGIMEN
  cargaLista(value: string) {
    const requestProforma: proformaRequest = <proformaRequest>{};
    requestProforma.pchrCONS_CodTabla = value;
    this.proformaService.listar(requestProforma).subscribe(response => {
      if (value == "RGM") {
        this.listReg.splice(0, this.listReg.length);
        for (let i = 0; i < response.datos.result.length; i++) {
          const item: ListItem = {
            nombre: response.datos.result[i].conS_Desc_SPA,
            codigo: response.datos.result[i].conS_CodTipo
          };
          this.listReg.push(item);
        }
      } else if (value == "VIA") {
        this.listVia.splice(0, this.listVia.length);
        for (let i = 0; i < response.datos.result.length; i++) {
          const item: ListItem = {
            nombre: response.datos.result[i].conS_Desc_SPA,
            codigo: response.datos.result[i].conS_CodTipo
          };
          this.listVia.push(item);
        }
      } else if (value == "WEB") {
        this.listEstado.splice(0, this.listEstado.length);
        for (let i = 0; i < response.datos.result.length; i++) {
          if (response.datos.result[i].conS_Desc_SPA != 'PERDIDO') {
            const item: ListItem = {
              nombre: response.datos.result[i].conS_Desc_SPA,
              codigo: response.datos.result[i].conS_CodTipo
            };
            this.listEstado.push(item);
          }
        }
      }
    });
  }

  //CARGA PUERTOS
  cargaPuerto(value: string) {
    console.log('Este es el nombre del formControl: ' + value)
    const values = this.groupRequerimiento.value;

    const requestPuerto: puertoRequest = <puertoRequest>{};
    requestPuerto.pintPUER_Codigo = '';
    requestPuerto.pchrCONS_TabVia = 'VIA';
    requestPuerto.pchrCONS_CodVia = values["via"];
    requestPuerto.pchrPUER_CodEstandar = '';
    requestPuerto.pvchPUER_Nombre = values[value];

    console.log(requestPuerto.pchrCONS_CodVia)
    console.log(requestPuerto.pvchPUER_Nombre)
    if (requestPuerto.pvchPUER_Nombre.length >= 3) {
      this.proformaService.listarPuerto(requestPuerto).subscribe(response => {
        if (value === 'ptoOrigen') {
          this.listPrtoO.splice(0, this.listPrtoO.length); // Eliminar todos los elementos existentes
          for (let i = 0; i < response.datos.result.length; i++) {
            const item: ListPrtoO = {
              nombre: response.datos.result[i].pueR_Nombre,
              codigo: response.datos.result[i].pueR_Codigo
            };
            this.listPrtoO.push(item);
          }
        }
        if (value === 'ptoDestino') {
          this.listPrtoD.splice(0, this.listPrtoD.length); // Eliminar todos los elementos existentes
          for (let i = 0; i < response.datos.result.length; i++) {
            const item: ListPrtoD = {
              nombre: response.datos.result[i].pueR_Nombre,
              codigo: response.datos.result[i].pueR_Codigo
            };
            this.listPrtoD.push(item);
          }
        }
      });
    }
  }

  //CARGA LISTA INCOTER
  cargaIncoter(value) {
    const requestIncoter: incoterRequest = <incoterRequest>{}
    requestIncoter.TIPO_CodTabla = value //INC
    this.proformaService.listarIncoter(requestIncoter).subscribe(response => {
      //this.listIncoter.splice(0, this.listIncoter.length)
      for (let i = 0; i < response.datos.result.length; i++) {
        const item: ListIncoter = {
          nombre: response.datos.result[i].tipO_Desc1,
          codigo: response.datos.result[i].tipo_CodTipo
        }
        //INCOTER
        if (value == 'INC') {
          this.listIncoter.push(item)
        }
        //UNIDAD DE MEDIDA
        if (value == 'UNM') {
          this.listUnidadMedida.push(item)
        }
        //COSTO DE SERVICIO CONCEPT                               O
        if (value == 'CSC') {
          this.listCostoServicioConcepto.push(item)
        }
        //GASTOS ADICIONALES-TIPO GASTO
        if (value == 'GAG') {
          this.listGastoTipoGasto.push(item)
        }
        //GASTOS ADICIONALES-CONCEPTO
        if (value == 'GAC') {
          this.listGastoConcepto.push(item)
        }
      }
    })
  }

  listaUnidadMedida: ListIncoter[] = [];
  cargaUnidadMedidaAbreviado() {
    const requestIncoter: incoterRequest = <incoterRequest>{}
    requestIncoter.TIPO_CodTabla = 'UNM' //INC
    this.proformaService.listarIncoter(requestIncoter).subscribe(response => {

      console.log(response.datos.result);
      for (let i = 0; i < response.datos.result.length; i++) {
        const item: ListIncoter = {
          nombre: response.datos.result[i].tipO_DescC,
          codigo: response.datos.result[i].tipo_CodTipo
        }
        this.listaUnidadMedida.push(item)
      }
    })
  }

  cargaImo() {
    const requestImo: imoRequest = <incoterRequest>{}
    requestImo.TIPO_CodTabla = "IMO"
    this.proformaService.listaImo(requestImo).subscribe(response => {
      this.listImo.splice(0, this.listImo.length); // Eliminar todos los elementos existentes
      for (let i = 0; i < response.datos.result.length; i++) {
        const item: ListPrtoO = {
          nombre: response.datos.result[i].tipO_Desc1,
          codigo: response.datos.result[i].tipo_CodTipo
        };
        this.listImo.push(item);
      }
    })
  }

  cargaMonedas() {
    const requestImo: imoRequest = <incoterRequest>{}
    requestImo.TIPO_CodTabla = "MND"
    this.proformaService.listaImo(requestImo).subscribe(response => {
      this.listaMoneda.splice(0, this.listaMoneda.length); // Eliminar todos los elementos existentes
      for (let i = 0; i < response.datos.result.length; i++) {
        const item: ListIncoter = {
          nombre: response.datos.result[i].tipO_Desc1,
          codigo: response.datos.result[i].tipo_CodTipo.toString()
        };
        this.listaMoneda.push(item);
      }
    })
  }

  abrirComponenteEnNuevaPestana(): void {
    const rutaComponente = '/Medida';
    const urlCompleta = window.location.origin + rutaComponente;
    window.open(urlCompleta, '_blank');
  }

  IsPeligrosa: boolean;
  guardaRequisision(value: string) {


    if (this.groupRequerimiento.valid) {



      const rol = this.storage.get('rol');
      const codusuario = this.storage.get('codusuario')
      const correo = this.storage.get('correo');




      this.cargaProformaInterna('I')
      this.cargaProformaInternaBultos()
      this.cargaProformaCosto()
      this.cargaProformaGasto()
      //const fechaActual = new Date();
      const values = this.groupRequerimiento.value
      const valuesP = this.groupPricing.value

      // Obtener la fecha y hora actual
      const fechaHoraActual = new Date();

      // Obtener el año, mes, día, hora, minutos y segundos por separado
      const año = fechaHoraActual.getFullYear();
      const mes = fechaHoraActual.getMonth() + 1; // Los meses en JavaScript son base 0, por lo que se agrega 1
      const dia = fechaHoraActual.getDate();
      const hora = fechaHoraActual.getHours();
      const minutos = fechaHoraActual.getMinutes();
      const segundos = fechaHoraActual.getSeconds();

      // Crear una cadena con la fecha y hora formateada
      const fechaHoraFormateada = `${dia}-${mes}-${año} ${hora}:${minutos}:${segundos}`;

      if (values.tipoCarga == "1") {
        this.IsPeligrosa = true;
      } else {

        this.IsPeligrosa = false;
      }

      let estado = ''
      if (rol == "8") {
        estado = '002'
      } else {
        estado = values["estado"]
      }

      const requestRequisision: guardaRequisisionesRequest = {
        CREQ_Numero: this.ReqNro,
        EMPR_Codigo: 1,
        SUCR_Codigo: 1,
        CREQ_NROREQ: "",
        ENTC_CodCliente: parseInt(this.codigoRazonSocial),
        ENTC_CodBroker: null,
        ENTC_CodAgente: this.codigoTransportistaPricing == null ? null : this.codigoTransportistaPricing, //CODIGO DE AGENTE QUE SE PARA A TRANSPO
        ENTC_CodContacto: values.contacto,
        ENTC_CodEjecutivo: 0,
        CREQ_FecEmi: this.util.formatearfecha(new Date(), 'yyyy-MM-ddTHH:mm:ss'),

        UDDT_Fecha: null,
        CREQ_CargaPeligrosa: this.IsPeligrosa,
        CONS_TABRGM: "RGM",
        CONS_CodRGM: values.regimen,
        CONS_TABVia: "VIA",
        CONS_CodVia: values.via,
        PUER_CodDestino: this.codPtoDestino,
        PUER_CodOrigen: this.codPtoOrigen, //cambiar
        CREQ_UsrAprueba: null,
        CREQ_FecAprueba: null,
        CREQ_UsrAceptaRechaza: null,
        CREQ_FecAceptaRechaza: null,
        CREQ_FecTarifa: values["fechaCarga"],
        CREQ_FecTarifaHasta: values["fechaVigencia"],
        TIPO_TabMND: "",
        TIPO_CodMND: valuesP["moneda"] == null ? "" : valuesP['moneda'],
        CREQ_Excepcion: false,
        CREQ_Observaciones: valuesP["observaciones"] == null ? '' : valuesP["observaciones"],//values["observaciones"],
        AUDI_UsrCrea: codusuario,

        AUDI_FecCrea: this.util.formatearfecha(new Date(), 'yyyy-MM-ddTHH:mm:ss'),
        AUDI_UsrMod: this.Modo == 'U' ? codusuario : null,
        AUDI_FecMod: null,
        CREQ_FECHACARGA: values["fechaCarga"],
        CREQ_DireccionRecojo: values["dirRecojo"],
        CREQ_DireccionEntrega: values["dirEntrega"],
        CREQ_TARGET: values["tarjet"] == null ? 0 : values["tarjet"],
        CONS_TABFLE: "FLE",
        TIPO_CODFLE: values['modalidad'], //esto esta mal
        CONS_TABINC: '',
        CONS_CODINC: values["incoter"],
        CONS_TABIMO: '',
        CONS_CODIMO: values["imo"],
        CPRO_TABESTADO: "WEB",
        CPRO_CODESTADO: estado,
        CREQ_MERCADERIA: values["mercaderia"],

        ObservacionEjecutivo: values["observacionEjec"],
        REQ_RUTA_IMAGEN: "",
        creq_un: values["un"] == null ? "" : values["un"],
        CCOT_NumDoc: "",
        correousuario: correo,
        CREQ_DIASLIBRES: values['diasLibres'] == null ? 0 : values['diasLibres'],

        //NUEVAS VARIABLES API
        diaslibreconfirmado: valuesP["diasLibres"],
        diasdisponiblerequisicion: valuesP["diasDisponibles"],
        ENTC_CodAgentePricing: this.codigoAgentePricing == null ? null : this.codigoAgentePricing,
        CREQ_TIEMPOTRAN: valuesP["tiempoTransito"] == null ? 0 : valuesP["tiempoTransito"],
        CREQ_FLETEMONTO: valuesP["fleteMonto"] == null ? 0 : valuesP['fleteMonto'],
        CREQ_FLETECOSTO: valuesP["fleteCosto"] == null ? 0 : valuesP['fleteCosto'],
        TIPO_TABUNM: "UNM",
        TIPO_CODUNM: valuesP["unidadMedida"] == null ? "" : valuesP['unidadMedida'],
        CREQ_FecVigenciaIni: valuesP["fechaVigenciaIni"] == null ? new Date() : valuesP["fechaVigenciaIni"],
        CREQ_FecVigenciaFin: valuesP["fechaVigenciaFin"] == null ? new Date() : valuesP["fechaVigenciaFin"],
        CREQ_TABALM: "ALM",
        CREQ_CODALM: Number(this.codigoAlmacenPricing) == null ? null : Number(this.codigoAlmacenPricing),
        DatosAdicionales: values.datosAdicionales == null ? "" : values.datosAdicionales,
        observacionesIngles: values.observacionesIngles == null ? "" : values.observacionesIngles,
        CREQ_PESORECURRENT: valuesP["pesoRecurrente"] == null ? 0 : valuesP["pesoRecurrente"],


        //FIN NUEVAS VARIABLES API
        Accion: this.Modo,
        ListaDetalleProformaInterna: this.proformaInterna,
        ListaDetalleProformaInternaBultos: this.proformaInternaBultos,
        ListaCostoServicio: this.proformaCostoServicio,
        ListaGastoAdicionales: this.proformaGastoAdicionales,
        ListaArchivos: this.proformaArchivos
      }

      console.log(this.proformaInternaBultos)

      this.proformaService.guardaRequisisiones(requestRequisision).subscribe(response => {

        if (response.datos.status === 200) {
          this.util.showMessage('GUARDADO CORRECTAMENTE');
          const nroproforma = response.datos.codigoComprobante;
          this.subirarchivo(nroproforma)
          this.Salir('');
        }
      }, (errorServicio) => {
        this.util.showMessage(errorServicio.error.meta.mensaje);

      });


    } else {


      this.regimen.focus()   // = true;
      this.util.showMessage("Ingresen los campos obligatorios" + "\n" + "")
      this.marcarCamposComoTocados();
    }
  }

  marcarCamposComoTocados() {
    Object.values(this.groupRequerimiento.controls).forEach(control => {
      control.markAsTouched();
    });
  }


  @ViewChild(MatSelect) regimen: MatSelect = <MatSelect>{};
  @ViewChild(MatSelect) via: MatSelect = <MatSelect>{};




  // cargaProformaInternaCosto(){
  //   proformaCostoServicio
  // }



  cargaProformaInterna(value) {

    let rol = this.storage.get("rol")
    this.proformaInterna = []


    if (this.ListarGeneralDetalleContenedor != undefined) {
      for (const contenedor of this.ListarGeneralDetalleContenedor) {
        /*if(this.Modo == 'U'){
          let codigo = this.cargaTransportistaNombre(contenedor.dreQ_CodTransportista)
        }else{
          contenedor.dreQ_PrecioUniCosto = 0,
          contenedor.dreQ_DiasLibres = 0
        }*/

        if (rol == "8") {
          if (contenedor.dreQ_CodTransportista != 0) {


            const item: ProformaInternaRequest = {
              //DREQ_Item:contenedor.dreQ_ITEM,
              PREQ_Numero: contenedor.dreQ_ITEM,
              PACK_Codigo: contenedor.pacK_Codigo,//
              DREQ_Cantidad: contenedor.dreQ_Cantidad,
              DREQ_PrecioUniCosto: contenedor.dreQ_PrecioUniCosto,
              DREQ_TotalUniCosto: contenedor.dreQ_TotalUniCosto,
              DREQ_TotalUniVenta: contenedor.dreQ_TotalUniVenta,
              DREQ_PrecioUniVenta: contenedor.dreQ_PrecioUniVenta,
              DREQ_Importe: contenedor.dreQ_Importe,
              DREQ_CodTransportista: contenedor.dreQ_CodTransportista,
              DREQ_FechaInicio: contenedor.dreQ_FechaInicio,
              DREQ_FechaFin: contenedor.dreQ_FechaFin,
              DREQ_DiasLibres: contenedor.dreQ_DiasLibres,
              ENTC_CodShipper: contenedor.entC_CodShipper,
              DREQ_ETD: contenedor.dreQ_ETD,
              DREQ_ETA: contenedor.dreQ_ETA,
              DREQ_Seleccionada: contenedor.dreQ_Seleccionada,
              CCOT_NumDoc: null,
              Accion: contenedor.acccion //esto debe de actualizar o eliminar
            }
            this.proformaInterna.push(item);

          } else {
            const item: ProformaInternaRequest = {
              //DREQ_Item:contenedor.dreQ_ITEM,
              PREQ_Numero: contenedor.dreQ_ITEM,
              PACK_Codigo: contenedor.pacK_Codigo,//
              DREQ_Cantidad: contenedor.dreQ_Cantidad,
              DREQ_PrecioUniCosto: contenedor.dreQ_PrecioUniCosto,
              DREQ_TotalUniCosto: contenedor.dreQ_TotalUniCosto,
              DREQ_TotalUniVenta: contenedor.dreQ_TotalUniVenta,
              DREQ_PrecioUniVenta: contenedor.dreQ_PrecioUniVenta,
              DREQ_Importe: contenedor.dreQ_Importe,
              DREQ_CodTransportista: contenedor.dreQ_CodTransportista,
              DREQ_FechaInicio: contenedor.dreQ_FechaInicio,
              DREQ_FechaFin: contenedor.dreQ_FechaFin,
              DREQ_DiasLibres: contenedor.dreQ_DiasLibres,
              ENTC_CodShipper: contenedor.entC_CodShipper,
              DREQ_ETD: contenedor.dreQ_ETD,
              DREQ_ETA: contenedor.dreQ_ETA,
              DREQ_Seleccionada: contenedor.dreQ_Seleccionada,
              CCOT_NumDoc: null,
              Accion: 'D' //esto debe de actualizar o eliminar
            }
            this.proformaInterna.push(item);

          }

        } else {

          const item: ProformaInternaRequest = {
            //DREQ_Item:contenedor.dreQ_ITEM,
            PREQ_Numero: contenedor.dreQ_ITEM,
            PACK_Codigo: contenedor.pacK_Codigo,//
            DREQ_Cantidad: contenedor.dreQ_Cantidad,
            DREQ_PrecioUniCosto: contenedor.dreQ_PrecioUniCosto,
            DREQ_TotalUniCosto: contenedor.dreQ_TotalUniCosto,
            DREQ_TotalUniVenta: contenedor.dreQ_TotalUniVenta,
            DREQ_PrecioUniVenta: contenedor.dreQ_PrecioUniVenta,
            DREQ_Importe: contenedor.dreQ_Importe,
            DREQ_CodTransportista: contenedor.dreQ_CodTransportista,
            DREQ_FechaInicio: contenedor.dreQ_FechaInicio,
            DREQ_FechaFin: contenedor.dreQ_FechaFin,
            DREQ_DiasLibres: contenedor.dreQ_DiasLibres,
            ENTC_CodShipper: contenedor.entC_CodShipper,
            DREQ_ETD: contenedor.dreQ_ETD,
            DREQ_ETA: contenedor.dreQ_ETA,
            DREQ_Seleccionada: contenedor.dreQ_Seleccionada,
            CCOT_NumDoc: null,
            Accion: contenedor.acccion //esto debe de actualizar o eliminar
          }
          this.proformaInterna.push(item);

        }

      }
    }
  }

  cargaCondicion(value: string) {
    const requestProforma: proformaRequest = <proformaRequest>{};
    requestProforma.pchrCONS_CodTabla = value
    this.proformaService.listar(requestProforma).subscribe(response => {
      for (let i = 0; i < response.datos.result.length; i++) {
        const item: ListItem = {
          nombre: response.datos.result[i].conS_Desc_SPA,
          codigo: response.datos.result[i].conS_CodTipo
        };
        this.listCondicion.push(item);
      }
      console.log(this.listCondicion)
    })
  }

  cargaProformaInternaBultos() {
    this.proformaInternaBultos.splice(0, this.proformaInternaBultos.length); // Limpia la lista
    if (Array.isArray(this.dataBultos) && this.dataBultos.length > 0) {
      for (const fila of this.dataBultos) {
        const item: ProformaInternaBultosRequest = {
          PREQB_Numero: fila.creqB_ITEM, //ITEM
          CREQB_LARGO: fila.creqB_LARGO,
          CREQB_ANCHO: fila.creqB_ANCHO,
          CREQB_ALTO: fila.creqB_ALTO,
          CREQB_PESO: fila.creqB_PESO,
          creqB_codunidmpeso: fila.creqB_codunidmpeso,
          CREQB_VOLUMEN: fila.creqB_VOLUMEN,
          creqB_codunidmvolumen: fila.creqB_codunidmvolumen,
          CREQB_CANT: fila.creqB_CANT,
          bccOT_NumDoc: fila.bccot_numdoc,
          Accion: fila.Accion


        };
        this.proformaInternaBultos.push(item);
      }
      console.log(this.proformaInternaBultos)
    }
  }

  cargaProformaCosto() {
    this.proformaCostoServicio.splice(0, this.proformaCostoServicio.length);
    /*  if(Array.isArray(this.dataCosto) && this.dataCosto.length > 0){
        if(this.Modo == 'U'){
          for(const fila of this.dataCosto){
              for(let i = 0; i<this.listCostoServicioConcepto.length;i++){
                if(fila.concepto == this.listCostoServicioConcepto[i].nombre){
                  fila.concepto = this.listCostoServicioConcepto[i].codigo
                }
              }
              for(let i = 0; i<this.listUnidadMedida.length;i++){
                if(fila.base == this.listUnidadMedida[i].nombre){
                  fila.base = this.listUnidadMedida[i].codigo
                }
              }
            const item:  ProformaCostoServicioRequest = {
              PREQ_Numero: this.ReqNro,
              CS_ITEM: 0,
              CS_TABCONCEPTO:'CSC',
              CS_CODCONCEPTO:fila.concepto,
              CS_MONTO:fila.cS_MONTO,
              CS_COSTO:fila.cS_COSTO,
              CS_TABBASE:'UNM',
              CS_CODBASE:fila.base,
              CS_APLICACION:fila.cS_APLICACION,
              AUDI_USRCR:'',
              AUDI_FECREG:new Date(),
              detalle:fila.detalle,
              tdi:fila.tdi ==null || fila.tdi ==undefined ? 0 : fila.tdi,
              carga:fila.carga ==null || fila.carga==undefined ? 0 :fila.carga,
              AlmacenMonto: fila.almacenMonto,
              ACCION: fila.Accion
            }
            this.proformaCostoServicio.push(item)
          }
        }
      }
      */

    this.listadogeneralproformacostogeneral.forEach(fila => {
      const item: ProformaCostoServicioRequest = {
        PREQ_Numero: this.ReqNro,
        CS_ITEM: fila.cS_ITEM,
        CS_TABCONCEPTO: 'CSC',
        CS_CODCONCEPTO: fila.cS_CODCONCEPTO,
        CS_MONTO: fila.cS_MONTO,
        CS_COSTO: fila.cS_COSTO,
        CS_TABBASE: 'UNM',
        CS_CODBASE: fila.cS_CODBASE,
        CS_APLICACION: fila.cS_APLICACION,
        AUDI_USRCR: '',
        AUDI_FECREG: new Date(),
        detalle: fila.detalle,
        tdi: fila.tdi == null || fila.tdi == undefined ? 0 : fila.tdi,
        carga: fila.carga == null || fila.carga == undefined ? 0 : fila.carga,
        AlmacenMonto: fila.almacenMonto,
        costototal: fila.cS_CTOTAL,
        AerolineaCosto: fila.aerolineaCosto == null || fila.aerolineaCosto == undefined ? 0 : fila.aerolineaCosto,
        RutaCosto: fila.rutaCosto,
        FrecuenciaCosto: fila.frecuenciaCosto,
        ACCION: fila.Accion
      }
      this.proformaCostoServicio.push(item)
    });

  }

  cargaProformaGasto() {
    this.proformaGastoAdicionales.splice(0, this.proformaGastoAdicionales.length);
    /*if(Array.isArray(this.dataGasto) && this.dataGasto.length > 0){
      if(this.Modo == 'U'){
        for(const fila of this.dataGasto){
          for(let i = 0; i<this.listGastoTipoGasto.length; i++){
            if(fila.gasto == this.listGastoTipoGasto[i].nombre){
              fila.gasto = this.listGastoTipoGasto[i].codigo
            }
          }
          for(let i = 0; i<this.listGastoConcepto.length; i++){
            if(fila.concepto == this.listGastoConcepto[i].nombre){
              fila.concepto = this.listGastoConcepto[i].codigo
            }
          }
          let codigoAlmacen
          //ESTO ES PARA OBTENER EL CODIGO DEL ALMACEN
          const requestClienteT: requestAlmacen = <requestAlmacen>{};
          requestClienteT.pvchENTC_NomCompleto = fila.almacen
          requestClienteT.pintENTC_Codigo = null;
          requestClienteT.pvchTIPE_Codigo = 14, //SI NO ES 15
          requestClienteT.pvchENTC_DocIden = ""
          this.proformaService.listaAlmacen(requestClienteT).subscribe(response => {
            codigoAlmacen = response.datos.result[0].entC_Codigo
          })
          const item:  ProformaGastoAdicionalesRequest = {
            PREQ_Numero:this.ReqNro,
            GA_ITEM:0,
            GA_TABTIPOGASTO:'GAG',
            GA_CODTIPOGASTO:fila.gasto,
            GA_TABCONCEPTO:'GAC',
            GA_CODCONCEPTO:fila.concepto,
            GA_TABALM:'ALM',
            GA_CODALM:codigoAlmacen,
            CS_MONTO:fila.cS_MONTO,
            CS_COSTO:fila.cS_COSTO,
            GA_TDI:fila.gA_TDI == null || fila.gA_TDI ==undefined?0 : fila.gA_TDI ,
            AUDI_USRCR:'',
            AUDI_FECREG: new Date(),
            ENTC_CODAEROLINEA:fila.ENTC_CODAEROLINEA,
            nota:fila.nota,
            ACCION:fila.accion
          }
          this.proformaGastoAdicionales.push(item)
        }
      }
    }
    */


    this.listadogeneralproformagastogeneral.forEach(fila => {

      const item: ProformaGastoAdicionalesRequest = {
        PREQ_Numero: this.ReqNro,
        GA_ITEM: fila.gA_ITEM,
        GA_TABTIPOGASTO: 'GAG',
        GA_CODTIPOGASTO: fila.gA_CODTIPOGASTO,
        GA_TABCONCEPTO: 'GAC',
        GA_CODCONCEPTO: fila.gA_CODCONCEPTO,
        GA_TABALM: 'ALM',
        GA_CODALM: 0,
        CS_MONTO: fila.cS_MONTO,
        CS_COSTO: fila.cS_COSTO,
        GA_TDI: fila.gA_TDI == null || fila.gA_TDI == undefined ? 0 : fila.gA_TDI,
        AUDI_USRCR: '',
        AUDI_FECREG: new Date(),
        ENTC_CODAEROLINEA: fila.ENTC_CODAEROLINEA,
        nota: fila.nota,
        PesoCargable: fila.pesoCargable == 'SI' ? true : false,
        ACCION: fila.accion
      }
      this.proformaGastoAdicionales.push(item)

    });
  }

  //CARGA CONTENEDOR
  cargaContenedor() {
    const requestContenedor: contenedorRequest = <contenedorRequest>{}
    requestContenedor.TipoCodPAQ = '001'
    requestContenedor.pintPACK_Codigo = ''
    this.proformaService.listarContenedor(requestContenedor).subscribe(response => {
      this.listContenedor.splice(0, this.listContenedor.length)
      for (let i = 0; i < response.datos.result.length; i++) {
        const item: ListContenedor = {
          nombre: response.datos.result[i].pacK_Desc,
          codigo: response.datos.result[i].pacK_Codigo.toString()
        };
        this.listContenedor.push(item);
      }
    })
  }
  //CARGA EL RUC Y RAZON SOCIAL
  cargaSunat() {
    const values = this.groupRequerimiento.value
    const requestSunat: sunatRequest = <sunatRequest>{}
    const razonSocialControl = this.groupRequerimiento.get('razonSocial');
    const rucControl = this.groupRequerimiento.get('ruc');
    requestSunat.razonsocial = values["razonSocial"]
    requestSunat.ruc = values["ruc"]

    this.proformaService.listarSunat(requestSunat).subscribe(response => {
      if (response.datos.result.length > 0) {
        this.razonSocial = response.datos.result[0].nombre_o_razon_social
        this.ruc = response.datos.result[0].ruc

        razonSocialControl.setValue(this.razonSocial);
        rucControl.setValue(this.ruc);
        this.cargaRucInterno('I')
      } else {
        this.cargaRucInterno('E')
      }
    },
      error => {
        this.cargaRucInterno('E')
        console.error('Error en la consulta a la API:', error);
      }
    )
  }
  //CARGA RUC Y RAZON SOCIALES YA REGISTRADOS
  codPadreContacto: string;
  cargaRucInterno(value) {
    const values = this.groupRequerimiento.value;
    const requestRucInterno: rucInternoRequest = <rucInternoRequest>{};
    const razonSocialControl = this.groupRequerimiento.get('razonSocial');
    const rucControl = this.groupRequerimiento.get('ruc');

    requestRucInterno.pvchTIPE_Codigo = "1";
    requestRucInterno.pvchENTC_NomCompleto = values["razonSocial"];

    if (requestRucInterno.pvchENTC_DocIden == null || value == 'I') {
      requestRucInterno.pvchENTC_DocIden = "";
    }
    // if(value == 'I'){
    //   requestRucInterno.pvchENTC_DocIden = "";
    // }
    if (value == 'E') {
      requestRucInterno.pvchENTC_DocIden = values["ruc"];
      requestRucInterno.pvchENTC_NomCompleto = "";
      this.proformaService.listarRucInterno(requestRucInterno).subscribe(response => {
        this.razonSocial = response.datos.result[0].entC_RazonSocial
        this.codigoRazonSocial = String(response.datos.result[0].entC_Codigo)
        this.codPadreContacto = response.datos.result[0].entC_Codigo.toString()
        razonSocialControl.setValue(this.razonSocial);
        this.cargaContacto(response.datos.result[0].entC_Codigo)
      })
    }

    if (requestRucInterno.pvchENTC_NomCompleto.length > 3) {
      this.proformaService.listarRucInterno(requestRucInterno).subscribe(response => {
        this.listRazonSocial.splice(0, this.listRazonSocial.length);
        for (let i = 0; i < response.datos.result.length; i++) {
          this.listRazonSocial.push(response.datos.result[i].entC_RazonSocial);
        }
        for (let i = 0; i < this.listRazonSocial.length; i++) {
          if (response.datos.result[i].entC_NomCompleto == values["razonSocial"]) {
            this.ruc = response.datos.result[i].entC_DocIden
          }
        }
        this.codigoRazonSocial = String(response.datos.result[0].entC_Codigo)
        rucControl.setValue(this.ruc);
        this.cargaContacto(response.datos.result[0].entC_Codigo)
      });
    }
    this.limitedOptions = this.listRazonSocial.slice(0, 10);
  }

  listaContactoJ: ListaContactoJ[] = []
  autocompleteTrigger: MatAutocompleteTrigger;
  cargaContacto(value) {
    /* const requestContacto: contactoRequest = <contactoRequest>{}
     requestContacto.pintENTC_CodPadre = value
     requestContacto.pvchENT_NombreCompleto = ""
     this.proformaService.listaContacto(requestContacto).subscribe(response => {
       this.listaContactoJ.splice(0,this.listaContactoJ.length)
       for (let i = 0; i < response.datos.result.length; i++) {
         if (response.datos.result[i].relA_Tipos == "CON") {
           const contactoJ: ListaContactoJ = {
             entC_CodHijo: response.datos.result[i].entC_CodHijo,
             entC_NomCompleto: response.datos.result[i].entC_NomCompleto
           };
           this.listaContactoJ.push(contactoJ);
         }
       }
     });
     */

    this.listadocontacto();
  }
  //LIMPIAR CONTROLES
  limpiar(value) {
    this.groupRequerimiento.get(value).reset();
  }

  agregarFila() {
    const codigoContenedor = this.groupContenedor.get('contenedor').value;
    const contenedorSeleccionado = this.tablaDatos2.find(contenedor => contenedor.codigoContenedor === codigoContenedor);
    const index = this.tablaDatos2.findIndex(x => x.codigoContenedor == codigoContenedor);

    if (index !== -1) {
      this.tablaDatos2.splice(index, 1);
    }
    const fila = {
      seleccionado: false,
      contenedor: this.listContenedor.find(contenedor => contenedor.codigo === codigoContenedor).nombre,//contenedorSeleccionado.nombre,
      codigoContenedor: this.groupContenedor.get('contenedor').value,// contenedorSeleccionado.codigo,
      cantidad: this.groupContenedor.get('cantidad').value,
      accion: 'I'
    };
    this.tablaDatos2.push(fila);
    this.groupContenedor.reset();
  }


  editarItem(row: any) {
    //const valuesContenedor = this.groupContenedor.value
    this.groupContenedor.controls['cantidad'].setValue(row.cantidad);
    this.groupContenedor.controls['contenedor'].setValue(row.codigoContenedor);
  }


  eliminarFilasSeleccionadas() {
    this.tablaDatos = this.tablaDatos.filter(fila => !fila.seleccionado);
  }

  toggleSeleccionarTodos(event: any) {
    const seleccionado = event.target.checked;
    this.tablaDatos.forEach(fila => (fila.seleccionado = seleccionado));
  }

  eliminarFila(index: number) {
    this.tablaDatos2.splice(index, 1);
  }

  toggleSeleccionarFila(event: any, fila: any) {
    if (event.checked) {
      this.filasSeleccionadas.push(fila);
    } else {
      const index = this.filasSeleccionadas.indexOf(fila);
      if (index > -1) {
        this.filasSeleccionadas.splice(index, 1);
      }
    }
  }

  Salir(ruta: string) {
    this.router.navigate(['/Requerimiento']);
  }

  requestreqdetalle: requestrequerimiento;
  Listadoreqdetalle: Listarrequerimientodetalle[];

  listainicialdetallerequerimiento: Listarrequerimientodetalle[];
  //ESTA FUNCION ES LA QUE PERMITE AGREGAR LOS ITEMS A LA TABLA DE REQUERIMIENTO

  ListarGeneralDetalleContenedor: Listarrequerimientodetalle[] = []

  listacontenedoresrequisicion: Listarrequerimientodetalle[]

  ListarDetalleRequirimiento(nroproforma: number) {

    let rol = this.storage.get("rol")


    const requestreqdetalle = <requestrequerimiento>{};
    requestreqdetalle.pintCPRO_Numero = nroproforma;

    this.proformaService.listarequerimientodetalle(requestreqdetalle).subscribe(
      (response) => {
        // Asigna los datos obtenidos a dataContenedor


        this.listacontenedoresrequisicion = response.datos.result.map(registro => {

          const Accion = 'U'

          return {
            ...registro,
            acccion: Accion,

          };
        });


        this.listpaquetes = [];
        const uniquePackDesc = new Set<string>();

        this.listacontenedoresrequisicion.forEach(itemcontenedor => {
          const packDesc = itemcontenedor.pacK_Desc;

          // Verificar si el pack_desc ya está en el conjunto
          if (!uniquePackDesc.has(packDesc)) {
            const item: ListContenedor = {
              nombre: packDesc,
              codigo: itemcontenedor.pacK_Codigo.toString()
            };

            // Agregar el pack_desc al conjunto
            uniquePackDesc.add(packDesc);

            // Agregar el item a la lista
            this.listpaquetes.push(item);
          }
        });




        if (this.listacontenedoresrequisicion != undefined) {


          if (this.listacontenedoresrequisicion[0].dreQ_CodTransportista != null) {

            if ((this.listacontenedoresrequisicion[0].dreQ_CodTransportista != 0)) {

              if (rol == "8") {
                this.dataContenedor = this.listacontenedoresrequisicion.filter(contenedor => contenedor.dreQ_CodTransportista !== 0);

              } else {
                this.dataContenedor = this.listacontenedoresrequisicion

              }
              // this.dataContenedor = this.listacontenedoresrequisicion
              this.listainicialdetallerequerimiento = this.dataContenedor.slice()
              this.listaTemporalContenedor = this.dataContenedor.slice()

            } else {


              if (rol == "8") {
                this.dataContenedor = this.listacontenedoresrequisicion.filter(contenedor => contenedor.dreQ_CodTransportista !== 0);

              } else {
                this.dataContenedor = this.listacontenedoresrequisicion

              }
              // this.dataContenedor = this.listacontenedoresrequisicion
              this.listainicialdetallerequerimiento = this.dataContenedor.slice()
              this.listaTemporalContenedor = this.dataContenedor.slice()


            }




          }
          this.ListarGeneralDetalleContenedor = this.listacontenedoresrequisicion.slice()
        }




      }
    );
  }


  onDateChangeDesde(event: any, campo: string) {
    const selectedDate = event.value; // Obtener la fecha seleccionada
    selectedDate.setHours(0, 0, 0, 0);

    // Formatear la fecha en el formato deseado y actualizar el valor del formulario

    this.tabContenedor.get(campo).setValue(selectedDate);
  }


  validarFechas(): void {
    const fechaInicio = this.groupPricing.get('fechaInicio').value;
    const fechaFin = this.groupPricing.get('fechaFin').value;

    alert(fechaInicio)
    if (fechaInicio.getTime() > fechaFin.getTime()) {

      this.groupPricing.controls['fechaFin'].setValue(fechaInicio)
      this.util.showMessageError('Fecha inicio, mayor a Fecha final')
    }
  }



  deletetarifa(row) {

    const index = this.listaTemporalContenedor.indexOf(row)

    if (index !== -1) {
      this.listaTemporalContenedor.splice(index, 1);
    }

    this.dataContenedor = Array.from(this.listaTemporalContenedor)



    const index2 = this.ListarGeneralDetalleContenedor.findIndex(x => x.dreQ_ITEM == row.dreQ_ITEM)
    if (index2 > -1) {
      this.ListarGeneralDetalleContenedor[index2].acccion = 'D'
    }



  }

  agregarnewitem() {

    this.listainicialdetallerequerimiento.forEach(element => {


      this.listaTablaContenedor = <Listarrequerimientodetalle>{}

      this.listaTablaContenedor.transportista = ""// this.transportistaSeleccionado.descripcionTransportista
      this.listaTablaContenedor.dreQ_CodTransportista = 0 //this.transportistaSeleccionado.codigoTransportista
      this.listaTablaContenedor.dreQ_PrecioUniCosto = 0 //values['valorCont']
      this.listaTablaContenedor.dreQ_DiasLibres = 0 //values['diasLibresCont']
      this.listaTablaContenedor.acccion = 'I'

      this.listaTablaContenedor.dreQ_Cantidad = element.dreQ_Cantidad
      this.listaTablaContenedor.pacK_Codigo = element.pacK_Codigo
      this.listaTablaContenedor.pacK_Desc = element.pacK_Desc


      this.listaTemporalContenedor.push(this.listaTablaContenedor)


    });
    this.dataContenedor = Array.from(this.listaTemporalContenedor.slice())

  }

  //ESTA FUNCION ES LA QUE PERMITE AGREGAR LOS ITEMS A LA TABLA
  agregaItemContenedor() {
    // const values = this.groupContenedor.value
    const valuesCont = this.groupContenedor.value
    this.listaTablaContenedor = <Listarrequerimientodetalle>{}


    const index = this.listaTemporalContenedor.findIndex(x => x.pacK_Codigo.toString() === this.contenedoroldinsert);

    //if (index == -1){
    let item = 0
    let accion = ''
    if (index >= 0) {
      item = this.listaTemporalContenedor[index].dreQ_ITEM
      accion = this.listaTemporalContenedor[index].acccion
      this.listaTemporalContenedor.splice(index, 1)
    }


    if (this.contenedoroldinsert == "") {


      let codigopaquete = valuesCont['contenedor']
      const indexduplicado = this.listaTemporalContenedor.findIndex(x => x.pacK_Codigo.toString() === codigopaquete.toString());

      //if (index == -1){

      if (indexduplicado >= 0) {
        this.util.showMessage('Item Duplicado')
        return
      }
    }

    this.listaTablaContenedor.dreQ_ITEM = item
    this.listaTablaContenedor.dreQ_Cantidad = valuesCont['cantidad']
    this.listaTablaContenedor.pacK_Codigo = valuesCont['contenedor']

    for (let j = 0; j < this.listContenedor.length; j++) {
      if (valuesCont['contenedor'] == this.listContenedor[j].codigo) {
        this.listaTablaContenedor.pacK_Desc = this.listContenedor[j].nombre
      }
    }
    this.listaTablaContenedor.acccion = accion == '' || accion == 'I' ? 'I' : 'U'

    this.listaTemporalContenedor.push(this.listaTablaContenedor)
    this.dataContenedor = Array.from(this.listaTemporalContenedor)

    if (this.listaTablaContenedor.acccion == 'U') {

      const index = this.ListarGeneralDetalleContenedor.findIndex(x => x.pacK_Codigo.toString() === this.listaTablaContenedor.pacK_Codigo.toString());

      //if (index == -1){
      let item = 0
      let accion = ''
      if (index >= 0) {
        this.ListarGeneralDetalleContenedor[index].dreQ_Cantidad = this.listaTablaContenedor.dreQ_Cantidad
      }


    } else {
      this.ListarGeneralDetalleContenedor = this.ListarGeneralDetalleContenedor.concat(this.listaTablaContenedor)

    }

    this.contenedoroldinsert = ""
  }


  convertToTonnes(kilos: number): number {
    const tonnes = kilos / 1000; // 1 tonelada = 1000 kilogramos
    return parseFloat(tonnes.toFixed(2)); // Redondear a 2 decimales
  }

  selectedConceptoNombre: string;
  itemcosto: ListaCostoProformaInterna

  itemgasto = <ListaGastoProformaInterna>{}


  editarItemcosto(row) {
    //const valuesContenedor = this.groupContenedor.value
    this.tabCosto.controls['conceptoCos'].setValue(row.cS_CODCONCEPTO);
    this.tabCosto.controls['montoCos'].setValue(row.cS_MONTO);
    this.tabCosto.controls['costo'].setValue(row.cS_COSTO);
    this.tabCosto.controls['tdi'].setValue(row.tdi);
    this.tabCosto.controls['detalleCos'].setValue(row.detalle);
    this.tabCosto.controls['carga'].setValue(row.carga);
    this.tabCosto.controls['almacenMonto'].setValue(row.carga);
    this.tabCosto.controls['baseCos'].setValue(row.cS_CODBASE);
    this.tabCosto.controls['aplicacionCos'].setValue(row.cS_APLICACION);
    this.tabCosto.controls['aereolineaCosto'].setValue(row.aerolinia);
    this.tabCosto.controls['rutaCosto'].setValue(row.rutaCosto);
    this.tabCosto.controls['frecuenciaCosto'].setValue(row.frecuenciaCosto);

    setTimeout(() => {
      this.tabCosto.controls['aereolineaCosto'].setValue(row.aerolinia);
    }, 0);

  }



  editarItemgasto(row) {
    //const valuesContenedor = this.groupContenedor.value
    this.tabGasto.controls['tipoGasto'].setValue(row.gA_CODTIPOGASTO);
    this.tabGasto.controls['conceptoGasto'].setValue(row.gA_CODCONCEPTO);
    this.tabGasto.controls['costo'].setValue(row.cS_COSTO);
    this.tabGasto.controls['monto'].setValue(row.cS_MONTO);
    this.tabGasto.controls['tdiGasto'].setValue(row.gA_TDI);
    this.tabGasto.controls['almacen'].setValue(row.almacen);
    this.tabGasto.controls['aereolineaGasto'].setValue(row.aereolinea);
    this.tabGasto.controls['notaGasto'].setValue(row.nota);
  }


  codtransportistaold: number
  codpaqueteold: number
  editaritemcontenedor(row: Listarrequerimientodetalle) {
    this.codtransportistaold = row.dreQ_CodTransportista

    this.transportistaSeleccionado = this.listaTodosTransportistas.find(option => option.descripcionTransportista === (row.nombreTransportista == '' ? row.transportista : row.nombreTransportista));

    this.codpaqueteold = row.pacK_Codigo
    this.tabContenedor.controls['transportistaCont'].setValue(row.nombreTransportista);
    this.tabContenedor.controls['paquetes'].setValue(row.pacK_Codigo.toString());
    this.tabContenedor.controls['costo'].setValue(row.dreQ_PrecioUniCosto);
    this.tabContenedor.controls['valorCont'].setValue(row.dreQ_PrecioUniVenta);
    this.tabContenedor.controls['fechaVigenciaIni'].setValue(new Date(row.dreQ_FechaInicio));
    this.tabContenedor.controls['fechaVigenciaFin'].setValue(new Date(row.dreQ_FechaFin));
    this.tabContenedor.controls['diasLibresCont'].setValue(row.dreQ_DiasLibres);

  }


  itemcontenedor: Listarrequerimientodetalle
  async AgregarItem(value) {
    if (value == 'Contenedor') {
      // const index = this.listadogeneralproformagastotmp .findIndex(x=> x.gA_CODCONCEPTO  === values['conceptoGasto']  );
      //if (index == -1){
      let item = 0
      let accion = ''
      let codigopaquete = 0
      const values = this.tabContenedor.value
      //if (index>=0){
      //   item =  this.listadogeneralproformagastotmp[index].gA_ITEM
      //   accion = this.listadogeneralproformagastotmp[index].accion
      //   this.listadogeneralproformagastotmp.splice(index,1)
      //}
      const index = this.listaTemporalContenedor.findIndex(x => x.pacK_Codigo === this.codpaqueteold && x.dreQ_CodTransportista == this.codtransportistaold);
      //if (index == -1){
      if (index >= 0) {
        item = this.ListarGeneralDetalleContenedor[index].dreQ_ITEM
        accion = this.ListarGeneralDetalleContenedor[index].acccion
        this.listaTemporalContenedor.splice(index, 1)
      }

      if (this.codtransportistaold == 0) {


        codigopaquete = values['paquetes']
        const indexduplicado = this.listaTemporalContenedor.findIndex(x => x.pacK_Codigo === codigopaquete && x.dreQ_CodTransportista == this.transportistaSeleccionado.codigoTransportista);

        //if (index == -1){

        if (indexduplicado >= 0) {
          this.util.showMessage('Item Duplicado')
          return
        }
      }
      if (this.listpaquetes != null) {
        if (this.listpaquetes.length == 0) {
          this.listpaquetes = this.listContenedor
        }
      }

      this.itemcontenedor = <Listarrequerimientodetalle>{}
      this.itemcontenedor.preQ_Numero = 0
      this.itemcontenedor.dreQ_ITEM = item
      this.itemcontenedor.pacK_Codigo = values['paquetes']
      this.itemcontenedor.pacK_Desc = this.listpaquetes.find(x => x.codigo == values['paquetes']).nombre
      this.itemcontenedor.dreQ_Cantidad = this.listacontenedoresrequisicion.find(y => y.pacK_Codigo == values['paquetes']).dreQ_Cantidad
      this.itemcontenedor.dreQ_PrecioUniCosto = values['costo']
      this.itemcontenedor.dreQ_TotalUniCosto = values['valorCont'] * this.itemcontenedor.dreQ_Cantidad
      this.itemcontenedor.dreQ_TotalUniVenta = values['valorCont'] * this.itemcontenedor.dreQ_Cantidad
      this.itemcontenedor.dreQ_PrecioUniVenta = values['valorCont']
      this.itemcontenedor.dreQ_Importe = 0
      this.itemcontenedor.transportista = this.transportistaSeleccionado.descripcionTransportista
      this.itemcontenedor.dreQ_CodTransportista = this.transportistaSeleccionado.codigoTransportista

      const fechainicio = values['fechaVigenciaIni'];

      const year = fechainicio.getFullYear();
      const month = String(fechainicio.getMonth() + 1).padStart(2, '0');
      const day = String(fechainicio.getDate()).padStart(2, '0');
      const formattedDateInicio = `${year}-${month}-${day}T00:00:00`;

      this.itemcontenedor.dreQ_FechaInicio = formattedDateInicio

      const fechaFin = values['fechaVigenciaFin'];

      const yearf = fechaFin.getFullYear();
      const monthf = String(fechaFin.getMonth() + 1).padStart(2, '0');
      const dayf = String(fechaFin.getDate()).padStart(2, '0');
      const formattedDateFin = `${yearf}-${monthf}-${dayf}T00:00:00`;

      this.itemcontenedor.dreQ_FechaFin = formattedDateFin;

      //  this.itemcontenedor.dreQ_FechaInicio  = values['fechaVigenciaIni'] 
      // this.itemcontenedor.dreQ_FechaFin  = values['fechaVigenciaFin'] 
      this.itemcontenedor.dreQ_DiasLibres = values['diasLibresCont'] == null ? 0 : values['diasLibresCont']
      this.itemcontenedor.entC_CodShipper = 0
      this.itemcontenedor.dreQ_ETD = null
      this.itemcontenedor.dreQ_ETA = null
      this.itemcontenedor.dreQ_Seleccionada = true
      this.itemcontenedor.nombreTransportista = this.transportistaSeleccionado.descripcionTransportista
      this.itemcontenedor.ccot_numdoc = null
      this.itemcontenedor.acccion = accion == '' || accion == 'I' ? 'I' : 'U'


      //this.itemgasto.accion=  accion =='' || accion == 'I' ? 'I' : 'U' 

      this.listaTemporalContenedor.push(this.itemcontenedor)



      if (this.itemcontenedor.acccion == 'U') {

        const index = this.ListarGeneralDetalleContenedor.findIndex(x => x.pacK_Codigo.toString() === this.itemcontenedor.pacK_Codigo.toString());

        //if (index == -1){
        let item = 0
        let accion = ''
        if (index >= 0) {
          this.ListarGeneralDetalleContenedor[index].dreQ_Cantidad = this.itemcontenedor.dreQ_Cantidad
          this.ListarGeneralDetalleContenedor[index].dreQ_PrecioUniVenta = this.itemcontenedor.dreQ_PrecioUniVenta
          this.ListarGeneralDetalleContenedor[index].dreQ_FechaInicio = this.itemcontenedor.dreQ_FechaInicio
          this.ListarGeneralDetalleContenedor[index].dreQ_FechaFin = this.itemcontenedor.dreQ_FechaFin
          this.ListarGeneralDetalleContenedor[index].dreQ_DiasLibres = this.itemcontenedor.dreQ_DiasLibres
        }


      } else {
        this.ListarGeneralDetalleContenedor = this.ListarGeneralDetalleContenedor.concat(this.itemcontenedor)

      }



      /*
            this.listaTemporalContenedor.forEach(element => {
      
      
              this.itemcontenedor = <Listarrequerimientodetalle>{}
              this.itemcontenedor.preQ_Numero = element.preQ_Numero
              this.itemcontenedor.dreQ_ITEM = element.dreQ_ITEM
              this.itemcontenedor.pacK_Codigo =element.pacK_Codigo
              this.itemcontenedor.pacK_Desc =element.pacK_Desc 
              this.itemcontenedor.dreQ_Cantidad = element.dreQ_Cantidad
              this.itemcontenedor.dreQ_PrecioUniCosto =element.dreQ_PrecioUniCosto
              this.itemcontenedor.dreQ_TotalUniCosto = element.dreQ_TotalUniCosto
              this.itemcontenedor.dreQ_TotalUniVenta = element.dreQ_TotalUniVenta
              this.itemcontenedor.dreQ_PrecioUniVenta =element.dreQ_PrecioUniVenta
              this.itemcontenedor.dreQ_Importe = element.dreQ_Importe
              this.itemcontenedor.transportista =element.transportista
              this.itemcontenedor.dreQ_CodTransportista = element.dreQ_CodTransportista
        
           
              this.itemcontenedor.dreQ_FechaInicio = element.dreQ_FechaInicio
        
            
              this.itemcontenedor.dreQ_FechaFin = element.dreQ_FechaFin
        
              //  this.itemcontenedor.dreQ_FechaInicio  = values['fechaVigenciaIni'] 
              // this.itemcontenedor.dreQ_FechaFin  = values['fechaVigenciaFin'] 
              this.itemcontenedor.dreQ_DiasLibres = element.dreQ_DiasLibres 
              this.itemcontenedor.entC_CodShipper = element.entC_CodShipper 
              this.itemcontenedor.dreQ_ETD =element.dreQ_ETD
              this.itemcontenedor.dreQ_ETA = element.dreQ_ETA
              this.itemcontenedor.dreQ_Seleccionada = element.dreQ_Seleccionada
              this.itemcontenedor.nombreTransportista = element.nombreTransportista
              this.itemcontenedor.ccot_numdoc =element.ccot_numdoc
              this.itemcontenedor.acccion = element.acccion
        
        
              //this.itemgasto.accion=  accion =='' || accion == 'I' ? 'I' : 'U' 
        
              this.ListarGeneralDetalleContenedor.push(this.itemcontenedor)
        
        
      });*/

      // this.ListarGeneralDetalleContenedor = this.listaTemporalContenedor.slice()


      this.dataContenedor = Array.from(this.listaTemporalContenedor)
      this.codtransportistaold = 0
      this.codpaqueteold = 0


      /* const values = this.tabContenedor.value
       const valuesCont = this.groupContenedor.value
       this.listaTablaContenedor   = <Listarrequerimientodetalle>{}
 
       if(this.Modo == 'U'){
         this.listaTablaContenedor.transportista = this.transportistaSeleccionado.descripcionTransportista
         this.listaTablaContenedor.dreQ_CodTransportista = this.transportistaSeleccionado.codigoTransportista
         this.listaTablaContenedor.dreQ_PrecioUniCosto = values['costo']
         this.listaTablaContenedor.dreQ_PrecioUniVenta = values['valorCont']
         this.listaTablaContenedor.dreQ_DiasLibres = values['diasLibresCont']
         this.listaTablaContenedor.acccion = 'U'
       }
       if(this.Modo == 'I'){
         this.listaTablaContenedor.dreQ_Cantidad = valuesCont['cantidad']
         this.listaTablaContenedor.pacK_Codigo = valuesCont['contenedor']
       }
 
       if(this.Modo != 'I'){
         if(values['transportistaCont'] != null && values['valorCont'] != null && values['diasLibresCont'] != null){
           for (const selectedRow of this.selectedRows) {
             // Agregar los datos a las filas seleccionadas
             selectedRow.transportista = this.transportistaSeleccionado.descripcionTransportista
             selectedRow.dreQ_CodTransportista = this.transportistaSeleccionado.codigoTransportista
             selectedRow.dreQ_PrecioUniCosto = values['costo'];
             selectedRow.dreQ_PrecioUniVenta = values['valorCont'];
             selectedRow.dreQ_DiasLibres = values['diasLibresCont'];
             selectedRow.accion = 'U';
           }
         }
 
       }else{
         if(valuesCont['contenedor'] != null && valuesCont['cantidad'] != null){
           let repite = 0
           for(let j=0;  j<this.listContenedor.length; j++){
             if(valuesCont['contenedor'] == this.listContenedor[j].codigo){
               this.listaTablaContenedor.pacK_Desc = this.listContenedor[j].nombre
               this.listaTablaContenedor.acccion = 'I'
             }
           }
           for(let i = 0; i<this.listaTemporalContenedor.length; i++){
             if(valuesCont['contenedor'] == this.listaTemporalContenedor[i].pacK_Codigo){
               repite += 1
             }
           }
           if(repite < 1){
             this.listaTemporalContenedor.push(this.listaTablaContenedor)
             this.dataContenedor = Array.from(this.listaTemporalContenedor)
             this.groupContenedor.reset()
           }
           //this.tabContenedor.reset
         }
       }*/




    }
    if (value == 'Costo') {

      let totalPeso: number;
      let total_Volumen: number;
      let mayorPV: number;
      let cantidadbultos: number
      const valuesP = this.groupPricing.value
      const values = this.tabCosto.value

      if (this.dataBultos != undefined) {


        if (this.dataBultos.length > 0) {


          total_Volumen = this.dataBultos[0].creqB_VOLUMEN;
          totalPeso = this.dataBultos[0].creqB_PESO;
          cantidadbultos = this.dataBultos[0].creqB_CANT

          var mayor = 0
          if (this.datos.conS_CodVia == '002') {
            mayor = valuesP['pesoRecurrente'];
          } else {
            mayor = totalPeso;
          }
          if (this.datos.conS_CodVia != '002') {
            if (this.dataBultos[0].creqB_codunidmpeso = '003') {
              totalPeso = this.convertToTonnes(totalPeso)
            }


            if (totalPeso > total_Volumen) {
              mayor = totalPeso;
            } else {
              mayor = total_Volumen;
            }
          }



          mayorPV = values['montoCos'] * (mayor);
        }

      } else {

        mayorPV = values['montoCos']

      }


      const index = this.listadogeneralproformacostotmp.findIndex(x => x.cS_CODCONCEPTO === values['conceptoCos']);

      //if (index == -1){
      let item = 0
      let accion = ''
      if (index >= 0) {
        item = this.listadogeneralproformacostotmp[index].cS_ITEM
        accion = this.listadogeneralproformacostotmp[index].Accion
        this.listadogeneralproformacostotmp.splice(index, 1)
      }




      this.itemcosto = <ListaCostoProformaInterna>{}
      this.itemcosto.preQ_Numero = item,
        this.itemcosto.cS_ITEM = item,

        this.itemcosto.cS_TABCONCEPTO = 'CSC',
        this.itemcosto.cS_CODCONCEPTO = values['conceptoCos']
      this.itemcosto.concepto = this.listCostoServicioConcepto.find(item => item.codigo === values['conceptoCos']).nombre
      this.itemcosto.cS_MONTO = values['montoCos']
      this.itemcosto.cS_COSTO = values['costo']
      this.itemcosto.cS_TABBASE = 'UNM',
        this.itemcosto.cS_CODBASE = values['baseCos'] == null ? '' : values['baseCos']
      let unidadmedidas
      if (values['baseCos'] != null) {
        if (values['baseCos'] != "") {
          unidadmedidas = this.listUnidadMedida.find(item => item.codigo === (values['baseCos'] == null || values['baseCos'] == '' ? '' : values['baseCos'])).nombre
        }
      } else {
        unidadmedidas = ''

      }

      this.itemcosto.base = unidadmedidas
      this.itemcosto.cS_APLICACION = values['aplicacionCos'] == null ? '' : values['aplicacionCos']//element.cS_APLICACION,
      this.itemcosto.audI_USRCR = '',
        this.itemcosto.audI_FECREG = null,
        this.itemcosto.detalle = values['detalleCos'] == null ? 0 : values['detalleCos']
      this.itemcosto.tdi = values['tdi'] == null ? 0 : values['tdi']
      this.itemcosto.carga = values['carga'] == null ? 0 : values['carga']
      this.itemcosto.almacenMonto = values['almacenMonto'] == null ? 0 : values['almacenMonto']
      this.itemcosto.cS_CTOTAL = parseFloat(mayorPV.toFixed(3));

      let var_aerolinea = 0
      if (values['aereolineaCosto'] != null) {

        var_aerolinea = this.datos.conS_CodVia == '002' ? this.listaTodosTransportistas.find(option => option.descripcionTransportista === values['aereolineaCosto']).codigoTransportista : 0

      } else {

        var_aerolinea = 0

      }
      this.itemcosto.aerolineaCosto = var_aerolinea///this.datos.conS_CodVia=='002' ?this.listaTodosTransportistas.find(option => option.descripcionTransportista === values['aereolineaCosto']).codigoTransportista:0;
      this.itemcosto.aerolinia = values['aereolineaCosto'] == null ? '' : values['aereolineaCosto']
      this.itemcosto.rutaCosto = values['rutaCosto'] == null ? 0 : values['rutaCosto']
      this.itemcosto.frecuenciaCosto = values['frecuenciaCosto'] == null ? 0 : values['frecuenciaCosto']
      this.itemcosto.Accion = accion == '' || accion == 'I' ? 'I' : 'U'

      this.listadogeneralproformacostotmp.push(this.itemcosto)

      this.listadogeneralproformacostogeneral = this.listadogeneralproformacostotmp.slice()

      this.dataCosto = Array.from(this.listadogeneralproformacostotmp)

      // }

    }
    if (value == 'Gasto') {

      const values = this.tabGasto.value
      const valuesP = this.groupPricing.value

      const index = this.listadogeneralproformagastotmp.findIndex(x => x.gA_CODCONCEPTO === values['conceptoGasto']);

      //if (index == -1){
      let item = 0
      let accion = ''
      if (index >= 0) {
        item = this.listadogeneralproformagastotmp[index].gA_ITEM
        accion = this.listadogeneralproformagastotmp[index].accion
        this.listadogeneralproformagastotmp.splice(index, 1)
      }


      this.itemgasto = <ListaGastoProformaInterna>{}
      this.itemgasto.preQ_Numero = 0
      this.itemgasto.gA_ITEM = item
      this.itemgasto.gA_TABTIPOGASTO = 'GAG'
      this.itemgasto.gA_CODTIPOGASTO = values['tipoGasto']
      this.itemgasto.gasto = this.listGastoTipoGasto.find(x => x.codigo == values['tipoGasto']).nombre
      this.itemgasto.gA_TABCONCEPTO = 'GAG'
      this.itemgasto.gA_CODCONCEPTO = values['conceptoGasto']
      this.itemgasto.concepto = this.listGastoConcepto.find(x => x.codigo == values['conceptoGasto']).nombre
      this.itemgasto.gA_TABALM = 'ALM'
      this.itemgasto.gA_CODALM = ''
      this.itemgasto.almacen = values['almacen'] == null ? 0 : values['almacen']

      this.itemgasto.cS_MONTO = values['monto'] == null ? 0 : values['monto']

      if (this.datos.conS_CodVia == '002') {
        if (valuesP['pesoRecurrente'] > 0 && values['pesoC'] == true) {
          this.itemgasto.cS_MONTO = this.itemgasto.cS_MONTO * valuesP['pesoRecurrente']
        }

      }
      else {
        this.itemgasto.cS_MONTO = values['monto'] == null ? 0 : values['monto']

      }


      this.itemgasto.cS_COSTO = values['costo'] == null ? 0 : values['costo']
      this.itemgasto.gA_TDI = values['tdiGasto'] == null ? 0 : values['tdiGasto']
      this.itemgasto.audI_USRCR = ''
      this.itemgasto.audI_FECREG = new Date
      this.itemgasto.ENTC_CODAEROLINEA = values['aerolineaGasto']
      this.itemgasto.nota = values['notaGasto'] == null ? 0 : values['notaGasto']
      this.itemgasto.pesoCargable = values['pesoC'] ? 'Si' : 'No'
      this.itemgasto.accion = accion == '' || accion == 'I' ? 'I' : 'U'

      this.listadogeneralproformagastotmp.push(this.itemgasto)

      this.listadogeneralproformagastogeneral = this.listadogeneralproformagastotmp.slice()

      this.dataGasto = Array.from(this.listadogeneralproformagastotmp)




    }
  }


  deletecosto(row) {

    const index = this.listadogeneralproformacostotmp.findIndex(x => x.cS_ITEM == row.cS_ITEM)

    if (index !== -1) {
      this.listadogeneralproformacostotmp.splice(index, 1);
    }

    this.dataCosto = Array.from(this.listadogeneralproformacostotmp)

    const index2 = this.listadogeneralproformacostogeneral.findIndex(x => x.cS_ITEM == row.cS_ITEM)
    if (index2 > -1) {
      this.listadogeneralproformacostogeneral[index2].Accion = 'D'
    }


  }


  deletecontenedorejecutivo(row) {




    const index = this.listaTemporalContenedor.findIndex(x => x.dreQ_ITEM == row.dreQ_ITEM)

    if (index !== -1) {
      this.listaTemporalContenedor.splice(index, 1);
    }

    this.dataContenedor = Array.from(this.listaTemporalContenedor)

    const index2 = this.ListarGeneralDetalleContenedor.findIndex(x => x.dreQ_ITEM == row.dreQ_ITEM)
    if (index2 > -1) {
      this.ListarGeneralDetalleContenedor[index2].acccion = 'D'
    }


  }


  deletegasto(row) {

    const index = this.listadogeneralproformagastotmp.findIndex(x => x.gA_ITEM == row.gA_ITEM)

    if (index !== -1) {
      this.listadogeneralproformagastotmp.splice(index, 1);
    }

    this.dataGasto = Array.from(this.listadogeneralproformagastotmp)

    const index2 = this.listadogeneralproformagastogeneral.findIndex(x => x.gA_ITEM == row.gA_ITEM)
    if (index2 > -1) {
      this.listadogeneralproformagastogeneral[index2].accion = 'D'
    }


  }


  editMode = false;


  btnActualizaContenedor: boolean = false
  rowUpdateContenedor: []
  contenedoroldinsert: string
  cargaUpdateContenedor(row, i) {
    // console.log(row)
    // console.log(i)
    this.contenedoroldinsert = row.pacK_Codigo.toString()
    this.groupContenedor.get('cantidad').setValue(row.dreQ_Cantidad)
    this.groupContenedor.get('contenedor').setValue(row.pacK_Codigo.toString())


    this.rowUpdateContenedor = i
    this.btnActualizaContenedor = true
  }

  contenedorCopia: Listarrequerimientodetalle[]
  ActualizaContenedor(i) {
    this.contenedorCopia = this.dataContenedor
    this.dataContenedor[i].dreQ_Cantidad = this.groupContenedor.get('cantidad').value
    this.dataContenedor[i].acccion = 'U'
    this.groupContenedor.reset()
    this.btnActualizaContenedor = false
  }
  //ACTUALIZA LA FILA CON EL INDEX ESPECIFICO:
  actualizaGasto(i) {
    const values = this.tabGasto.value
    const rowToUpdate = this.dataGasto[i];
    rowToUpdate.almacen = values['almacenGasto']
    rowToUpdate.accion = 'U'

    for (let i = 0; i < this.listGastoConcepto.length; i++) {
      if (values.conceptoGasto == this.listGastoConcepto[i].codigo) {
        rowToUpdate.gA_CODCONCEPTO = this.listGastoConcepto[i].codigo
        rowToUpdate.concepto = this.listGastoConcepto[i].nombre
      }
    }

    for (let i = 0; i < this.listGastoTipoGasto.length; i++) {
      if (values.tipoGasto == this.listGastoTipoGasto[i].codigo) {
        rowToUpdate.gA_CODTIPOGASTO = this.listGastoTipoGasto[i].codigo
        rowToUpdate.gasto = this.listGastoTipoGasto[i].nombre
      }
    }
    //this.listaTablaGasto.concepto = values.conceptoGasto
    rowToUpdate.cS_MONTO = values['monto']
    rowToUpdate.gA_TDI = values['tdiGasto']
    rowToUpdate.cS_COSTO = values['costo']
    this.editMode = false;
  }


  //ESTO PUEDE CARGAR ALMACEN Y TRANSPORTISTA -- TAL VEZ
  codigoTransportista: number
  codigoAgente: number
  transportistasFiltrados: ListaTransportista[] = [];

  cargaTodosTransportista() {
    const requestTransportista: requestTransportista = <requestTransportista>{};
    requestTransportista.pintENTC_Codigo = 1;
    requestTransportista.pintENTC_Razonsocial = "";
    this.proformaService.listaTransportista(requestTransportista).subscribe(response => {
      // Actualizar listaTransportista
      for (let i = 0; i < response.datos.result.length; i++) {
        const item: ListaTransportista = {
          descripcionTransportista: response.datos.result[i].descripcionTransportista,
          codigoTransportista: response.datos.result[i].codigoTransportista,
          entC_Siglas: ''

        }
        // const item2: ListIncoter = {
        //     nombre: response.datos.result[i].descripcionTransportista,
        //     codigo: response.datos.result[i].codigoTransportista.toString()
        // }
        this.listaTodosTransportistas.push(item);
        // this.listAgente.push(item2);
        // console.log(item2.nombre == 'TAMPA CARGO S.A. SUCURSAL PERUANA' ? 'EXIST EN EL ITEM2 TAMPA CARGO S.A. SUCURSAL PERUANA' : 'NO EXISTE')
      }
      console.log('TAMANO DE LA LISTA TRANSPORTISTAS :' + this.listAgente.length)
      console.log(this.listAgente)
    });
  }

  //Funcion para filtrar transportistas
  textoFiltro: string = '';
  filtrarTransportistas(event: any): void {
    const texto = event.target.value;
    this.transportistasFiltrados = this.listaTodosTransportistas.filter(
      (transportista) =>
        transportista.descripcionTransportista
          .toLowerCase()
          .includes(texto.toLowerCase())
    );
  }

  transportistaSeleccionado: any;
  opcionSeleccionada(event: MatAutocompleteSelectedEvent) {
    this.transportistaSeleccionado = this.listaTodosTransportistas.find(option => option.descripcionTransportista === event.option.value);
  }

  codigoAgentePricing: number
  nombreAgentePricing: string
  agenteSeleccionado: any;
  agenteSelecciona(event: MatAutocompleteSelectedEvent) {
    this.agenteSeleccionado = this.listAgente.find(option => option.nombre.toLowerCase() === event.option.value.toLowerCase());
    this.codigoAgentePricing = this.agenteSeleccionado.codigo
    this.nombreAgentePricing = this.agenteSeleccionado.nombre
  }

  agenteSeleccionadoCarga(value) {
    //  console.log(value)
    this.agenteSeleccionado = this.listAgente.find(option => option.nombre.toLowerCase() === value.toLowerCase());
  }



  //Nuevo campo
  textoFiltroTranportista: string = '';
  codigoTransportistaPricing: number
  nombreTransportistaPricing: string
  transportistaPricingSeleccionado: any;
  transportistaPSelecciona(event: MatAutocompleteSelectedEvent) {
    this.transportistaPricingSeleccionado = this.listTransportistaP.find(option => option.nombre.toLowerCase() === event.option.value.toLowerCase());
    this.codigoTransportistaPricing = this.transportistaPricingSeleccionado.codigo
    this.nombreTransportistaPricing = this.transportistaPricingSeleccionado.nombre
  }

  transportistaPSeleccionadoCarga(value) {
    //  console.log(value)
    this.transportistaPricingSeleccionado = this.listTransportistaP.find(option => option.nombre.toLowerCase() === value.toLowerCase());
  }
  //FIN NUEVO CAMPO

  //BUSCA TRANSPORTISTA NOMBRE
  cargaTransportistaNombre(value) {
    const requestTransportista: requestTransportista = <requestTransportista>{};
    requestTransportista.pintENTC_Codigo = 1;
    requestTransportista.pintENTC_Razonsocial = value
    this.proformaService.listaTransportista(requestTransportista).subscribe(response => {
      this.transportistaTabla = response.datos.result[0].codigoTransportista
    })
    return this.transportistaTabla
  }

  codigoAlmacenPricing: string
  nombreAlmacenPricing: string
  opcionSeleccionadaAlmacen(event: MatAutocompleteSelectedEvent) {
    const selectedCodigo = event.option.value;
    const selectedAlmacen = this.listAlmacen.find(opcion => opcion.nombre === selectedCodigo);
    this.codigoAlmacenPricing = selectedAlmacen.codigo
    this.nombreAlmacenPricing = selectedAlmacen.nombre
  }
  nombreAlmacen
  almacenSeleccionado
  almacenSeleccionadoCarga(value) {
    this.almacenSeleccionado = this.listAlmacen.find(option => option.codigo == value);
    if (this.almacenSeleccionado != null) {
      this.nombreAlmacen = this.almacenSeleccionado.nombre
    }
  }

  opcionSeleccionadaAlmacenGasto(event: MatAutocompleteSelectedEvent) {
    const selectedCodigo = event.option.value;
    const selectedAlmacen = this.listAlmacen.find(opcion => opcion.codigo === selectedCodigo);
    if (selectedAlmacen) {
      this.tabGasto.get('almacenGasto').setValue(selectedAlmacen.nombre);
    }
  }
  opcionSeleccionadaAgente(event: MatAutocompleteSelectedEvent) {
    const selectedCodigo = event.option.value;
    const selectedAgente = this.listAgente.find(opcion => opcion.codigo === selectedCodigo);
    if (selectedAgente) {
      this.groupPricing.get('agente').setValue(selectedAgente.nombre);
      this.codigoAgente = selectedCodigo
    }
  }
  opcionSeleccionadaTransportistaP(event: MatAutocompleteSelectedEvent) {
    const selectedCodigo = event.option.value;
    const selectedAgente = this.listTransportistaP.find(opcion => opcion.codigo === selectedCodigo);
    if (selectedAgente) {
      this.groupPricing.get('transportista').setValue(selectedAgente.nombre);
      this.codigoAgente = selectedCodigo
    }
  }

  opcionSeleccionadaAlmacen1(event: MatAutocompleteSelectedEvent) {
    const selectedCodigo = event.option.value;
    const selectedAlmacen = this.listAlmacen.find(opcion => opcion.codigo === selectedCodigo);
    if (selectedAlmacen) {
      this.groupPricing.get('almacen').setValue(selectedAlmacen.nombre);
    }
  }

  opcionSeleccionadaTransportista(event: MatAutocompleteSelectedEvent) {
    const selectedCodigo = event.option.value;
    const selectedAlmacen = this.listAlmacen.find(opcion => opcion.codigo === selectedCodigo);
    if (selectedAlmacen) {
      this.tabContenedor.get('transportistaCont').setValue(selectedAlmacen.nombre);
    }
  }



  contador = 0

  textoFiltroAgente: string = '';
  cargaTodosAgentes() {
    const requestClienteT: listaClienteRequest = <listaClienteRequest>{};
    requestClienteT.pintENTC_Codigo = null,
      requestClienteT.pvchENTC_NomCompleto = "",
      requestClienteT.pvchTIPE_Codigo = 6,
      requestClienteT.pvchENTC_DocIden = ""
    this.proformaService.listaCliente(requestClienteT).subscribe(response => {
      for (let i = 0; i < response.datos.result.length; i++) {
        const item: ListIncoter = {
          nombre: response.datos.result[i].entC_NomCompleto,
          codigo: response.datos.result[i].entC_Codigo.toString(),
        }
        // console.log(item.nombre == 'TAMPA CARGO S.A. SUCURSAL PERUANA' ? item.nombre : 'NO EXISTE')
        this.listAgente.push(item)
        this.listTransportistaP.push(item)
      }
      const ItemObligatorio: ListIncoter = {
        nombre: 'TAMPA CARGO S.A. SUCURSAL PERUANA',
        codigo: '1019'
      }
      this.listAgente.push(ItemObligatorio)
      this.listTransportistaP.push(ItemObligatorio)
    })
    console.log('TAMANO DE LA LISTA EN CARGA TODOS AGENTES :' + this.listAgente.length)
  }

  cargaTodosAgente() {
    const requestClienteT: listaClienteRequest = <listaClienteRequest>{};
    requestClienteT.pintENTC_Codigo = null,
      requestClienteT.pvchENTC_NomCompleto = "",
      requestClienteT.pvchTIPE_Codigo = 2,
      requestClienteT.pvchENTC_DocIden = ""
    this.proformaService.listaCliente(requestClienteT).subscribe(response => {
      for (let i = 0; i < response.datos.result.length; i++) {
        const item: ListIncoter = {
          nombre: response.datos.result[i].entC_NomCompleto,
          codigo: response.datos.result[i].entC_Codigo.toString(),
        }
        this.listAgente.push(item)
        this.listTransportistaP.push(item)
      }
      console.log('TAMANO DE LA LISTA CARGATODOSAGENTE :' + this.listAgente.length)
    })
  }

  textoFiltroAlmacen1: string = ''
  textoFiltroAlmacen2: string = ''
  cargaTodosAlmacenes() {
    const requestClienteT: listaClienteRequest = <listaClienteRequest>{};
    requestClienteT.pintENTC_Codigo = null,
      requestClienteT.pvchENTC_NomCompleto = "",
      requestClienteT.pvchTIPE_Codigo = 14, //SI NO ES 15
      requestClienteT.pvchENTC_DocIden = ""
    this.proformaService.listaCliente(requestClienteT).subscribe(response => {
      for (let i = 0; i < response.datos.result.length; i++) {
        const item: ListIncoter = {
          nombre: response.datos.result[i].entC_NomCompleto,
          codigo: response.datos.result[i].entC_Codigo.toString(),
        }
        this.listAlmacen.push(item)
      }
    })
  }

  cargaAlmacenGasto(value) {
    const requestClienteT: requestAlmacen = <requestAlmacen>{};
    const values = this.tabGasto.value

    if (value == 'I') {
      requestClienteT.pvchENTC_NomCompleto = values['almacen']
      console.log(requestClienteT.pvchENTC_NomCompleto)
    } else {
      requestClienteT.pvchENTC_NomCompleto = ''
    }

    requestClienteT.pintENTC_Codigo = null;
    requestClienteT.pvchTIPE_Codigo = 14, //SI NO ES 15
      requestClienteT.pvchENTC_DocIden = ""

    this.proformaService.listaAlmacen(requestClienteT).subscribe(response => {
      this.listAlmacenGasto.splice(0, this.listAlmacenGasto.length)

      for (let i = 0; i < response.datos.result.length; i++) {
        const item: ListIncoter = {
          nombre: response.datos.result[i].entC_NomCompleto,
          codigo: response.datos.result[i].entC_Codigo.toString(),
        }
        this.listAlmacenGasto.push(item)
      }

    })
  }

  cargaAlmacenPricing(value) {
    const requestClienteT: requestAlmacen = <requestAlmacen>{};
    const values = this.groupPricing.value

    if (value == 'I') {
      requestClienteT.pvchENTC_NomCompleto = values['almacen']
    } else {
      requestClienteT.pvchENTC_NomCompleto = ''
    }

    requestClienteT.pintENTC_Codigo = null;
    requestClienteT.pvchTIPE_Codigo = 14, //SI NO ES 15
      requestClienteT.pvchENTC_DocIden = ""

    this.proformaService.listaAlmacen(requestClienteT).subscribe(response => {
      this.listAlmacenGasto.splice(0, this.listAlmacenGasto.length)

      for (let i = 0; i < response.datos.result.length; i++) {
        const item: ListIncoter = {
          nombre: response.datos.result[i].entC_NomCompleto,
          codigo: response.datos.result[i].entC_Codigo.toString(),
        }
        this.listAlmacenGasto.push(item)
      }

    })
  }

  codigoAlmacenGasto
  obtieneAlmacenEspecifico(value) {
    const requestClienteT: requestAlmacen = <requestAlmacen>{};
    const values = this.tabGasto.value

    requestClienteT.pvchENTC_NomCompleto = value
    requestClienteT.pintENTC_Codigo = null;
    requestClienteT.pvchTIPE_Codigo = 14, //SI NO ES 15
      requestClienteT.pvchENTC_DocIden = ""

    this.proformaService.listaAlmacen(requestClienteT).subscribe(response => {
      this.codigoAlmacenGasto = response.datos.result[0].entC_Codigo
    })
  }

  listaCostoProformaResponse: ListaCostoProformaInterna[]
  tablaTemporalCostoProforma: tablaCosto
  tablaTemporalProformaCosto: tablaCosto[] = []

  listadogeneralproformacosto: ListaCostoProformaInterna[] = []
  listadogeneralproformacostotmp: ListaCostoProformaInterna[] = []
  listadogeneralproformacostogeneral: ListaCostoProformaInterna[] = []


  listaProformaInternaCosto(value) {
    const request: requestGenerico = <requestGenerico>{};
    request.PREQ_Numero = value;

    this.proformaService.listaProformaInternaCosto(request).subscribe(response => {


      if (response.datos.status === 200) {

        this.dataCosto = response.datos.result;


        this.listadogeneralproformacostotmp = response.datos.result.map(registro => {
          const accion = 'U'
          return {
            ...registro,
            Accion: accion

          };

        });


        this.listadogeneralproformacostogeneral = this.listadogeneralproformacostotmp.slice()

      }


    });
  }


  listadogeneralproformagastotmp: ListaGastoProformaInterna[] = []
  listadogeneralproformagastogeneral: ListaGastoProformaInterna[] = []

  listaProformaInternaGasto(value) {
    const request: requestGenerico = <requestGenerico>{};
    request.PREQ_Numero = value;

    this.proformaService.listaProformaInternaGasto(request).subscribe(response => {
      if (response.datos.status == 200) {
        this.dataGasto = response.datos.result;


        this.listadogeneralproformagastotmp = response.datos.result.map(registro => {
          const accion = 'U'
          return {
            ...registro,
            Accion: accion

          };

        });


        this.listadogeneralproformagastogeneral = this.listadogeneralproformagastotmp.slice()


      }
    });
  }

  listaProformaInternaBultos(value) {
    const request: requestBultos = <requestBultos>{};
    request.pintCPRO_Numero = value;

    this.proformaService.listaProformaInternaBultos(request).subscribe(response => {
      if (response.datos.status == 200) {


        this.listaTemporalBultos = response.datos.result;

        this.dataBultos = this.listaTemporalBultos;
      }
    });
  }

  indiceBultos: number
  editaBul = false
  editaBultos(row, i) {
    this.indiceBultos = 0
    this.group.get('cantBultos').setValue(row.creqB_CANT)
    this.group.get('largo').setValue(row.creqB_LARGO)
    this.group.get('ancho').setValue(row.creqB_ANCHO)
    this.group.get('alto').setValue(row.creqB_ALTO)
    this.group.get('peso').setValue(row.creqB_PESO)
    this.group.get('volumen').setValue(row.creqB_VOLUMEN)
    this.group.get('umvolumen').setValue(row.creqB_codunidmvolumen)
    this.group.get('umpeso').setValue(row.creqB_codunidmpeso)


    this.editaBul = true
    this.indiceBultos = i
    // alert(this.indiceBultos)
    console.log(i)
  }


  deletebultos(row) {
    const index = this.listaTemporalBultos.indexOf(row)

    this.listaTemporalBultos[index].Accion = 'D'
    const item: tablaBultos = {
      preqB_Numero: this.listaTemporalBultos[index].preqB_Numero,
      creqB_ITEM: this.listaTemporalBultos[index].creqB_ITEM,
      creqB_CANT: this.listaTemporalBultos[index].creqB_CANT,
      creqB_LARGO: this.listaTemporalBultos[index].creqB_LARGO,
      creqB_ANCHO: this.listaTemporalBultos[index].creqB_ANCHO,
      creqB_ALTO: this.listaTemporalBultos[index].creqB_ALTO,
      creqB_PESO: this.listaTemporalBultos[index].creqB_PESO,
      creqB_VOLUMEN: this.listaTemporalBultos[index].creqB_VOLUMEN,
      creqB_codunidmpeso: this.listaTemporalBultos[index].creqB_codunidmpeso,
      creqB_desunidmpeso: this.listaTemporalBultos[index].creqB_desunidmpeso,
      creqB_codunidmvolumen: this.listaTemporalBultos[index].creqB_codunidmpeso,
      creqB_desunidmvolumen: this.listaTemporalBultos[index].creqB_codunidmvolumen,
      bccot_numdoc: this.listaTemporalBultos[index].bccot_numdoc,
      Accion: 'D'
    };
    if (index !== -1) {
      this.listaTemporalBultos.splice(index, 1);
    }
    this.dataBultos = Array.from(this.listaTemporalBultos.slice());
    this.listaTemporalBultos.push(item)
    console.log(this.listaTemporalBultos)
  }


  actualizaBultos() {
    const values = this.group.value;

    const bultoToUpdate = this.listaTemporalBultos[0];

    if (bultoToUpdate) {
      bultoToUpdate.creqB_ITEM = bultoToUpdate.creqB_ITEM;
      bultoToUpdate.creqB_CANT = values['cantBultos'];

      bultoToUpdate.creqB_LARGO = values['largo'];
      bultoToUpdate.creqB_ANCHO = values['ancho'];
      bultoToUpdate.creqB_ALTO = values['alto'];
      bultoToUpdate.creqB_PESO = values['peso'];
      bultoToUpdate.creqB_codunidmpeso = values['umpeso']
      bultoToUpdate.creqB_desunidmpeso = this.listaUnidadMedida.find(uMedida => uMedida.codigo === values['umpeso'])?.nombre;
      bultoToUpdate.creqB_VOLUMEN = values['volumen'];
      bultoToUpdate.creqB_codunidmvolumen = values['umvolumen']
      bultoToUpdate.creqB_desunidmvolumen = this.listaUnidadMedida.find(uMedida => uMedida.codigo === values['umvolumen'])?.nombre;
      bultoToUpdate.Accion = (bultoToUpdate.creqB_ITEM > 0) || bultoToUpdate.creqB_ITEM != null ? 'U' : 'I';
      //  this.group.reset()


      this.listaTemporalBultos.splice(0, 1);
      // this.listaTemporalBultos=[]
      this.dataBultos = []


      this.listaTemporalBultos.push(bultoToUpdate)
      this.dataBultos = Array.from(this.listaTemporalBultos)
      this.editaBul = false;

    }
  }
  inputcubicaje() {
    const values = this.group.value;

    if (values['largo'] != null && values['ancho'] != null && values['alto'] != null) {

      var cubi = values['largo'] * values['ancho'] * values['alto'];

      this.group.get('volumen').setValue(cubi)

    }
  }

  agregarItemBultos() {

    const values = this.group.value;
    let cantidadbultos = values['cantBultos']
    if (cantidadbultos > 0) {

      const cubijate = values['largo'] * values['ancho'] * values['alto'];
      this.listaTablaBultos = <tablaBultos>{}
      this.listaTablaBultos.preqB_Numero = this.ReqNro
      this.listaTablaBultos.creqB_CANT = values['cantBultos']
      this.listaTablaBultos.creqB_LARGO = values['largo']
      this.listaTablaBultos.creqB_ANCHO = values['ancho']
      this.listaTablaBultos.creqB_ALTO = values['alto']
      this.listaTablaBultos.creqB_PESO = values['peso']
      this.listaTablaBultos.creqB_codunidmpeso = values['umpeso']
      this.listaTablaBultos.creqB_desunidmpeso = this.listaUnidadMedida.find(uMedida => uMedida.codigo === values['umpeso'])?.nombre;

      this.listaTablaBultos.creqB_codunidmvolumen = values['umvolumen']
      this.listaTablaBultos.creqB_desunidmvolumen = this.listaUnidadMedida.find(uMedida => uMedida.codigo === values['umvolumen'])?.nombre;


      if (values['volumen'] == null) {
        this.listaTablaBultos.creqB_VOLUMEN = cubijate;
      } else {
        this.listaTablaBultos.creqB_VOLUMEN = values['volumen'];
      }

      this.listaTablaBultos.Accion = 'I';
      this.listaTemporalBultos.push(this.listaTablaBultos);
      this.dataBultos = this.listaTemporalBultos;//.slice();
      this.group.reset()

    } else {


      this.util.showMessage("debe ingresar una cantidad de bultos mayor a 0")
    }



  }






  editaGas = false
  indiceGasto: number
  indiceCosto: number
  editaTablaGasto(row, i) {
    this.indiceGasto = 0
    this.tabGasto.get('tipoGasto').setValue(row.gA_CODTIPOGASTO)
    this.tabGasto.get('conceptoGasto').setValue(row.gA_CODCONCEPTO)
    this.tabGasto.get('monto').setValue(row.cS_MONTO)
    this.tabGasto.get('costo').setValue(row.cS_COSTO)
    this.tabGasto.get('tdiGasto').setValue(row.gA_TDI)
    this.tabGasto.get('almacenGasto').setValue(row.almacen)
    this.indiceGasto = i;
    this.editaGas = true
  }

  actualizaGastoTabla() {
    const values = this.tabGasto.value
    const rowToUpdate = this.dataGasto[this.indiceGasto];
    rowToUpdate.almacen = values['almacenGasto']
    rowToUpdate.accion = 'U'

    for (let i = 0; i < this.listGastoConcepto.length; i++) {
      if (values.conceptoGasto == this.listGastoConcepto[i].codigo) {
        rowToUpdate.gA_CODCONCEPTO = this.listGastoConcepto[i].codigo
        rowToUpdate.concepto = this.listGastoConcepto[i].nombre
      }
    }

    for (let i = 0; i < this.listGastoTipoGasto.length; i++) {
      if (values.tipoGasto == this.listGastoTipoGasto[i].codigo) {
        rowToUpdate.gA_CODTIPOGASTO = this.listGastoTipoGasto[i].codigo
        rowToUpdate.gasto = this.listGastoTipoGasto[i].nombre
      }
    }
    //this.listaTablaGasto.concepto = values.conceptoGasto
    rowToUpdate.cS_MONTO = values['monto']
    rowToUpdate.gA_TDI = values['tdiGasto']
    rowToUpdate.cS_COSTO = values['costo']
    this.tabGasto.reset()
    this.editaGas = false;
  }

  //FUNCION PARA CAPTURAR EL CODIGO DEL PUERTO ORIGEN
  codPtoOrigen: number;
  nomPtoOrigen: string;
  selectedPtoOrigen(event: MatAutocompleteSelectedEvent) {
    console.log(event.option)
    const selectedOption = event.option.value;
    const selectedCodigo = this.listPrtoO.find(option => option.nombre === selectedOption)?.codigo; // Contendrá el código correspondiente al nombre seleccionado
    this.nomPtoOrigen = selectedOption
    this.codPtoOrigen = selectedCodigo
    console.log(selectedCodigo)
    console.log(selectedOption)
  }
  //FUNCION PARA CAPTURAR EL CODIGO DEL PUERTO DESTINO
  codPtoDestino: number;
  nomPtoDestino: string;
  selectedPtoDestino(event: MatAutocompleteSelectedEvent) {
    console.log(event.option)
    const selectedOption = event.option.value;
    const selectedCodigo = this.listPrtoD.find(option => option.nombre === selectedOption)?.codigo; // Contendrá el código correspondiente al nombre seleccionado
    this.nomPtoDestino = selectedOption
    this.codPtoDestino = selectedCodigo
    console.log(selectedCodigo)
    console.log(selectedOption)
  }




  archivosSeleccionado: File[] = [];

  onFileChange(event: any): void {
    //const file = event.target.files[0];
    //this.archivosSeleccionados.push(file);
    const input = event.target as HTMLInputElement;
    if (input.files) {
      for (let i = 0; i < input.files.length; i++) {
        this.archivosSeleccionado.push(input.files[i]);

        this.itemarchivorequest = <ProformaArchivosRequest>{}
        this.itemarchivorequest.PREQ_Numero = 0
        this.itemarchivorequest.ITEM_ARCHIVO = 0
        this.itemarchivorequest.RUTA_ARCHIVO = input.files[i].name
        this.itemarchivorequest.ACCION = 'I'


        this.proformaArchivos.push(this.itemarchivorequest)

      }
    }
  }

  itemarchivorequest: ProformaArchivosRequest;
  onFileSelected(event: Event) {
    const fileInput = event.target as HTMLInputElement;
    if (fileInput.files && fileInput.files.length > 0) {
      const archivo = fileInput.files[0];
      this.archivosService.agregarArchivo(archivo);


    }
  }

  agregarArchivo(): void {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = '.pdf,.doc,.docx,.jpg,.jpeg,.png'; // Opciones de tipos de archivos permitidos
    fileInput.addEventListener('change', (event: any) => this.onFileChange(event));
    fileInput.click();
  }


  /*
  onArchivosSeleccionados(event: Event): void {
  const input = event.target as HTMLInputElement;
  if (input.files) {
      for (let i = 0; i < input.files.length; i++) {
        this.archivosSeleccionados.push(input.files[i]);
      }
    }
  }
  */

  Request: requestimagen;
  subirarchivo(codigocomprobante: string) {

    // this.Request = <requestimagen>{}
    // this.Request.archivosSeleccionados = this.archivosSeleccionados

    const request: requestimagen = {
      archivosSeleccionados: this.archivosSeleccionado
    };


    const formData = new FormData();
    for (const archivo of this.archivosSeleccionado) {
      formData.append('archivosSeleccionados', archivo);
    }

    formData.append("nroproforma", codigocomprobante)


    this.proformaService.subirarchivo(formData).subscribe
      ((response) => {

        if (response.datos.status === 200) {


        }

      }, (errorServicio) => {
        this.util.showMessage(errorServicio.error.meta.mensaje);


      });


  }

  obtenerIconoTipoArchivo(nombreArchivo: string): string {
    const extension = nombreArchivo.split('.').pop()?.toLowerCase();
    switch (extension) {
      case 'pdf':
        //return '../../../../../assets/iconos/jpg.svg'
        return '../../../../../assets/iconos/pdf.svg';
      case 'doc':
        return '../../../../../assets/iconos/documento.svg'
      case 'docx':
        return '../../../../../assets/iconos/word.svg'
      case 'jpg':
        return '../../../../../assets/iconos/jpg.svg'
      case 'jpeg':
      case 'png':
        return 'assets/icons/image-icon.svg';
      // Agrega más casos para otros tipos de archivos si es necesario
      default:
        return 'assets/icons/default-icon.svg';
    }
  }

  obtenerTamanoArchivo(size: number): string {
    // Función para convertir el tamaño del archivo en una cadena más legible
    const KB = 1024;
    const MB = KB * 1024;

    if (size < KB) {
      return size + ' B';
    } else if (size < MB) {
      return (size / KB).toFixed(2) + ' KB';
    } else {
      return (size / MB).toFixed(2) + ' MB';
    }
  }


  private async obtenerDatosArchivo(archivo: File): Promise<ArrayBuffer | null> {
    return new Promise<ArrayBuffer | null>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event: any) => {
        if (event.target && event.target.result instanceof ArrayBuffer) {
          resolve(event.target.result);
        } else {
          resolve(null);
        }
      };

      reader.onerror = () => {
        resolve(null);
      };

      reader.readAsArrayBuffer(archivo);
    });
  }




  async descargarArchivo(archivo: File) {
    const datosArchivo = await this.obtenerDatosArchivo(archivo);

    if (datosArchivo) {
      const blob = new Blob([datosArchivo]);
      const archivoURL = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = archivoURL;
      link.target = '_blank';
      link.download = archivo.name;
      document.body.appendChild(link);
      link.click();

      URL.revokeObjectURL(archivoURL);
      document.body.removeChild(link);
    }
  }



  quitarArchivo(index: number) {
    this.archivosSeleccionado.splice(index, 1);

    //const indexlis =  this.proformaArchivos.findIndex(x => x.ITEM_ARCHIVO ===itemcodigo)

    //if (index !== -1) {
    this.proformaArchivos[index].ACCION = "D";
    // }
  }


  BDonFileChange(input: ListaArchivosDescargaProformaInterna): void {
    //const file = event.target.files[0];
    //this.archivosSeleccionados.push(file);


    // for (let i = 0; i < input.length; i++) {

    const byteArray = new Uint8Array(this.base64ToUint8Array(input.imagenbyte.toString()));
    const blob = new Blob([byteArray]);
    const file = new File([blob], input.nombre);

    this.archivosSeleccionado.push(file);

    this.itemarchivorequest = <ProformaArchivosRequest>{}
    this.itemarchivorequest.PREQ_Numero = 0
    this.itemarchivorequest.ITEM_ARCHIVO = input.item_archivo
    this.itemarchivorequest.RUTA_ARCHIVO = input.nombre
    this.itemarchivorequest.ACCION = 'U'


    this.proformaArchivos.push(this.itemarchivorequest)

    //   }

  }


  private base64ToUint8Array(base64: string): Uint8Array {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  }


  listaProformaInternaArchivo(value) {
    const request: requestBultos = <requestBultos>{};
    request.pintCPRO_Numero = value;

    this.proformaService.listaProformaInternaArchivosDescarga(request).subscribe(response => {
      if (response.datos.status == 200) {

        response.datos.result.forEach(element => {


          const fileInput = document.createElement('input');
          fileInput.type = 'file';
          fileInput.accept = '.pdf,.doc,.docx,.jpg,.jpeg,.png'; // Opciones de tipos de archivos permitidos
          fileInput.addEventListener('change', (event: any) => this.BDonFileChange(event));
          //  fileInput.click();



        });

      }
    });
  }


  listaProformaInternaArchivoDescarga(value) {
    const request: requestBultos = <requestBultos>{};
    request.pintCPRO_Numero = value;

    this.proformaService.listaProformaInternaArchivosDescarga(request).subscribe(response => {
      if (response.datos.status == 200) {

        response.datos.result.forEach(element => {


          const fileInput = document.createElement('input');
          fileInput.type = 'file';
          fileInput.accept = '.pdf,.doc,.docx,.jpg,.jpeg,.png'; // Opciones de tipos de archivos permitidos
          fileInput.addEventListener('change', (event: any) => this.BDonFileChange(element));
          //  fileInput.click();
          this.BDonFileChange(element)


        });

      }
    });
  }



  requestcontacto: requestcontacto;
  listContacto: listcontacto[];
  listadocontacto() {

    this.requestcontacto = <requestcontacto>{}
    this.requestcontacto.ENTC_CodCliente = parseInt(this.codigoRazonSocial);


    this.proformaService.listarcontacto(this.requestcontacto).subscribe
      ((response) => {


        if (response.datos.status === 200) {

          this.listContacto = response.datos.result;

        }

      }, (errorServicio) => {
        this.util.showMessage(errorServicio.error.meta.mensaje);


      });


  }

  //abre modal
  crear_contacto: boolean
  openModal(value, panel) {
    const values = this.groupRequerimiento.value;

    if ((values["razonSocial"] === undefined)) {

      //  this.crear_contacto = true
      this.util.showMessage("seleccione primero un cliente");

    }

    const codcliente = this.codigoRazonSocial
    const options = {
      disableClose: true,
      panelClass: panel,
      data: codcliente,
    };

    const reference = this.util.openModal(
      value,
      options,


    );
    reference.subscribe((response) => {
      if (response) {



      }

      this.listadocontacto();
    });
  }
}





interface ArchivoSeleccionado {
  name: string;
  size: number;
  /* Agrega aquí las propiedades adicionales del archivo que necesites */
}
