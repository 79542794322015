<div class="titulo">REGISTRO DE SERVICIOS</div>
<div class="main">
    <form [formGroup]="group" class="formulario example-full-width-alterno-content-column">
        <div class="example-full-width-alterno-content-row center buttons-mantenimientos">
            <p-button label="Guardar" icon="pi pi-save" styleClass="p-button-info p-button-sm"
                (onClick)="guardar()"></p-button>
            <p-button label="Salir" icon="pi pi-external-link" styleClass="p-button-info p-button-sm"
                (onClick)="salir()"></p-button>
        </div>
        <div class="example-full-width-alterno-content-row">
            <div class="example-full-width-alterno-content-2">
                <span class="p-float-label">
                    <input type="text" formControlName="nombre" pInputText class="p-inputtext-sm" />
                    <label for="float-label">Nombre Servicio</label>
                </span>
            </div>
            <div class="example-full-width-alterno-content-2">
                <span class="p-float-label">
                    <p-dropdown formControlName="origen" [options]="AllPorts" placeholder="POL" [showClear]="true"
                        optionLabel="nombre" inputId="origenid" [filter]="true" filterBy="nombre" class="p-inputtext-sm"
                        [virtualScroll]="true" [virtualScrollItemSize]="2">
                    </p-dropdown>
                    <!-- (onFilter)="onFilter($event,'origen')"   (onFilter)="onFilter($event,'destino')" -->
                    <label for="float-label">POL</label>
                </span>
            </div>
        </div>
        <div class="example-full-width-alterno-content-row">
            <div class="example-full-width-alterno-content">
                <span class="p-float-label">
                    <p-dropdown formControlName="naviera" [options]="ListaNaviera" placeholder="Navieras"
                        optionLabel="nombre" inputId="float-label" [filter]="true" filterBy="nombre" [showClear]="true"
                        class="p-inputtext-sm">
                    </p-dropdown>
                    <label for="float-label">Navieras</label>
                </span>
            </div>
            <!-- <div>
                <p-button icon="pi pi-plus" styleClass="p-button-info p-button-sm"
                    (onClick)="agregarNaviera()"></p-button>
            </div> -->
        </div>

        <div class="example-full-width-alterno-content-row">
            <div class="example-full-width-alterno-content-2">
                <span class="p-float-label">
                    <p-dropdown formControlName="destino" [options]="AllPorts" optionLabel="nombre"
                        inputId="float-label" [filter]="true" filterBy="nombre" placeholder="POD" [showClear]="true"
                        class="p-inputtext-sm" [virtualScroll]="true" [virtualScrollItemSize]="2">
                    </p-dropdown>
                    <label for="float-label">POD</label>
                </span>
            </div>
            <!-- <div class="example-full-width-alterno-content-2">
                <input class="p-inputtext-sm" type="text" formControlName="transito" pInputText
                    placeholder="Tiempo de tránsito" />
            </div> -->
            <div *ngIf="transaccion == 'NUEVO'">
                <p-button icon="pi pi-plus" styleClass="p-button-info p-button-sm" (onClick)="agregar()"></p-button>
            </div>

            <div class="example-full-width-alterno-content-2" *ngIf="transaccion == 'EDIT'">
                <span class="p-float-label">
                    <input type="text" formControlName="transito" pInputText class="p-inputtext-sm" />
                    <label for="float-label">Tiempo Transito</label>
                </span>
            </div>
        </div>
        <div class="example-full-width-alterno-content table" *ngIf="transaccion == 'NUEVO'">
            <p-table [value]="groupListaNavieras" styleClass="p-datatable-sm">
                <ng-template pTemplate="header">
                    <tr class="header-table">
                        <th></th>
                        <th>Navieras</th>
                        <th>Puertos</th>
                        <th>Tiempos</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-product let-i="rowIndex">
                    <tr class="body-table">
                        <td>
                            <div><p-button icon="pi pi-trash" styleClass="p-button-danger p-button-sm"
                                    (onClick)="quitar(this.groupListaNavieras,i)"></p-button>
                            </div>
                        </td>
                        <td>{{ product.nombre_nave }}</td>
                        <td>{{ product.nombre_destino }}</td>
                        <td><input class="p-inputtext-sm" type="text" formControlName="transito" pInputText
                                placeholder="Tiempo de tránsito" (input)="onTransitoChange($event, i)" /></td>
                    </tr>
                </ng-template>
            </p-table>
        </div>

        <!-- <div class="example-full-width-alterno-content table">
            <p-table [value]="ListaPuertos" styleClass="p-datatable-sm">
                <ng-template pTemplate="header">
                    <tr class="header-table">
                        <th></th>
                        <th>POD</th>
                        <th>Tiempo</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-product let-i="rowIndex">
                    <tr class="body-table">
                        <td>
                            <div><p-button icon="pi pi-trash" styleClass="p-button-danger p-button-sm"
                                    (onClick)="quitar(ListaPuertos,i)"></p-button>
                            </div>
                        </td>
                        <td>{{ product.nombre }}</td>
                        <td><input class="p-inputtext-sm" type="text" formControlName="transito" pInputText
                                placeholder="Tiempo de tránsito" (input)="onTransitoChange($event, i)" /></td>
                    </tr>
                </ng-template>
            </p-table>
        </div> -->
    </form>
</div>