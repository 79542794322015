import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BuscarNavesItinerarioRequest, InsertItinerarioRefferRequest, InsertServicioRefferRequest, ListarDropdownRequest, ListarItinerariosRefferRequest, ListarNavesRequest, ListarNuevasReservaRequest, ListarNuevosClientesRequest, ListarServiciosRefferRequest, NuevaReservaRequest, NuevoPerfilClienteRequest, operacionesNaveRequest, transbordoReservaRequest, transbordoTrackingRequest, UpdateNuevosClienteRequest, updatePasswordRefferRequest, updateReservaRefferRequest } from '../comercial/reffer/domain/reffer-request';
import { BuscarNavesItinerarioResponse, GeneralResponse, ListarDropdownResponse, ListarItinerariosRefferResponse, ListarNavesResponse, ListarNuevaReservaResponse, ListarNuevosClientesResponse, ListarServiciosRefferResponse, transbordoTrackingResponse } from '../comercial/reffer/domain/reffer-response';
import { ComercialRepository } from '../repositorio/comercial.repository';
import { ListarOrdenesVentaRequest, CrudOrdenesVentaRequest, listAllOVRequest, listNaveViajePorPuertoRequest } from '../comercial/orden-venta/domain/request/comercial-orden-venta-request';
import { ListarOrdenesVentaResponse, CrudOrdenesVentaResponse } from '../comercial/orden-venta/domain/response/comercial-orden-venta-response';
import { searchTrackingShipmentRequest } from '../comercial/tracking-shipment/domain/tracking-shipment-request';
import { searchTrackingShipmentResponse } from '../comercial/tracking-shipment/domain/tracking-shipment-response';
@Injectable({
  providedIn: 'root'
})
export class ComercialService implements ComercialRepository {

  constructor(
    private readonly http: HttpClient
  ) { }

  //#region REFFER

  NuevoPerfil(request: NuevoPerfilClienteRequest): Observable<GeneralResponse> {
    return this.http.post<GeneralResponse>(`${environment.PATH_API}/reffer/NuevoPerfil`, request);
  }

  ListarNuevosClientes(request: ListarNuevosClientesRequest): Observable<ListarNuevosClientesResponse> {
    return this.http.post<ListarNuevosClientesResponse>(`${environment.PATH_API}/reffer/ListarNuevoCliente`, request);
  }

  UpdateNuevosCliente(request: UpdateNuevosClienteRequest): Observable<GeneralResponse> {
    return this.http.post<GeneralResponse>(`${environment.PATH_API}/reffer/updatenuevocliente`, request);
  }

  ListarDropdown(request: ListarDropdownRequest): Observable<ListarDropdownResponse> {
    return this.http.post<ListarDropdownResponse>(`${environment.PATH_API}/reffer/ListarDropdown`, request);
  }

  NuevaReserva(request: NuevaReservaRequest): Observable<GeneralResponse> {
    return this.http.post<GeneralResponse>(`${environment.PATH_API}/reffer/insertsolicitudreserva`, request);
  }

  ListarNuevasReserva(request: ListarNuevasReservaRequest): Observable<ListarNuevaReservaResponse> {
    return this.http.post<ListarNuevaReservaResponse>(`${environment.PATH_API}/reffer/ListarNuevasReserva`, request);
  }

  InsertServicioReffer(request: InsertServicioRefferRequest): Observable<GeneralResponse> {
    return this.http.post<GeneralResponse>(`${environment.PATH_API}/reffer/InsertServicioReffer`, request);
  }

  ListarServiciosReffer(request: ListarServiciosRefferRequest): Observable<ListarServiciosRefferResponse> {
    return this.http.post<ListarServiciosRefferResponse>(`${environment.PATH_API}/reffer/ListarServiciosReffer`, request);
  }

  InsertItinerarioReffer(request: InsertItinerarioRefferRequest): Observable<GeneralResponse> {
    return this.http.post<GeneralResponse>(`${environment.PATH_API}/reffer/InsertItinerarioReffer`, request);
  }

  ListarItinerariosReffer(request: ListarItinerariosRefferRequest): Observable<ListarItinerariosRefferResponse> {
    return this.http.post<ListarItinerariosRefferResponse>(`${environment.PATH_API}/reffer/ListarItinerariosReffer`, request);
  }

  ListarNaves(): Observable<ListarDropdownResponse> {
    return this.http.post<ListarDropdownResponse>(`${environment.PATH_API}/reffer/ListarNaves`, '');
  }

  updateReservaReffer(request: updateReservaRefferRequest): Observable<GeneralResponse> {
    return this.http.post<GeneralResponse>(`${environment.PATH_API}/reffer/updateReserva`, request);
  }

  updatePasswordReffer(request: updatePasswordRefferRequest): Observable<GeneralResponse> {
    return this.http.post<GeneralResponse>(`${environment.PATH_API}/reffer/updatePassword`, request);
  }

  BuscarNavesItinerario(request: BuscarNavesItinerarioRequest): Observable<BuscarNavesItinerarioResponse> {
    return this.http.post<BuscarNavesItinerarioResponse>(`${environment.PATH_API}/reffer/BuscarNavesItinerario`, request);
  }

  ListarNavesNext(request: ListarNavesRequest): Observable<ListarNavesResponse> {
    return this.http.post<ListarNavesResponse>(`${environment.PATH_API}/reffer/ListarNavesNext`, request);
  }

  operacionesNave(request: operacionesNaveRequest): Observable<GeneralResponse> {
    return this.http.post<GeneralResponse>(`${environment.PATH_API}/reffer/operacionesNave`, request);
  }

  transbordoReserva(request: transbordoReservaRequest): Observable<GeneralResponse> {
    return this.http.post<GeneralResponse>(`${environment.PATH_API}/reffer/transbordoReserva`, request);
  }

  transbordoTracking(request: transbordoTrackingRequest): Observable<transbordoTrackingResponse> {
    return this.http.post<transbordoTrackingResponse>(`${environment.PATH_API}/reffer/transbordoTracking`, request);
  }

  //#endregion

  //#region SEGUIMIENTO DE EMBARQUE

  searchTrackingShipment(prolrequest: searchTrackingShipmentRequest): Observable<searchTrackingShipmentResponse> {
    return this.http.post<searchTrackingShipmentResponse>(`${environment.PATH_API}/comercial/searchTrackingShipment`, prolrequest);
  }

  //#endregion

  ListarOrdenesVenta(prolrequest: ListarOrdenesVentaRequest): Observable<ListarOrdenesVentaResponse> {
    return this.http.post<ListarOrdenesVentaResponse>(`${environment.PATH_API}/comercial/BuscarCotizacionesOrdenVenta`, prolrequest);
  }

  CrudOrdenesVenta(prolrequest: CrudOrdenesVentaRequest): Observable<CrudOrdenesVentaResponse> {
    return this.http.post<CrudOrdenesVentaResponse>(`${environment.PATH_API}/comercial/TCOM_SP_CRUD_Cab_Cotizacion_OV`, prolrequest);
  }

  listAllOV(prolrequest: listAllOVRequest): Observable<any> {
    return this.http.post<any>(`${environment.PATH_API}/comercial/ListarAllOrdenVenta`, prolrequest);
  }

  //Daniel 03/09/24
  listNaveViajePorPuerto(prolrequest: listNaveViajePorPuertoRequest): Observable<any> {
    return this.http.post<any>(`${environment.PATH_API}/comercial/ListarNaveViajePorPuerto`, prolrequest);
  }
}
