import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ActivatedRoute, Router } from '@angular/router';

import { MetadataTable } from 'src/app/interfaces/metada-table.interface';
import { ProformaRepository } from 'src/app/proforma/domain/proforma.repository';
import { ListarTodosWebRequest, requestTransportista, rucInternoRequest } from 'src/app/proforma/domain/request/proforma_request';
import { ListParametros } from 'src/app/proforma/domain/response/proforma_response';
import { listaClienteInterno } from 'src/app/requerimiento/domain/response/response_requerimiento';
import { CryptoService } from 'src/app/services/crypto.service';
import { StorageService } from 'src/app/services/storage.service';
import { ItemsListarOrdenesVentaResponse/*, ItemListarFleteOrdenVentaResponse*/ } from '../../domain/response/comercial-orden-venta-response'; //Daniel
import { CrudOrdenesVentaRequest } from '../../domain/request/comercial-orden-venta-request';//DANIEL 26
// import { ComercialRepository } from '../../domain/comercial-repository'; //daniel 26
import { UtilService } from 'src/app/services/util.service'; //dANIEL26
import { newmetadate, style } from 'src/app/shared/components/datatable/domain/datatable-domain';
import { MatSelectChange } from '@angular/material/select';
import { ComercialRepository } from 'src/app/repositorio/comercial.repository';
import { Parametros } from 'src/app/services/domain/services-domain';
import { Title } from '@angular/platform-browser';
import { SelectItem } from 'primeng/api';
import { dbmetadata } from 'src/app/shared/components/db-table/domain/dbTable-domain';

@Component({
  selector: 'app-mantenimiento-orden-venta',
  templateUrl: './mantenimiento-orden-venta.component.html',
  styleUrls: ['./mantenimiento-orden-venta.component.css']
})

export class MantenimientoOrdenVentaComponent {
  date: Date = new Date();
  binary: boolean = true;
  uploadedFiles: any[] = [];
  datosRecibidos: any;//Se guarda los datos de la fila selecciona al dar click en EDITAR
  estilos: style = {
    header: {
      align: 'left',
      justify: 'center',
      size: '10px'
    },
    body: {
      size: '9px'
    }
  }

  datosViajePorPuerto2: any[] = []
  seguimiento_Embarque: any[] = []

  //Cambiar Estado si es "NUEVO" o "EDITAR"
  //  --Datos Generales  
  readOnlyNumeroDGDG: boolean;
  readOnlyNumeroCotizacionDG: boolean;
  readOnlyEstadoDG: boolean;
  readOnlyNoCRMDG: boolean;
  readonlyOrdenCompraDG: boolean;
  readonlyRegimenDG: boolean;
  readonlyViaDG: boolean;
  readonlyLineaNegocioDG: boolean;
  readonlyFEmisionDG: boolean;
  readonlyFVencimientoDG: boolean;
  readonlyFAprobacionDG: boolean;
  readonlyFEmbarqueDG: boolean;
  readOnlyClienteDG: boolean;  //PENDIENTE
  readOnlyCustomerDG: boolean;
  readOnlyAgenteDG: boolean;
  readOnlyEjecutivoDG: boolean;
  readonlyIncotermDG: boolean;
  readonlyServLogisticoDG: boolean;
  readonlyServicioDG: boolean;
  readonlyCotPropiaDG: boolean;
  visibledCotPropiaDG: boolean; //Cambia segun otras funciones
  readonlyAvisoLl_zDG: boolean;


  //  --Servicios y Tarifa
  readonlyTransportisaSTST: boolean;
  readonlyPuertoTransbordoST: boolean;
  readonlydTraficoST: boolean;
  readonlyPuertoOrigenST: boolean;
  readonlyPuertoDestinoST: boolean;
  readonlyMonedaST: boolean;
  readonlyTipoFechaTarifaST: boolean;
  readonlyFechaTarifaST: boolean;
  readonlyCondicionST: boolean;
  readonlyNumeroContratoST: boolean;
  readonlyAdicionalST: boolean;
  readonlyPCMblST: boolean;
  readonlyPCHblST: boolean;
  readOnlyFleteTotalST: boolean;
  readonlyDiasLibresSobreeestadiaST: boolean;


  // --Datos Embarque
  readonlyCargaRefrigeradaDE: boolean;
  readOnlyTemperaturaDE: boolean;
  readonlyCargaPeligrosaDE: boolean;
  readonlyImoClassDE: boolean;
  readonlyRoundTripDE: boolean;
  readOnlyImoUnDE: boolean;
  readonlyShipperDE: boolean;
  readonlyReferenciaDE: boolean;
  readonlyConsigneeDE: boolean;
  readonlyNotifyDE: boolean;

  // --Observaciones
  readonlyAlmacenajeObs: boolean;
  readonlySobreestadiaObs: boolean;
  readonlyTiempoViajeObs: boolean;
  readonlyValidezOfertaObs: boolean;
  readonlyInstruccionesEmbarqueObs: boolean;

  // --Novedades
  readonlyNroBookingNov: boolean;
  readonlyDepositoNov: boolean;
  readonlyBlNietoNov: boolean;
  readonlyBlHijoNov: boolean;
  readonlyBlMasterNov: boolean;
  readonlyContenedorNov: boolean;
  readOnlyViajeNov: boolean;
  readonlyFZarpeNov: boolean;
  readonlyFEtaEtdNov: boolean;
  readOnlyNaveNov: boolean;

  // --EventosTareas
  readonlyTipoEventoTareaET: boolean;
  readonlyFechaYHoraET: boolean;
  readonlyEstadoET: boolean;
  readonlyModuloET: boolean;
  readonlyUsuarioET: boolean;
  readonlyObservacionesET: boolean;

  // --Servicio Locales
  readOnlyCodigoOperacionDSL: boolean;
  readOnlyNumeroOpeacionDSL: boolean;
  readonlyFechaEmisionDSL: boolean;
  readOnlyClienteDSL: boolean;
  readOnlyTransportistaAMDSL: boolean;
  readOnlyAlmacenDSL: boolean;
  readOnlyAgenciaMaritimaDSL: boolean;
  readOnlyTerminalPortuarioDSL: boolean;
  readOnlyCondicionEmbarqueDSL: boolean;
  readonlyTransportistaDSL: boolean;
  readonlySADADSL: boolean;
  readonlyIMODSL: boolean;
  readonlyTarifaDSL: boolean;
  readonlyTipoDSL: boolean;
  readonlyVigenciaDesdeDSL: boolean;
  readonlyVigenciaHastaDSL: boolean;
  readonlyDescripcionDSL: boolean;//PENDIENTE
  readonlyObservacionesDSL: boolean;//PENDIENTE

  //Variables que cambian segun otras acciones
  avisoLl_z: string = 'Aviso de llegada '; // Valor inicial del título
  fechaEm_z: string = 'F. Embarque';

  constructor(//Daniel01-08
    private readonly router: Router,
    private readonly proformaService: ProformaRepository,
    private readonly ordenventaService: ComercialRepository, // Daniel 26
    private readonly util: UtilService, // DANIEL 26
    private readonly title: Title
  ) {
    this.title.setTitle('Nueva Orden Venta')
    const datosViajePorPuerto = JSON.parse(localStorage.getItem('datoViajePorPuerto'))
    this.datosViajePorPuerto2 = datosViajePorPuerto
    console.log('LLEGA VACIO ESTO', this.datosViajePorPuerto2)
  }

  onUpload(event) {
    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }

    //this.messageService.add({severity: 'info', summary: 'File Uploaded', detail: ''});
  }

  onRegimenChange(event: any) {//Cambio de otros elementos
    const selectedRegimen = event.value;
    if (selectedRegimen === '001') {
      this.group_datosgenerales.get('avisodellegada').setValue(true)
      this.group_datosgenerales.get('lineanegocio')?.setValue('');
      this.visibledCotPropiaDG = true;
    } else if (selectedRegimen === '002') {
      this.group_datosgenerales.get('lineanegocio')?.setValue('002');  //Establece el valor del segundo cbx
      this.avisoLl_z = 'Aviso de Zarpe';
      this.fechaEm_z = 'F. Zarpe';
      this.group_datosgenerales.get('avisodellegada').setValue(true)
      this.visibledCotPropiaDG = true;
    } else {
      this.group_datosgenerales.get('lineanegocio')?.setValue('');   //Limpia el valor si no se cumple la condición
      this.avisoLl_z = 'Aviso de llegada';
      this.fechaEm_z = 'F. Embarque';
      this.group_datosgenerales.get('avisodellegada').setValue(false)

      this.visibledCotPropiaDG = false;
    }
  }

  transformData(data: any[]): any[] { //Para mapear los datos para que funcione en select
    return data.map(item => ({
      labely: item.nombre,
      valuey: item.nombre,
      codigoy: item.codigo,
    }));
  }

  formatDate(originalDateStr: string, newFormat: string = 'MM/DD/YYYY HH:mm:ss'): string {
    // Crear un objeto Date a partir de la cadena original
    const date = new Date(originalDateStr);

    // Función para añadir ceros a la izquierda
    const pad = (number: number, length: number) => number.toString().padStart(length, '0');

    // Crear el formato de salida basado en la cadena `newFormat`
    const formattedDate = newFormat
      .replace('MM', pad(date.getMonth() + 1, 2))
      .replace('DD', pad(date.getDate(), 2))
      .replace('YYYY', date.getFullYear().toString())
      .replace('HH', pad(date.getHours(), 2))
      .replace('mm', pad(date.getMinutes(), 2))
      .replace('ss', pad(date.getSeconds(), 2));

    return formattedDate;
  }

  onCargaPeligrosaChange(event: any) {
    const isChecked = event.checked;
    if (isChecked) {
      this.readonlyImoClassDE = false;
      this.readOnlyImoUnDE = false;
    } else {
      this.readonlyImoClassDE = true;
      this.readOnlyImoUnDE = true;
    }
  }

  panelOpenState = false;
  parametros: Parametros[] = [] // any[] = []//Variable para guardar todos los datos de LTW

  group: FormGroup //No hace nada 

  //Declaracion de los FormGroup
  group_datosgenerales: FormGroup
  group_serviciostarifa: FormGroup
  group_datosembarque: FormGroup
  group_observaciones: FormGroup
  group_viaje: FormGroup
  group_novedades: FormGroup
  group_archivos: FormGroup
  group_eventostareas: FormGroup
  group_servicioslocales: FormGroup

  inicializarFormularios(row) {
    this.group_datosgenerales = new FormGroup({//30
      numero: new FormControl(row.ccot_numdoc, null),
      numerocotizacion: new FormControl(row.ccot_numdoccot, null),
      estado: new FormControl(row.cons_codest, null),
      cotizacionpropia: new FormControl(row.ccot_propia, null),
      avisodellegada: new FormControl(row.ccot_enviaavisollegada, null),
      serviciologistico: new FormControl(row.ccot_serviciologistico, null),
      servicio: new FormControl(row.ccot_serviciotransmision, null),
      regimen: new FormControl(row.cons_codrgm, null),
      via: new FormControl(row.cons_codvia, null),
      lineanegocio: new FormControl(row.cons_codlng, null),
      //fechaemision: new FormControl(row.ccot_fecemi, null),
      fechaemision: new FormControl(row.ccot_fecemi ? new Date(row.ccot_fecemi) : null, null),
      fechavencimiento: new FormControl(row.ccot_fecvcto ? new Date(row.ccot_fecvcto) : null, null),
      fechaaprobacion: new FormControl(row.ccoT_FecAprueba ? new Date(row.ccoT_FecAprueba) : null, null),
      fechaembarque: new FormControl(row.ddov_fecembarque ? new Date(row.ddov_fecembarque) : null, null),
      cliente: new FormControl(row.entc_nomcliente, null),
      customer: new FormControl(row.entC_NomCustomer, null),
      agente: new FormControl(row.entc_nomagente, null),
      incoterm: new FormControl(row.tipo_codinc, null),
      ejecutivo: new FormControl(row.entc_nomejecutivo, null),
      numerocrm: new FormControl(row.ccot_numpresupuesto, null),
      broker: new FormControl(null, null),//Se eliminó
      ordencompra: new FormControl(row.ccot_ordencompra, null),
    })

    this.group_serviciostarifa = new FormGroup({
      transportista: new FormControl(row.entc_nomtransportista, null),
      puertotransbordo: new FormControl(row.puer_nombretrasbordo, null),
      trafico: new FormControl(row.tipo_codtrf, null),
      puertoorigen: new FormControl(row.puer_nombreorigen, null),
      puertodestino: new FormControl(row.puer_nombredestino, null),
      moneda: new FormControl(row.tipo_codmnd, null),
      tipofechatarifa: new FormControl(row.cons_codtft, null),
      fechatarifa: new FormControl(row.ccot_fectarifa ? new Date(row.ccot_fectarifa) : null, null),
      condicion: new FormControl(row.cons_codfle, null),
      numerocontrato: new FormControl(row.cont_numero, null),
      adicional: new FormControl(row.cont_descrip, null),
      pcmbl: new FormControl(row.ccot_pagombl, null),
      pchbl: new FormControl(row.ccot_pagohbl, null),
      fletetotal: new FormControl(row.dcot_totaluniventa, null),
      diaslibres: new FormControl(row.ccot_sobreestadia, null),
    })

    this.group_datosembarque = new FormGroup({
      cargarefrigerada: new FormControl(row.ccot_cargarefrigerada, null),
      cargapeligrosa: new FormControl(row.ccot_cargapeligrosa, null),
      roundtrip: new FormControl(row.ccot_roundtrip, null),
      temperatura: new FormControl(row.ccot_temperatura, null),
      imoclass: new FormControl(row.tipo_codimo, null),
      imoun: new FormControl(row.ccot_imoun, null),
      shipper: new FormControl(row.entc_nomshipper, null),
      consignee: new FormControl(row.entc_nomconsignee, null),
      notify: new FormControl(row.entc_nomnotify, null),
      referencia: new FormControl(row.doov_codreferencia, null),
    })

    this.group_observaciones = new FormGroup({
      almacenaje: new FormControl(row.ccot_almacenaje, null),
      sobreestadia: new FormControl(row.ccot_sobreestadia, null),
      tiempoviaje: new FormControl(row.ccot_tiempoviaje, null),
      validezoferta: new FormControl(row.ccot_validezoferta, null),
      instrucciones: new FormControl(row.ccot_observaciones, null),
    })

    this.group_novedades = new FormGroup({
      numerobooking: new FormControl(row.ddov_nrobooking, null),
      fecharecepcion: new FormControl(null, null),//Se eliminó
      deposito: new FormControl(row.entc_nomdeptemporal, null),
      blnieto: new FormControl(row.ddov_hblnieto, null),
      blhijo: new FormControl(row.doov_hbl, null),
      blmaster: new FormControl(row.doov_mbl, null),
      contenedor: new FormControl(row.doov_cntr, null),
      viaje: new FormControl(row.nvia_codigo, null),
      zarpe: new FormControl(null, null),
      etaetd: new FormControl(null, null),
      nave: new FormControl(row.nave_nombre, null),
      tiponotificacion: new FormControl(null, null),//Se eliminó
      descripcion: new FormControl(null, null),//Se eliminó
    })

    this.group_archivos = new FormGroup({
      archivo: new FormControl(null, null),
    })

    this.group_eventostareas = new FormGroup({
      tipoevento: new FormControl(null, null),
      observaciones: new FormControl(null, null),
      fechahora: new FormControl(null, null),
      estado: new FormControl(null, null),
      modulo: new FormControl(null, null),
      usuario: new FormControl(null, null),
    })

    this.group_servicioslocales = new FormGroup({
      codigooperacion: new FormControl(row.cope_codigo, null),
      numerooperacion: new FormControl(row.cope_numdoc, null),
      fechaemision: new FormControl(row.cope_fecemi ? new Date(row.cope_fecemi) : null, null),
      cliente: new FormControl(row.entc_nombrecliente, null),
      transportistaam: new FormControl(row.entc_nombretransportista, null),
      almacen: new FormControl(row.entc_nombrealmacen, null),
      agencia: new FormControl(row.entc_nombreagenciamaritima, null),
      terminal: new FormControl(null, null),
      condicion: new FormControl(row.descripcioncondicionembarque, null),
      transportista: new FormControl(null, null),
      sada: new FormControl(null, null),
      imo: new FormControl(null, null),
      tarifa: new FormControl(row.ctar_codigo, null),
      tipo: new FormControl(row.tarifftype, null),
      vigenciadesde: new FormControl(row.validfrom ? new Date(row.validfrom) : null, null),
      vigenciahasta: new FormControl(row.validto ? new Date(row.validto) : null, null),
      descripcion: new FormControl(row.remarks, null),
      observaciones: new FormControl(null, null),
    })
  }


  item2: ItemsListarOrdenesVentaResponse;
  exit: string = 'nuevo'
  datos: any[] = []

  LTWPAQUETEnombres: any[] = []//No hace nada
  enviarDatos(datos: string) { //No hace nada
    return this.parametros
      .filter(param => param.tipo === datos)
      .map(param => param.nombre);
  }

  //Listas para mandar al componente datatable, es para los select:
  ListaTipoPaquete: SelectItem[] = []
  ListaServicio: SelectItem[] = []
  //ListaIngreEgre: any[]=[]
  ListaTipoEntidad: SelectItem[] = []
  ListaProveedorG: SelectItem[] = []
  ListaBase: SelectItem[] = []
  ListaMoneda: SelectItem[] = []
  ListaComodity: SelectItem[] = []
  ListaUnidadMedida: SelectItem[] = []
  ListaTipoDocumento: SelectItem[] = []


  ListadoOperaciones: any[] = []
  ListadoGrupoPaquete: any[] = []
  ListadoServicioContenedores: any[] = []
  ListadoTarifaAsociada: any[] = []
  // ListadoDetalleAsociada:any[]=[]
  // ListadoServiciosAsociados:any[]=[]
  ListadoControlAsociados: any[] = []
  // ListadoEventosAsociados:any[]=[]

  //Declaraciones de metaDatas:
  serviciosytarifas_dataflete: any[] = []
  // metadatableFlete: newmetadate[]

  serviciosytarifas_dataservicios: any[] = []
  // metadatableServicio: newmetadate[]

  serviciosytarifas_dataseguro: any[] = []
  // metadatableSeguro: newmetadate[]

  datosembarque_dataflete: any[] = []
  metadatableFleteEmbarque: newmetadate[]

  viajeitinerario_dataitinerario: any[] = []
  // metadatableItinerario: newmetadate[]

  viajeitinerario_datanave: any[] = []
  metadatableDataNave: newmetadate[]

  archivos_dataarchivos: any[] = []
  metadatableDataArchivos: newmetadate[]

  eventostareas_dataeventos: any[] = []
  metadatableDataEventos: newmetadate[]

  servicioslocales_dataservicios: any[] = []
  metadatableDataServicios: newmetadate[]

  servicioslocales_datadetalle: any[] = []
  metadatableDataDetalle: newmetadate[]

  // setFilterData(table: any[], filtro: string) {
  //   let tabla = []
  //   tabla = table.filter(r => r['tipo'] === filtro)
  //   //console.log(tabla)
  //   return tabla
  // }
  // metadatableFlete: newmetadate[] = [
  //   { title: 'ITEM', field: 'dcot_item', filterType: 'numeric', inputType: 'int', inputStatus: 'lock' },
  //   { title: 'TIPO PAQUETE', field: 'pack_descc', inputType: 'dropdown', dropdown: this.ListaTipoPaquete },
  //   { title: 'CANTIDAD', field: 'dcot_cantidad', filterType: 'numeric', inputType: 'int' },
  //   { title: 'P.U. COSTO', field: 'dcot_preciounicosto', filterType: 'numeric', inputType: 'decimal' },
  //   { title: 'P.U. VENTA', field: 'dcot_preciouniventa', filterType: 'numeric', inputType: 'decimal' },
  //   { title: 'TOTAL COSTO', field: 'dcot_totalunicosto', filterType: 'numeric', inputType: 'decimal' },
  //   { title: 'TOTAL VENTA', field: 'dcot_totaluniventa', filterType: 'numeric', inputType: 'decimal', inputStatus: 'lock' },
  // ]

  metadatableFlete: newmetadate[] = [
    { title: 'ITEM', field: 'dcot_item', filterType: 'numeric', inputType: 'int'},
    { title: 'TIPO PAQUETE', field: 'pack_desc', inputType: 'dropdown', dropdown: this.ListaTipoPaquete },
    { title: 'CANTIDAD', field: 'dcot_cantidad', filterType: 'numeric', inputType: 'int' },
    { title: 'P.U. COSTO', field: 'dcot_preciounicosto', filterType: 'numeric', inputType: 'decimal' },
    { title: 'P.U. VENTA', field: 'dcot_preciouniventa', filterType: 'numeric', inputType: 'decimal' },
    { title: 'TOTAL COSTO', field: 'dcot_totalunicosto', filterType: 'numeric', inputType: 'decimal' },
    { title: 'TOTAL VENTA', field: 'dcot_totaluniventa', filterType: 'numeric', inputType: 'decimal'},
  ]

  metadatableServicio: newmetadate[] = [
    //{ title: 'PRUBEAS DATOS', field: '', key: 'scot_item' },
    { title: 'EXONERADO', field: 'scot_exonerado', filterType: 'boolean', inputType: 'checkbox', inputStatus: 'lock' },
    { title: 'SERVICIO', field: 'serv_nombre', filterType: 'text', inputType: 'autocomplete', dropdown: this.ListaServicio },
    { title: 'INGRESO/EGRESO', field: 'ccot_ingresogasto', filterType: 'text', inputType: 'autocomplete', dropdown: this.ListaServicio },//?
    // //{ title: 'ENTIDAD', field: null, filterType: 'text' },//boton
    { title: 'TIPO ENTIDAD', field: 'tipedescripcion', filterType: 'text', inputType: 'autocomplete', dropdown: this.ListaTipoEntidad, buscar: 'tipe_codigo', children: 'entc_nomcom' },
    //{ title: 'PROVEEDOR', field: 'entc_nomcom', filterType: 'text', inputType:'autocomplete_1', dropdown_1: this.ListaProveedorG)},
    { title: 'PROVEEDOR', field: 'entc_nomcom', filterType: 'text', inputType: 'autocomplete', dropdown: this.ListaProveedorG },
    { title: 'BASE', field: 'cons_desc_spa', filterType: 'text', inputType: 'dropdown', dropdown: this.ListaBase },
    { title: 'CANTIDAD', field: 'scot_cantidad', filterType: 'text', inputType: 'int' },
    { title: 'MONEDA', field: 'tipo_descmnd', filterType: 'text', inputType: 'dropdown', dropdown: this.ListaMoneda },
    { title: 'PRECIO UNITARIO', field: 'scot_preciouni', filterType: 'text', inputType: 'decimal' },
    { title: 'IMPORTE INGRESO', field: 'scot_importe_ingreso', filterType: 'text', inputType: 'decimal', inputStatus: 'lock' },
    { title: 'IMPORTE EGRESO', field: 'scot_importe_egreso', filterType: 'text', inputType: 'decimal', inputStatus: 'lock' },
    { title: 'AFECTO IGV', field: 'serv_afeigv', filterType: 'boolean', inputType: 'checkbox', inputStatus: 'lock' },
    { title: 'AFECTO DETRACCIÓN', field: 'serv_afedet', filterType: 'boolean', inputType: 'checkbox', inputStatus: 'lock' },
    //{ title: 'TIPO DOCUMENTO', field: null, filterType: 'text', inputType:'text' },//boton
    { title: 'TIPO DOCUMENTO', field: 'tipo_desctdo', filterType: 'text', inputType: 'autocomplete', dropdown: this.ListaTipoDocumento },//Lo seleccionado, llega como null desde la BD
    { title: 'SERIE DOCUMENTO', field: 'scot_serietdo', filterType: 'text', inputType: 'text' },
    { title: 'NRO. DOCUMENTO', field: 'scot_numerotdo', filterType: 'text', inputType: 'text' },
    { title: 'FEC. EMISIÓN', field: 'audi_feccrea', filterType: 'text', inputType: 'date' },//Cambiar luego por "scot_fechaemision, usando: audi_feccrea; this.formatDate('audi_feccrea', 'MM/DD/YYYY HH:mm:ss')"
  ]

  metadatableDataObservaciones: newmetadate[] = []

  // datosViajePorPuerto2:any[]=[]

  metadatableSeguro: newmetadate[] = [
    { title: 'EXONERADO', field: null, filterType: 'text', inputType: 'text' },
    { title: 'SERVICIO', field: null, filterType: 'text', inputType: 'text' },
    { title: 'INGRESO/EGRESO', field: null, filterType: 'text', inputType: 'text' },
    { title: 'ENTIDAD', field: null, filterType: 'text', inputType: 'text' },
    { title: 'TIPO ENTIDAD', field: null, filterType: 'text', inputType: 'text' },
    { title: 'PROVEEDOR', field: null, filterType: 'text', inputType: 'text' },
    { title: 'BASE', field: null, filterType: 'text', inputType: 'text' },
    { title: 'CANTIDAD', field: null, filterType: 'text', inputType: 'text' },
    { title: 'MONEDA', field: null, filterType: 'text', inputType: 'text' },
    { title: 'PRECIO UNITARIO', field: null, filterType: 'text', inputType: 'text' },
    { title: 'IMPORTE EGRESO', field: null, filterType: 'text', inputType: 'text' },
    { title: 'AFECTO IGV', field: null, filterType: 'text', inputType: 'text' },
    { title: 'AFECTO DETRACCIÓN', field: null, filterType: 'text', inputType: 'text' },
    { title: 'TIPO DOCUMENTO', field: null, filterType: 'text', inputType: 'text' },//
    { title: 'SERIE DOCUMENTO', field: null, filterType: 'text', inputType: 'text' },
    { title: 'NRO. DOCUMENTO', field: null, filterType: 'text', inputType: 'text' },
    { title: 'FEC. EMISIÓNNEW', field: null, filterType: 'text', inputType: 'text' },
  ]


  metadatableItinerario: newmetadate[] = [
    // { title: 'TRAMO', field: 'ovnv_item', key: 'ovnv_item', filterType: 'numeric', inputType: 'int', inputStatus: 'lock' },
    // { title: 'FEEDER', field: 'nvia_esfeeder', filterType: 'boolean', inputType: 'checkbox', inputStatus: 'lock' },
    // { title: 'FINAL', field: 'ovnv_final', filterType: 'boolean', inputType: 'checkbox', inputStatus: 'lock' },
    // { title: 'CÓDIGO VIAJE', field: 'nvia_codigo', filterType: 'text', inputType: 'text', popup: 'y', datosDialogTable: this.datosViajePorPuerto2 },
    // { title: 'NAVE', field: 'nave_nombre', filterType: 'text', inputType: 'text', inputStatus: 'lock' },
    // { title: 'VIAJE', field: 'nvia_nroviaje', filterType: 'text', inputType: 'text', inputStatus: 'lock' },
    // { title: 'ETD', field: 'etd', filterType: 'text', inputType: 'date' },
    // { title: 'FECHA EMBARQUE', field: 'fechaembarque', filterType: 'text', inputType: 'date' },
    // { title: 'ETA', field: 'eta', filterType: 'text', inputType: 'date' },
    // { title: 'POL', field: 'nameportloading', filterType: 'text', inputType: 'autocomplete' },
    // { title: 'POD', field: 'nameportdischarge', filterType: 'text', inputType: 'autocomplete' },
  ]

  async ngOnInit(): Promise<void> {

    this.inicializarFormularios([])
    this.parametros = await this.util.getParametros()

    this.ListarParametros()
    this.parametros.map(r => {
      if (r.tipo === 'PAQUETE') {
        const item: SelectItem = {
          label: r.nombre,
          value: r.codigo
        }
        this.ListaTipoPaquete.push(item)
      }
      if (r.tipo === 'TMC') {
        const item = {
          label: r.nombre,
          value: r.codigo
        }
        this.ListaServicio.push(item)
      }
      if (r.tipo === 'BAS') {
        const item = {
          label: r.nombre,
          value: r.codigo
        }
        //this.ListaBase.push(r)
        this.ListaBase.push(item)
      }
      if (r.tipo === 'MND') {
        const item = {
          label: r.nombre,
          value: r.codigo
        }
        // this.ListaMoneda.push(r)
        this.ListaMoneda.push(item)
      }
      if (r.tipo === 'TDO') {
        const item = {
          label: r.nombre,
          value: r.codigo
        }
        this.ListaTipoDocumento.push(item)
      }

      if (r.tipo === 'CDT') {
        const item = {
          label: r.nombre,
          value: r.codigo
        }
        this.ListaComodity.push(item)
      }
      if (r.tipo === 'UNM') {
        const item = {
          label: r.nombre,
          value: r.codigo
        }
        this.ListaUnidadMedida.push(item)
      }

      if (r.tipo === 'TIPE') {
        const item = {
          label: r.nombre,
          value: r.codigo
        }
        this.ListaTipoEntidad.push(item)
      }
      if (r.tipo === 'CLIENTE' || r.tipo === 'SHIPP' || r.tipo === 'TRANSPO' || r.tipo === 'AGE') { //chiwa
        const item = {
          label: r.nombre,
          value: r.codigo
        }
        this.ListaProveedorG.push(item)
      }


    })


    console.log("Lista de ProveedoresG: ", this.ListaBase)

    console.log("Tipos de entidad", this.ListaTipoEntidad)

    this.metadatableFleteEmbarque = [
      { title: 'ITEM', field: 'dcot_item', filterType: 'text', inputType: 'text', inputStatus: 'lock' },
      { title: 'COMODITY', field: 'cdt_desc', filterType: 'text', inputType: 'dropdown', dropdown: this.ListaComodity },//?
      { title: 'PRODUCTO', field: 'dcot_producto', filterType: 'text', inputType: 'text' },
      { title: 'BULTOS', field: 'dcot_bultos', filterType: 'numeric', inputType: 'decimal' },
      { title: 'UNIDAD MEDIDA', field: 'tipo_tabunm', filterType: 'text', inputType: 'dropdown', dropdown: this.ListaUnidadMedida },
      { title: 'PESO (Kg)', field: 'dcot_peso', filterType: 'numeric', inputType: 'decimal' },
      { title: 'VOLUMEN (m3)', field: 'dcot_volumen', filterType: 'numeric', inputType: 'decimal' },
      { title: 'LARGO (cm)', field: 'dcot_largo', filterType: 'numeric', inputType: 'decimal' },
      { title: 'ANCHO (cm)', field: 'dcot_ancho', filterType: 'numeric', inputType: 'decimal' },
      { title: 'ALTO (cm)', field: 'dcot_alto', filterType: 'numeric', inputType: 'decimal' },
    ]

    this.metadatableItinerario = [
      { title: 'TRAMO', field: 'ovnv_item', filterType: 'numeric', inputType: 'int', inputStatus: 'lock' },
      { title: 'FEEDER', field: 'nvia_esfeeder', filterType: 'boolean', inputType: 'checkbox', inputStatus: 'lock' },
      { title: 'FINAL', field: 'ovnv_final', filterType: 'boolean', inputType: 'checkbox', inputStatus: 'lock' },
      { title: 'CÓDIGO VIAJE', field: 'nvia_codigo', filterType: 'text', inputType: 'text', popup: 'y', datosDialogTable: this.datosViajePorPuerto2 },
      { title: 'NAVE', field: 'nave_nombre', filterType: 'text', inputType: 'text', inputStatus: 'lock' },
      { title: 'VIAJE', field: 'nvia_nroviaje', filterType: 'text', inputType: 'text', inputStatus: 'lock' },
      { title: 'ETD', field: 'etd', filterType: 'text', inputType: 'date' },
      { title: 'FECHA EMBARQUE', field: 'fechaembarque', filterType: 'text', inputType: 'date' },
      { title: 'ETA', field: 'eta', filterType: 'text', inputType: 'date' },
      { title: 'POL', field: 'nameportloading', filterType: 'text', inputType: 'autocomplete' },
      { title: 'POD', field: 'nameportdischarge', filterType: 'text', inputType: 'autocomplete' },
    ]

    this.metadatableDataNave = [
      { title: 'TRAMO', field: null, filterType: 'text', inputType: 'text' },
      { title: 'ETA CONTABLE', field: null, filterType: 'text', inputType: 'text' },
      { title: 'ETA OPERATIVA', field: null, filterType: 'text', inputType: 'text' },
      { title: 'FECHA CUTOFF', field: null, filterType: 'text', inputType: 'text' },
      { title: 'FECHA LLEGADA', field: null, filterType: 'text', inputType: 'text' },
      { title: 'FECHA ZARPE', field: null, filterType: 'text', inputType: 'text' },
      { title: 'FECHA ARRIBO', field: null, filterType: 'text', inputType: 'text' },
      { title: 'FECHA CIERRE DIRECC / TERMINO DE E.', field: null, filterType: 'text', inputType: 'text' },
      { title: 'FECHA DESCARGA', field: null, filterType: 'text', inputType: 'text' },
      { title: 'FECHA PLAZO VOBO', field: null, filterType: 'text', inputType: 'text' },
      { title: 'NRO. MANIFIESTO', field: null, filterType: 'text', inputType: 'text' },
      { title: 'NRO. MANIFIESTO DESCON', field: null, filterType: 'text', inputType: 'text' },
      { title: 'FECHA RECOGER DOCS', field: null, filterType: 'text', inputType: 'text' },
      { title: 'TERMINAL PORTUARIO', field: null, filterType: 'text', inputType: 'text' },
      { title: 'AGENTE PORTUARIO VOBO', field: null, filterType: 'text', inputType: 'text' },
    ]

    this.metadatableDataArchivos = [
      { title: 'ELIMINAR', field: null, filterType: 'text', inputType: 'text' },
      { title: 'FECHA', field: null, filterType: 'text', inputType: 'text' },
      { title: 'DESCRIPCIÓN', field: null, filterType: 'text', inputType: 'text' },
      { title: 'OBSERVACIÓN', field: null, filterType: 'text', inputType: 'text' },
    ]

    this.metadatableDataEventos = [
      { title: 'EVENTO/TAREA', field: 'tipo_desc1', filterType: 'text', inputType: 'text', inputStatus: 'lock' },
      { title: 'MODULO', field: 'cons_desc_spa', filterType: 'text', inputType: 'text', inputStatus: 'lock' },
      { title: 'FECHA', field: 'even_fecha', filterType: 'text', inputType: 'date', inputStatus: 'lock' },
      { title: 'CUMPLIDA', field: 'even_cumplida', filterType: 'boolean', inputType: 'checkbox', inputStatus: 'lock' },
      { title: 'USUARIO', field: 'even_usuario', filterType: 'text', inputType: 'text', inputStatus: 'lock' },
      { title: 'OBSERVACIONES', field: 'even_observaciones', filterType: 'text', inputType: 'text', inputStatus: 'lock' },
    ]

    this.metadatableDataServicios = [
      { title: 'SERVICIO', field: 'conceptdescription', filterType: 'text', inputType: 'text' },
      { title: 'UNIDAD', field: 'unitdescription', filterType: 'text', inputType: 'text' },
      { title: 'MONEDA', field: 'currency', filterType: 'text', inputType: 'text' },//???????
      { title: 'IMPORTE VENTA', field: 'salesamount', filterType: 'text', inputType: 'text' },
    ]

    this.metadatableDataObservaciones = [
      { title: 'FECHA', field: null, filterType: 'text', inputType: 'text' },
      { title: 'USUARIO', field: 'even_usuario', filterType: 'text', inputType: 'text' },
      { title: 'OBSERVACIONES', field: 'even_observaciones', filterType: 'text', inputType: 'text' },//??????
      { title: 'ARCHIVO', field: null, filterType: 'text', inputType: 'text' },

    ]

    this.metadatableDataDetalle = [
      { title: 'DESCRIPCIÓN', field: 'serv_nombre_spa', filterType: 'text', inputType: 'text' },
      { title: 'UNIDAD BASE', field: 'cons_desc_spa', filterType: 'text', inputType: 'text' },
      { title: 'TIPO ENTIDAD', field: 'tipoentidad', filterType: 'text', inputType: 'text' },//??????
      { title: 'RAZÓN SOCIAL', field: 'entc_razonsocial', filterType: 'text', inputType: 'text' },
      { title: 'VENTA (UNIDAD)', field: 'dope_preciounitvta', filterType: 'text', inputType: 'text' },
      { title: 'MONEDA', field: 'nombremnd', filterType: 'text', inputType: 'text' },//?
      { title: 'CANTIDAD', field: 'dope_cantidad', filterType: 'text', inputType: 'text' },
      { title: 'VENTA (TOTAL)', field: 'dope_preciototvta', filterType: 'text', inputType: 'text' },
    ]

    const datosRecibidos = JSON.parse(localStorage.getItem('datoov'))
    this.datos = datosRecibidos

    if (this.datos) {
      console.log("Los datos traidos son: ", this.datos);
    } else {
      console.log("No hay datos de FLETE o this.datos es null");
    }
    if (this.datos && this.datos['listadofletes']) {
      this.serviciosytarifas_dataflete = this.datos['listadofletes'];
      this.datosembarque_dataflete = this.datos['listadofletes'];
      // this.serviciosytarifas_dataservicios = this.datos['listadoservicio'];
      console.log("Los datos de FLETES: ", this.serviciosytarifas_dataflete);
    } else {
      console.log("No hay datos de FLETE o this.datos es null");
    }

    if (this.datos && this.datos['listadoservicio']) {

      this.serviciosytarifas_dataservicios = this.datos['listadoservicio'];

      console.log("Los datos de Servicios: ", this.serviciosytarifas_dataservicios);

    } else {

      console.log("No hay datos de SERVICIOS o this.datos es null");

    }

    if (this.datos && this.datos['listadoseguro']) {

      this.serviciosytarifas_dataseguro = this.datos['listadoseguro'];

      console.log("Los datos de SEGURO: ", this.serviciosytarifas_dataseguro);

    } else {

      console.log("No hay datos de SEGURO o this.datos es null");

    }

    if (this.datos && this.datos['listadoviajeitinerario']) {

      this.viajeitinerario_dataitinerario = this.datos['listadoviajeitinerario'];

      console.log("Los datos de  VIAJE ITINERARIO: ", this.viajeitinerario_dataitinerario);

    } else {

      console.log("No hay datos de VIAJE ITINERARIO o this.datos es null");

    }

    if (this.datos && this.datos['listadodetallenaveviaje']) {

      this.viajeitinerario_datanave = this.datos['listadodetallenaveviaje'];

      console.log("Los datos de DETALLENAVEVIAJE: ", this.viajeitinerario_datanave);

    } else {

      console.log("No hay datos de DETALLENAVEVIAJE o this.datos es null");

    }

    if (this.datos && this.datos['listadoarchivo']) {

      this.archivos_dataarchivos = this.datos['listadoarchivo'];

      console.log("Los datos de ARCHIVO: ", this.archivos_dataarchivos);

    } else {

      console.log("No hay datos de ARCHIVO o this.datos es null");

    }

    if (this.datos && this.datos['listadoeventostareas']) {

      this.eventostareas_dataeventos = this.datos['listadoeventostareas'];

      console.log("Los datos de EVENTOS TAREAS: ", this.eventostareas_dataeventos);

    } else {

      console.log("No hay datos de EVENTOS TAREAS o this.datos es null");

    }

    if (this.datos && this.datos['listadoseguimientoembarque']) {

      this.seguimiento_Embarque = this.datos['listadoseguimientoembarque'];

      console.log("Los datos de SeguimientoEmbarque: ", this.seguimiento_Embarque);

    } else {

      console.log("No hay datos de SeguimientoEmbarque o this.datos es null");

    }

    if (this.datosViajePorPuerto2) {

      console.log("Los datos de NAVEVIAJEPORPUERTO: ", this.datosViajePorPuerto2);

    } else {

      console.log("No hay datos de NAVEVIAJEPORPUERTO o this.datos es null");

    }

    //-----------------------------------------

    if (this.datos && this.datos['listadooperaciones']) {

      this.ListadoOperaciones = this.datos['listadooperaciones'];

      console.log("Los datos de ListadoOperaciones: ", this.ListadoOperaciones);

    } else {

      console.log("No hay datos de ListadoOperaciones o this.datos es null");

    }

    if (this.datos && this.datos['listadogrupopaquete']) {

      this.ListadoGrupoPaquete = this.datos['listadogrupopaquete'];

      console.log("Los datos de ListadoGrupoPaquete: ", this.ListadoGrupoPaquete);

    } else {

      console.log("No hay datos de ListadoGrupoPaquete o this.datos es null");

    }

    if (this.datos && this.datos['listadoserviciocontenedores']) {

      this.ListadoServicioContenedores = this.datos['listadoserviciocontenedores'];

      console.log("Los datos de ListadoServicioContenedores: ", this.ListadoServicioContenedores);

    } else {

      console.log("No hay datos de ListadoServicioContenedores o this.datos es null");

    }

    if (this.datos && this.datos['listadotarifaasociada']) {

      this.ListadoTarifaAsociada = this.datos['listadotarifaasociada'];

      console.log("Los datos de ListadoTarifaAsociada: ", this.ListadoTarifaAsociada);

    } else {

      console.log("No hay datos de ListadoTarifaAsociada o this.datos es null");

    }

    if (this.datos && this.datos['listadodetalleasociada']) {//Es una tabla

      this.servicioslocales_dataservicios = this.datos['listadodetalleasociada'];

      console.log("Los datos de ListadoDetalleAsociada: ", this.servicioslocales_dataservicios);

    } else {

      console.log("No hay datos de ListadoDetalleAsociada o this.datos es null");

    }

    if (this.datos && this.datos['listadoserviciosasociados']) {//Es una tabla

      this.servicioslocales_datadetalle = this.datos['listadoserviciosasociados'];

      console.log("Los datos de ListadoServiciosAsociados: ", this.servicioslocales_datadetalle);

    } else {

      console.log("No hay datos de ListadoServiciosAsociados o this.datos es null");

    }

    if (this.datos && this.datos['listadocontrolasociados']) {

      this.ListadoControlAsociados = this.datos['listadocontrolasociados'];

      console.log("Los datos de ListadoControlAsociados: ", this.ListadoControlAsociados);

    } else {

      console.log("No hay datos de ListadoControlAsociados o this.datos es null");

    }

    if (this.datos && this.datos['listadoeventosasociados']) {//Es un tabla

      this.servicioslocales_dataObservaciones = this.datos['listadoeventosasociados'];

      console.log("Los datos de ListadoEventosAsociados: ", this.servicioslocales_dataObservaciones);

    } else {

      console.log("No hay datos de ListadoEventosAsociados o this.datos es null");

    }



    //console.log(this.datos)
    //localStorage.removeItem('datoov');
    const opcion = localStorage.getItem('Opcion')
    console.log("La opcion es: ", opcion)

    console.log("Son los datos Recibidos: ", datosRecibidos);
    //console.log("FECHA ZZ es: ", datosRecibidos.ccot_fecemi)

    //Daniel


    if (opcion == 'EDITAR') {
      console.log("Entró a EDITAR")
      this.item2 = {
        //=====DATOS GENERALES============
        ccot_numdoc: datosRecibidos.ccot_numdoc,
        ccot_numdoccot: datosRecibidos.ccot_numdoccot,
        cons_codest: datosRecibidos.cons_codest,
        cons_descest: datosRecibidos.cons_descest,
        cons_codrgm: datosRecibidos.cons_codrgm,
        cons_descrgm: datosRecibidos.cons_descrgm,
        cons_codvia: datosRecibidos.cons_codvia,
        cons_descvia: datosRecibidos.cons_descvia,
        cons_codlng: datosRecibidos.cons_codlng,
        cons_descfle: datosRecibidos.cons_descfle,
        ccot_propia: datosRecibidos.ccot_propia,
        ccot_enviaavisollegada: datosRecibidos.ccot_enviaavisollegada,
        ccot_serviciologistico: datosRecibidos.ccot_serviciologistico,
        ccot_serviciotransmision: datosRecibidos.ccot_serviciotransmision,
        ccot_fecemi: datosRecibidos.ccot_fecemi,
        ccot_fecemiformat: datosRecibidos.ccot_fecemiformat,
        ccot_fecvcto: datosRecibidos.ccot_fecvcto,
        ccot_fecaprueba: datosRecibidos.ccot_fecaprueba,
        ddov_fecembarque: datosRecibidos.ddov_fecembarque,
        entc_nomcliente: datosRecibidos.entc_nomcliente,
        entC_NomCustomer: datosRecibidos.entc_nomcustomer,
        entc_nomagente: datosRecibidos.entc_nomagente,
        tipo_codinc: datosRecibidos.tipo_codinc,
        ccot_ordencompra: datosRecibidos.ccot_ordencompra,
        entc_nomejecutivo: datosRecibidos.entc_nomejecutivo,
        ccot_numpresupuesto: datosRecibidos.ccot_numpresupuesto,

        //=====SERVICIOS Y TARIFA============================
        ///---datos de contrato
        entc_nomtransportista: datosRecibidos.entc_nomtransportista,
        puer_nombretrasbordo: datosRecibidos.puer_nombretrasbordo,
        tipo_codtrf: datosRecibidos.tipo_codtrf,
        puer_nombreorigen: datosRecibidos.puer_nombreorigen,
        puer_nombredestino: datosRecibidos.puer_nombredestino,
        tipo_codmnd: datosRecibidos.tipo_codmnd,
        cons_codtft: datosRecibidos.cons_codtft,
        ccot_fectarifa: datosRecibidos.ccot_fectarifa,
        cons_codfle: datosRecibidos.cons_codfle,//Es la condicion?
        cont_numero: datosRecibidos.cont_numero,
        cont_descrip: datosRecibidos.cont_descrip,
        ///--flete
        ccot_pagombl: this.tranformarDa(datosRecibidos.ccot_pagombl),
        ccot_pagohbl: this.tranformarDa(datosRecibidos.ccot_pagohbl),
        dcot_totaluniventa: datosRecibidos["listadofletes"][0].dcot_totaluniventa,
        //Dias Libres sobre Estadia es lo mimso que está en Observaciones
        //--servicios        
        dcot_preciounicosto: datosRecibidos.dcot_preciounicosto,
        //--seguro
        //DATOS EMBARQUE=============================
        ccot_cargarefrigerada: datosRecibidos.ccot_cargarefrigerada,
        ccot_cargapeligrosa: datosRecibidos.ccot_cargapeligrosa,
        ccot_roundtrip: datosRecibidos.ccot_roundtrip,
        ccot_temperatura: datosRecibidos.ccot_temperatura,
        tipo_codimo: datosRecibidos.tipo_codimo,
        ccot_imoun: datosRecibidos.ccot_imoun,
        entc_nomshipper: datosRecibidos.entc_nomshipper,
        entc_nomconsignee: datosRecibidos.entc_nomconsignee,
        entc_nomnotify: datosRecibidos.entc_nomnotify,
        doov_codreferencia: datosRecibidos.doov_codreferencia,
        //OBSERVACIONES-----------
        ccot_almacenaje: datosRecibidos.ccot_almacenaje,
        ccot_sobreestadia: datosRecibidos.ccot_sobreestadia,
        ccot_tiempoviaje: datosRecibidos.ccot_tiempoviaje,
        ccot_validezoferta: datosRecibidos.ccot_validezoferta,
        ccot_observaciones: datosRecibidos.ccot_observaciones,
        //VIAJE ITENERARIO------------------
        //NOVEDADES--------------------
        ddov_nrobooking: datosRecibidos.ddov_nrobooking,
        //fechaRecepcion
        entc_nomdeptemporal: datosRecibidos.entc_nomdeptemporal,
        ddov_hblnieto: datosRecibidos.ddov_hblnieto,
        doov_hbl: datosRecibidos.doov_hbl,
        doov_mbl: datosRecibidos.doov_mbl,
        doov_cntr: datosRecibidos.doov_cntr,
        nvia_codigo: datosRecibidos.nvia_codigo,
        //zARPE
        //ETA
        nave_nombre: datosRecibidos.nave_nombre,
        //ARCHIVO------------------
        //EVENTOS/TAREAS-------------



        //SERVICIOS LOCALES-----------
        cope_codigo: datosRecibidos["listadooperaciones"][0].cope_codigo,
        cope_numdoc: datosRecibidos["listadooperaciones"][0].cope_numdoc,
        cope_fecemi: datosRecibidos["listadooperaciones"][0].cope_fecemi,
        entc_nombrecliente: datosRecibidos["listadooperaciones"][0].entc_nombrecliente,
        entc_nombretransportista: datosRecibidos["listadooperaciones"][0].entc_nombretransportista,
        entc_nombrealmacen: datosRecibidos["listadooperaciones"][0].entc_nombrealmacen,
        entc_nombreagenciamaritima: datosRecibidos["listadooperaciones"][0].entc_nombreagenciamaritima,
        entc_nombreterminalportuario: datosRecibidos["listadooperaciones"][0].entc_nombreterminalportuario,
        descripcioncondicionembarque: datosRecibidos["listadooperaciones"][0].descripcioncondicionembarque,
        //Transportista (Jalado de arriba)?????
        //SADA???
        //IMO?????
        ctar_codigo: datosRecibidos["listadotarifaasociada"][0].ctar_codigo,
        tarifftype: datosRecibidos["listadotarifaasociada"][0].tarifftype,
        validfrom: datosRecibidos["listadotarifaasociada"][0].validfrom,
        validto: datosRecibidos["listadotarifaasociada"][0].validto,
        remarks: datosRecibidos["listadotarifaasociada"][0].remarks,
        //observaciones
      };
      console.log("Datos FORMATEADOS:", this.item2)
      this.inicializarFormularios(this.item2)
      this.date = this.group_datosgenerales.get('fechaemision')?.value;
      console.log("La fecha es<<<: ", this.date);

      //Cambiando el acceso a cada dato
      if (datosRecibidos.cons_codest = "004") {
        console.log("Entro a 004")
        //   //Datos Generales
        //   this.readOnlyNumero = true;
        //   this.readOnlyNumeroCotizacion = true;
        //   this.visibledCotPropia = true;
        // this.readOnlyEstado = true;
        // this.disabledRegimen = true;
        // this.disabledVia = true;
        // this.disabledFEmision = true;
        // this.disabledFVencimiento = true;
        // this.disabledFAprobacion = true;
        // this.disabledFEmbarque = true;
        // this.readOnlyCliente = true;
        // this.group_datosgenerales.get('cotizacionpropia')?.disable();
        // this.group_datosgenerales.get('avisodellegada')?.disable();
        // this.group_datosgenerales.get('servicio')?.disable();
        // this.disabledXCliente = true;

        // this.readOnlyCustomer = true;
        // this.disabledXCustomer = true;
        // this.readOnlyAgente = true;
        // this.disabledXAgente = true;
        // this.disabledIncoterm = true;
        // this.readOnlyEjecutivo = true;
        // this.disabledXEjecutivo = true;

        // this.readOnlyNoCRM = true;
        // this.disabledTrafico = true;
        // this.readOnlyFleteTotal = true;
        // this.readOnlyViaje = true;
        // this.disabledFZarpe = true;
        // this.disabledFEtaEtd = true;
        // this.readOnlyNave = true;
        // this.readOnlyUsuario = true;
        // this.readOnlyCodigoOperacion = true;
        // this.|Opeacion = true;
        // this.readOnlyClienteSL = true;
        // this.readOnlyTransportistaAM = true;
        // this.readOnlyAlmacen = true;
        // this.readOnlyAgenciaMaritima = true;
        // this.readOnlyTerminalPortuario = true;
        // this.readOnlyCondicionEmbarque = true;
      }


      // this.group_datosgenerales.get('estado')?.disable();
    } else if (opcion == 'NUEVO') {
      console.log("Entró a NUEVO")
      this.item2 = {
        //=====DATOS GENERALES============
        ccot_numdoc: null,
        ccot_numdoccot: null,
        cons_codest: null,
        cons_descest: null,
        cons_codrgm: null,
        cons_descrgm: null,
        cons_codvia: null,
        cons_descvia: null,
        cons_codlng: null,
        cons_descfle: null,
        ccot_propia: false,
        ccot_enviaavisollegada: false,
        ccot_serviciologistico: false,
        ccot_serviciotransmision: false,
        ccot_fecemi: null,
        ccot_fecemiformat: null,
        ccot_fecvcto: null,
        ccot_fecaprueba: null,
        ddov_fecembarque: null,
        entc_nomcliente: null,
        entC_NomCustomer: null,
        entc_nomagente: null,
        tipo_codinc: null,
        ccot_ordencompra: null,
        entc_nomejecutivo: null,
        ccot_numpresupuesto: null,
        //=====SERVICIOS Y TARIFA============================
        ///---datos de contrato
        entc_nomtransportista: null,
        puer_nombretrasbordo: null,
        tipo_codtrf: null,
        puer_nombreorigen: null,
        puer_nombredestino: null,
        tipo_codmnd: null,
        cons_codtft: null,
        ccot_fectarifa: null,
        cons_codfle: null,//Es la condicion?
        cont_numero: null,
        cont_descrip: null,
        ///--flete
        ccot_pagombl: null,
        ccot_pagohbl: null,
        dcot_totaluniventa: null,
        //Dias Libres sobre Estadia es lo mimso que está en Observaciones
        //--servicios
        dcot_preciounicosto: null,
        //--seguro
        //DATOS EMBARQUE=============================
        ccot_cargarefrigerada: false,
        ccot_cargapeligrosa: false,
        ccot_roundtrip: false,
        ccot_temperatura: null,
        tipo_codimo: null,
        ccot_imoun: null,
        entc_nomshipper: null,
        entc_nomconsignee: null,
        entc_nomnotify: null,
        doov_codreferencia: null,
        //OBSERVACIONES-----------
        ccot_almacenaje: null,
        ccot_sobreestadia: null,
        ccot_tiempoviaje: null,
        ccot_validezoferta: null,
        ccot_observaciones: null,
        //VIAJE ITENERARIO------------------
        //NOVEDADES--------------------
        ddov_nrobooking: null,
        //fechaRecepcion
        entc_nomdeptemporal: null,
        ddov_hblnieto: false,
        doov_hbl: null,
        doov_mbl: null,
        doov_cntr: null,
        nvia_codigo: null,
        //zARPE
        //ETA
        nave_nombre: null,
        //ARCHIVO------------------
        //EVENTOS/TAREAS-------------

        //SERVICIOS LOCALES-----------
        cope_codigo: null,
        cope_numdoc: null,
        cope_fecemi: null,
        entc_nombrecliente: null,
        entc_nombretransportista: null,
        entc_nombrealmacen: null,
        entc_nombreagenciamaritima: null,
        entc_nombreterminalportuario: null,//Terminal portuariao?????
        descripcioncondicionembarque: null,
        //Transportista (Jalado de arriba)?????
        //SADA???
        //IMO?????
        ctar_codigo: null,
        tarifftype: null,
        validfrom: null,
        validto: null,
        remarks: null,
        //observaciones
      };
      console.log("Datos FORMATEADOS:", this.item2)
      this.inicializarFormularios(this.item2)

      this.readOnlyNumeroDGDG = true;
      this.readOnlyNumeroCotizacionDG = true;
      this.readOnlyEstadoDG = true;
      this.readOnlyNoCRMDG = true;
      this.readonlyFEmbarqueDG = true;

    }


    this.group_eventostareas.get('estado')?.setValue('1');


  }

  //Daniel
  // compareFn(option1: any, option2: any): boolean {
  //   return option1 && option2 ? option1 === option2 : option1 === option2;
  // }

  //Para listar en cada cbx: 
  // --DatosGenerales:
  ListaTodosEstado: any[] = []
  ListaTodosRegimen: any[] = []
  ListaTodosVia: any[] = []
  ListaTodosLineaNegocio: any[] = []
  ListaTodosCliente: any[] = []
  ListaTodosCustomer: any[] = []
  ListaTodosAgentes: any[] = []
  ListaTodosIncoterm: any[] = []
  ListaTodosEjecutivos: any[] = []
  // --ServicioYTarifa
  ListaTodosTransportista: any[] = []//dANIEL 24
  ListaTodosPuertoTransbordo: any[] = []
  ListaTodosTrafico: any[] = []
  ListaTodosPuertoOrigen: any[] = []
  ListaTodosPuertoDestino: any[] = []
  ListaTodosMoneda: any[] = []
  ListaTodosTipoFechaTarifa: any[] = []
  ListaTodosCondicion: any[] = []
  ListaTodosPCMBL: any[] = []
  ListaTodosPCHBL: any[] = []
  // --DatosEmbarque
  ListaTodosImoClass: any[] = []
  ListaTodosShipper: any[] = []
  ListaTodosConsignee: any[] = []
  ListaTodosNotify: any[] = []

  // --Novedades
  ListaTodosDeposito: any[] = []

  // --EventosTareas
  ListaTodosEventoTarea: any[] = []
  ListTodosModulo: any[] = []

  // --ServicioLocales

  ListaTodosTarifa: any[] = []

  // servicioslocales_dataservicios: any[] = []

  // servicioslocales_datadetalle: any[] = []

  servicioslocales_dataObservaciones: any[] = []

  //ListaTodosDepositoTemp: any[] = []
  ListarParametros() {
    // --DatosGenerales
    // this.ListaEstado=[]
    // this.ListaRegimen=[]
    // this.ListaVia=[]
    // this.ListaLineaNegocio=[]
    // this.ListaCliente=[]
    // this.ListaTodosCustomer = []
    // this.ListaEjecutivos = []
    // this.ListaAgentes = []
    // this.ListaTodosTransportista = []//dANIEL 24
    // this.ListaTodosShipper = []//dANIEL 
    // this.ListaTodosPuertoOrigen = []
    // this.ListaTodosPuertoDestino = []
    // this.ListaTodosConsignee = []
    // this.ListaTodosNotify = []
    // this.ListaTodosDepositoTemp = []
    //============================================================
    // --DatosGenerales
    this.ListaTodosEstado = this.parametros.slice().filter(r => r.tipo === 'OVE')
    this.ListaTodosRegimen = this.parametros.slice().filter(r => r.tipo === 'RGM')
    this.ListaTodosVia = this.parametros.slice().filter(r => r.tipo === 'VIA')
    this.ListaTodosLineaNegocio = this.parametros.slice().filter(r => r.tipo === 'LNG')
    this.ListaTodosCliente = this.parametros.slice().filter(r => r.tipo === 'CLIENTE')
    this.ListaTodosCustomer = this.parametros.slice().filter(r => r.tipo === 'CUS')
    this.ListaTodosAgentes = this.parametros.slice().filter(r => r.tipo === 'AGE')
    this.ListaTodosIncoterm = this.parametros.slice().filter(r => r.tipo === 'INC')
    this.ListaTodosEjecutivos = this.parametros.slice().filter(r => r.tipo === 'EJC')

    // --ServiciosYTarifa
    this.ListaTodosTransportista = this.parametros.slice().filter(r => r.tipo === 'TRANSPORTISTA')//dANIEL 24
    this.ListaTodosPuertoTransbordo = this.parametros.slice().filter(r => r.tipo === 'TRANSBORDO')//dANIEL 24
    this.ListaTodosTrafico = this.parametros.slice().filter(r => r.tipo === 'TRF')
    this.ListaTodosPuertoOrigen = this.parametros.slice().filter(r => r.tipo === 'ORIGEN')//dANIEL 24
    this.ListaTodosPuertoDestino = this.parametros.slice().filter(r => r.tipo === 'DESTINO')//dANIEL 24
    this.ListaTodosMoneda = this.parametros.slice().filter(r => r.tipo === 'MND')//dANIEL 24
    this.ListaTodosTipoFechaTarifa = this.parametros.slice().filter(r => r.tipo === 'TFT')//dANIEL 24
    this.ListaTodosCondicion = this.parametros.slice().filter(r => r.tipo === 'FLE')//dANIEL 24
    this.ListaTodosPCMBL = this.parametros.slice().filter(r => r.tipo === 'CTP')//dANIEL 24
    this.ListaTodosPCHBL = this.parametros.slice().filter(r => r.tipo === 'CTP')//dANIEL 24

    // --Datos Embarque    
    this.ListaTodosImoClass = this.parametros.slice().filter(r => r.tipo === 'IMO')//dANIEL 24
    this.ListaTodosShipper = this.parametros.slice().filter(r => r.tipo === 'SHIPPER')//dANIEL 24
    this.ListaTodosConsignee = this.parametros.slice().filter(r => r.tipo === 'CLIENTE')//dANIEL 24
    this.ListaTodosNotify = this.parametros.slice().filter(r => r.tipo == 'CLIENTE')//DANIEL30

    // --Novedades
    this.ListaTodosDeposito = this.parametros.slice().filter(r => r.tipo === 'DepositoTemp')//dANIEL 24

    // --EventoTarea
    this.ListaTodosEventoTarea = this.parametros.slice().filter(r => r.tipo === 'EVE')//dANIEL 24
    this.ListTodosModulo = this.parametros.slice().filter(r => r.tipo === 'MOD')//dANIEL 24

    // -ServicioLocales
    this.ListaTodosTarifa = this.parametros.slice().filter(r => r.tipo === 'WEB')//dANIEL 24


  }

  limpiar(value) {
    this.group_datosgenerales.get(value).reset();
    this.CodigosFiltrosAutocomplete[0][value] = 0
  }

  limpiarST(value) {
    this.group_serviciostarifa.get(value).reset();
    this.CodigosFiltrosAutocomplete[0][value] = 0
  }

  limpiarDE(value) {
    this.group_datosembarque.get(value).reset();
    this.CodigosFiltrosAutocomplete[0][value] = 0
  }

  limpiarNovedades(value) {
    this.group_novedades.get(value).reset();
    this.CodigosFiltrosAutocomplete[0][value] = 0
  }

  // CLIENTES - DATOS GENERALES
  listClienteInterno: listaClienteInterno[] = [];
  cargaRucInterno(condicion) {
    const values = this.group_datosgenerales.value;
    const requestRucInterno: rucInternoRequest = <rucInternoRequest>{};
    if (condicion == 'D') {
      requestRucInterno.pvchTIPE_Codigo = "1";
      requestRucInterno.pvchENTC_NomCompleto = values['cliente'];
      requestRucInterno.pvchENTC_DocIden = "";
      this.proformaService.listarRucInterno(requestRucInterno).subscribe(response => {
        this.listClienteInterno.splice(0, this.listClienteInterno.length);
        for (let i = 0; i < response.datos.result.length; i++) {
          const item: listaClienteInterno = {
            nombreCliente: response.datos.result[i].entC_RazonSocial,
            codigoCliente: response.datos.result[i].entC_Codigo
          }
          this.listClienteInterno.push(item);
        }
      })
    }
  }

  CodigosFiltrosAutocomplete = [{
    ejecutivo: 0,
    customer: 0,
    agente: 0,
    broker: 0,
    cliente: 0,
    transportista: 0,
    group_novedades: 0,    /*Daniel23 */
    shipper: 0,
    puertoOrigen: 0,
    puertoDestino: 0,
    puertoTransbordo: 0,
    consignee: 0,
    notify: 0,
    depositoTemp: 0,
  }]

  textoFiltro: string = '';
  opcionSeleccionadacliente(event: MatAutocompleteSelectedEvent) {
    for (let i = 0; i < this.listClienteInterno.length; i++) {
      if (this.listClienteInterno[i].nombreCliente == event.option.value) {
        this.CodigosFiltrosAutocomplete[0].cliente = this.listClienteInterno[i].codigoCliente;
        break;
      }
    }
  }

  //CUSTOMER DATOS GENERALES
  // CustomerSeleccionado: any;
  // OpcionSeleccionadaCustomer(event: MatAutocompleteSelectedEvent) {
  //   this.CustomerSeleccionado = this.ListaTodosCustomer.find(option => option.nombre === event.option.value);

  //   this.CodigosFiltrosAutocomplete[0].customer = this.CustomerSeleccionado.codigo;
  // }

  // CustomerFiltrados: any[];
  // onInputChangeCustomer(value: string) {
  //   this.CustomerFiltrados = this.ListaTodosCustomer.filter(option =>
  //     option.nombre.toLowerCase().includes(value.toLowerCase())
  //   );
  // }

  TransportistaSeleccionado: any; //Daniel 24
  OpcionSeleccionadaTransportista(event: MatAutocompleteSelectedEvent) {
    this.TransportistaSeleccionado = this.ListaTodosTransportista.find(option => option.nombre === event.option.value);

    this.CodigosFiltrosAutocomplete[0].transportista = this.TransportistaSeleccionado.codigo;
  }

  TranpostistaFiltrados: any[]; //Daniel 24
  onInputChangeTransportista(value: string) {
    this.TranpostistaFiltrados = this.ListaTodosTransportista.filter(option =>
      option.nombre.toLowerCase().includes(value.toLowerCase())
    );
  }

  PuertoOrigenSeleccionado: any; //Daniel 24
  OpcionSeleccionadaPuertoOrigenSeleccionado(event: MatAutocompleteSelectedEvent) {
    this.PuertoOrigenSeleccionado = this.ListaTodosPuertoOrigen.find(option => option.nombre === event.option.value);

    this.CodigosFiltrosAutocomplete[0].puertoOrigen = this.PuertoOrigenSeleccionado.codigo;

    console.log("Eligió esto: ", this.CodigosFiltrosAutocomplete[0].puertoOrigen)
  }

  PuertoOrigenSeleccionadoFiltrados: any[]; //Daniel 24
  onInputChangePuertoOrigenSeleccionado(value: string) {
    this.PuertoOrigenSeleccionadoFiltrados = this.ListaTodosPuertoOrigen.filter(option =>
      option.nombre.toLowerCase().includes(value.toLowerCase())
    );
  }

  PuertoDestinoSeleccionado: any; //Daniel 24
  OpcionSeleccionadaPuertoDestinoSeleccionado(event: MatAutocompleteSelectedEvent) {
    this.PuertoDestinoSeleccionado = this.ListaTodosPuertoDestino.find(option => option.nombre === event.option.value);

    this.CodigosFiltrosAutocomplete[0].puertoDestino = this.PuertoDestinoSeleccionado.codigo;
  }

  PuertoDestinoFiltrado: any[]; //Daniel 24
  onInputChangePuertoDestinoSeleccionado(value: string) {
    this.PuertoDestinoFiltrado = this.ListaTodosPuertoDestino.filter(option =>
      option.nombre.toLowerCase().includes(value.toLowerCase())
    );
  }

  PuertoTransbordoSeleccionado: any; //Daniel 24
  OpcionSeleccionadaPuertoTransbordo(event: MatAutocompleteSelectedEvent) {
    this.PuertoTransbordoSeleccionado = this.ListaTodosPuertoTransbordo.find(option => option.nombre === event.option.value);

    this.CodigosFiltrosAutocomplete[0].puertoTransbordo = this.PuertoTransbordoSeleccionado.codigo;
  }

  PuertoTransbordoFiltrado: any[]; //Daniel 24
  onInputChangePuertoTransbordoSeleccionado(value: string) {
    this.PuertoTransbordoFiltrado = this.ListaTodosPuertoTransbordo.filter(option =>
      option.nombre.toLowerCase().includes(value.toLowerCase())
    );
  }


  ShipperSeleccionado: any; //Daniel 24
  OpcionSeleccionadaShipper(event: MatAutocompleteSelectedEvent) {
    this.ShipperSeleccionado = this.ListaTodosShipper.find(option => option.nombre === event.option.value);

    this.CodigosFiltrosAutocomplete[0].shipper = this.ShipperSeleccionado.codigo;
  }


  ShipperFiltrados: any[]; //Daniel 24
  onInputChangeShipper(value: string) {
    this.ShipperFiltrados = this.ListaTodosShipper.filter(option =>
      option.nombre.toLowerCase().includes(value.toLowerCase())
    );
  }

  ConsigneeSeleccionado: any; //Daniel 24
  OpcionSeleccionadaConsignee(event: MatAutocompleteSelectedEvent) {
    this.ConsigneeSeleccionado = this.ListaTodosConsignee.find(option => option.nombre === event.option.value);

    this.CodigosFiltrosAutocomplete[0].consignee = this.ConsigneeSeleccionado.codigo;
  }
  ConsigneeFiltrados: any[]; //Daniel 24
  onInputChangeConsignee(value: string) {
    this.ConsigneeFiltrados = this.ListaTodosConsignee.filter(option =>
      option.nombre.toLowerCase().includes(value.toLowerCase())
    );
  }

  NotifySeleccionado: any; //Daniel 24
  OpcionSeleccionadaNotify(event: MatAutocompleteSelectedEvent) {
    this.NotifySeleccionado = this.ListaTodosNotify.find(option => option.nombre === event.option.value);

    this.CodigosFiltrosAutocomplete[0].notify = this.NotifySeleccionado.codigo;
  }
  NotifyFiltrados: any[]; //Daniel 24
  onInputChangeNotify(value: string) {
    this.NotifyFiltrados = this.ListaTodosNotify.filter(option =>
      option.nombre.toLowerCase().includes(value.toLowerCase())
    );
  }

  DespositoTempSeleccionado: any; //Daniel 24
  OpcionSeleccionadaDepositoTemp(event: MatAutocompleteSelectedEvent) {
    this.DespositoTempSeleccionado = this.ListaTodosDeposito.find(option => option.nombre === event.option.value);

    this.CodigosFiltrosAutocomplete[0].depositoTemp = this.DespositoTempSeleccionado.codigo;
  }
  DepositoTempFiltrados: any[]; //Daniel 24
  onInputChangeDepositoTemp(value: string) {
    this.DepositoTempFiltrados = this.ListaTodosDeposito.filter(option =>
      option.nombre.toLowerCase().includes(value.toLowerCase())
    );
  }


  //AGENTE - DATOS GENERALES
  //ListaAgentes: any[] = []
  // ListarAgentes() {
  //   //const request = <requestTransportista>{};
  //   const request = <ListarTodosWebRequest>{};  
  //   // request.pintENTC_Codigo = 1;
  //   // request.pintENTC_Razonsocial = "";
  //   request.codigo = 1;
  //   request. tipo= "";

  //   // this.proformaService.listaTransportista(request).subscribe(response => {
  //   //   // Actualizar listaTransportista
  //   //   for (let i = 0; i < response.datos.result.length; i++) {
  //   //     const item: ListParametros = {
  //   //       nombre: response.datos.result[i].descripcionTransportista,
  //   //       codigo: response.datos.result[i].codigoTransportista.toString(),
  //   //       tipo: 'AGE'
  //   //     }
  //   //     this.ListaAgentes.push(item);
  //   //   }
  //   // });
  //   this.proformaService.ListarTodosWeb(request).subscribe(response => {
  //     //Actualizar listaTransportista
  //     for (let i = 0; i < response.datos.result.length; i++) {
  //       const item: ListParametros = {
  //         nombre: response.datos.result[i].nombre,
  //         codigo: response.datos.result[i].codigo.toString(),
  //         tipo: 'AGE'
  //         //tipo: 'J'
  //       }
  //       this.ListaAgentes.push(item);
  //     }
  //   });
  // }

  // AgenteSeleccionado: any;
  // OpcionSeleccionadaAgente(event: MatAutocompleteSelectedEvent) {
  //   this.AgenteSeleccionado = this.ListaAgentes.find(option => option.nombre === event.option.value);

  //   this.CodigosFiltrosAutocomplete[0].agente = this.AgenteSeleccionado.codigo;
  // }

  // TransportistaSeleccionado: any;
  // OpcionSeleccionadaTransportista(event: MatAutocompleteSelectedEvent) {
  //   this.TransportistaSeleccionado = this.ListaAgentes.find(option => option.nombre === event.option.value);

  //   this.CodigosFiltrosAutocomplete[0].agente = this.TransportistaSeleccionado.codigo;
  // }

  // AgentesFiltrados: any[];
  // onInputChangeAgente(value: string) {
  //   this.AgentesFiltrados = this.ListaAgentes.filter(option =>
  //     option.nombre.toLowerCase().includes(value.toLowerCase())
  //   );
  // }

  // TransportistasFiltrados: any[];
  // onInputChangeTransportista(value: string) {
  //   this.AgentesFiltrados = this.ListaAgentes.filter(option =>
  //     option.nombre.toLowerCase().includes(value.toLowerCase())
  //   );
  // }

  //EJECUTIVOS - DATOS GENERALES  filteredOptions: any[];
  // EjecutivoSeleccionado: any;
  // OpcionSeleccionadaEjecutivo(event: MatAutocompleteSelectedEvent) {
  //   this.EjecutivoSeleccionado = this.ListaEjecutivos.find(option => option.nombre === event.option.value);

  //   this.CodigosFiltrosAutocomplete[0].ejecutivo = this.EjecutivoSeleccionado.codigo;
  // }

  // EjecutivosFiltrados: any[];
  // onInputChangeEjecutivo(value: string) {
  //   this.EjecutivosFiltrados = this.ListaEjecutivos.filter(option =>
  //     option.nombre.toLowerCase().includes(value.toLowerCase())
  //   );
  // }

  chargedate(event: any, campo: string) {
    // const selectedDate = event.value;
    // // selectedDate.setHours(0, 0, 0, 0);
    // this.group.get(campo).setValue(selectedDate.toISOString());
  }

  chargedate2(event: any, campo: string, formulario: FormGroup) {
    // const selectedDate = event.value;
    // // selectedDate.setHours(0, 0, 0, 0); 
    // formulario.get(campo).setValue(selectedDate.toISOString());
  }

  // SERVICIOS Y TARIFAS



  showMessage(row: any) {
    console.log(row)
  }
  deleteRow(row: any): void {
    const index = this.serviciosytarifas_dataflete.indexOf(row);
    if (index > -1) {
      this.serviciosytarifas_dataflete.splice(index, 1);
    }
  }


  // serviciosytarifas_dataservicios: any[] = []
  // // serviciosytarifas_metadataservicios: MetadataTable[] = [
  // //   { field: 'scot_exonerado', title: 'EXONERADO', type: 'checkbox'},
  // //   { field: 'serv_nombre', title: 'SERVICIO' },
  // //   { field: 'ccot_ingresogasto', title: 'INGRESO/EGRESO' },
  // //   { field: '4', title: 'ENTIDAD' },//DEBE HABER UN BOTON
  // //   { field: '5', title: 'TIPO ENTIDAD' },
  // //   { field: 'entc_nomcom', title: 'PROVEEDOR' },
  // //   { field: 'cons_codbas', title: 'BASE' },//DE LISTAR TODOS WEB? BAS
  // //   { field: 'scot_cantidad', title: 'CANTIDAD' },
  // //   { field: 'tipo_descmnd', title: 'MONEDA' },
  // //   { field: 'scot_preciouni', title: 'PRECIO UNITARIO' },
  // //   { field: 'scot_importe_egreso', title: 'IMPORTE EGRESO' },
  // //   { field: 'serv_afeigv', title: 'AFECTO IGV' },//CHECKBOX
  // //   { field: 'serv_afedet', title: 'AFECTO DETRACCIÓN' },//CHECKBOX
  // //   { field: '73', title: 'TIPO DOCUMENTO' },//BOTON  
  // //   { field: 'scot_serietdo', title: 'SERIE DOCUMENTO' },
  // //   { field: 'scot_numerotdo', title: 'NRO. DOCUMENTO' },
  // //   { field: 'scot_fechaemision', title: 'FEC. EMISIÓN' },
  // // ]


  // metadatableServicio: newmetadate[] = [
  //   { title: 'EXONERADO', field: 'scot_exonerado', tipo: 'boolean', key: 'scot_item', tipoInput: 'checkbox', estadoInput: 'bloqueado' },
  //   { title: 'SERVICIO', field: 'serv_nombre', tipo: 'text' },
  //   { title: 'INGRESO/EGRESO', field: 'ccot_ingresogasto', tipo: 'text' },//?
  //   { title: 'ENTIDAD', field: null, tipo: 'text' },//boton
  //   { title: 'TIPO ENTIDAD', field: null, tipo: 'text' },//?
  //   { title: 'PROVEEDOR', field: 'entc_nomcom', tipo: 'text' },
  //   { title: 'BASE', field: 'cons_desc_spa', tipo: 'text', tipoInput: 'select', droptown: this.transformData(this.ListaBase)},
  //   { title: 'CANTIDAD', field: 'scot_cantidad', tipo: 'text' , tipoInput: 'int'},
  //   { title: 'MONEDA', field: 'tipo_descmnd', tipo: 'text', tipoInput: 'select', droptown: this.ListaMoneda },
  //   { title: 'PRECIO UNITARIO', field: 'scot_preciouni', tipo: 'text', tipoInput: 'number' },
  //   { title: 'IMPORTE EGRESO', field: 'scot_importe_egreso', tipo: 'text', tipoInput: 'number', estadoInput: 'bloqueado' },
  //   { title: 'AFECTO IGV', field: 'serv_afeigv', tipo: 'boolean', tipoInput: 'checkbox', estadoInput: 'bloqueado' },
  //   { title: 'AFECTO DETRACCIÓN', field: 'serv_afedet', tipo: 'boolean', tipoInput: 'checkbox', estadoInput: 'bloqueado' },
  //   { title: 'TIPO DOCUMENTO', field: null, tipo: 'text' },//boton
  //   { title: 'TIPO DOCUMENTO', field: null, tipo: 'text' },//Lo seleccionado
  //   { title: 'SERIE DOCUMENTO', field: 'scot_serietdo', tipo: 'text', tipoInput: 'Text' },
  //   { title: 'NRO. DOCUMENTO', field: 'scot_numerotdo', tipo: 'text' },
  //   { title: 'FEC. EMISIÓN', field: 'scot_fechaemision', tipo: 'text' },
  // ]



  //DATOS EMBARQUE



  //VIAJE ITINERARIO



  //NOVEDADES
  novedades_datanovedades: any[] = [{ a1: '1' }]
  novedades_metadatanovedades: MetadataTable[] = [
    { field: 'a1', title: 'FECHA' },
    { field: '2', title: 'EMAIL' },
    { field: '3', title: 'DESCRIPCIÓN' },
  ]

  //ARCHIVOS
  formatObject(obj) {
    return {
      label: obj.nombre,
      value: obj.codigo
    };
  }

  //EVENTOS TAREAS
  tranformarDa(dato: string): string {
    if (dato === "P") {
      console.log("Entro a P")
      return "C  ";
    } else if (dato === "C") {
      console.log("Entro a C")
      return "P  ";
    }
    return "";
  }

  handleDropdownChange(event: MatSelectChange) {
    const selectedValue = event.value; // Obtiene el valor seleccionado
    console.log("Opción seleccionada:", selectedValue);

    // Si quieres obtener el objeto completo seleccionado en lugar del valor,
    // tendrás que buscarlo en tu lista de parámetros
    const selectedItem = this.parametros.find(param => param.codigo === selectedValue);
    if (selectedItem) {
      console.log('Nombre:', selectedItem.nombre);
      console.log('Código:', selectedItem.codigo);
    } else {
      console.log('No se encontró el ítem seleccionado.');
    }
  }



  list_menu: any = [
    { icon: 'exit_to_app', text: 'Salir' },
    { icon: 'save', text: 'Guardar' }
  ]

  operaciones_menu(tipo: string) {
    console.log(tipo)
    switch (tipo) {
      case 'Salir':
        localStorage.removeItem('datoov');
        const rutaComponente = '/comercialordenventa';
        this.router.navigate([rutaComponente]);
        break;
      case 'Guardar':
        //Daniel26
        const request = <CrudOrdenesVentaRequest>{};
        const valuesDatosGenerales = this.group_datosgenerales.value   //30
        const valuesServicioTarifa = this.group_serviciostarifa.value
        const valuesDatosEmbarque = this.group_datosembarque.value
        const valuesObservaciones = this.group_observaciones.value
        //group_viaje.value
        const valuesNovedades = this.group_novedades.value
        const valuesArchivos = this.group_archivos.value
        const valuesEventosTareas = this.group_eventostareas.value
        const valuesServiciosLocales = this.group_servicioslocales.value


        // function formatFechaYYYYMMDD(fechaISO: string): string {
        //     // Crear un objeto Date a partir de la cadena ISO 8601
        //     const fecha = new Date(fechaISO);

        //     // Obtener año, mes y día de la fecha
        //     const year = fecha.getFullYear();
        //     const month = ('0' + (fecha.getMonth() + 1)).slice(-2); // Añadir 1 porque getMonth() devuelve el mes base 0
        //     const day = ('0' + fecha.getDate()).slice(-2);

        //     // Formatear la fecha en el formato "yyyy-mm-dd"
        //     const fechaFormateada = `${year}-${month}-${day}`;

        //     return fechaFormateada;
        // }
        // 1. pintCCOT_Numero
        request.psinCCOT_Tipo = 2//2. psinCCOT_Tipo
        request.psinEMPR_Codigo = 1//3. psinEMPR_Codigo
        request.psinSUCR_Codigo = 1// 4. psinSUCR_Codigo 1
        request.pvchCCOT_NumDoc = valuesDatosGenerales["numero"]// 5. pvchCCOT_NumDoc
        request.pintCCOT_NumPadre = null// 6. pintCCOT_NumPadre NULL
        request.psinCCOT_TipoPadre = null// 7. psinCCOT_TipoPadre NULL
        request.psinCCOT_Version = 1// 8. psinCCOT_Version 1
        request.pbitCCOT_Propia = valuesDatosGenerales["cotizacionpropia"]// 9. pbitCCOT_Propia
        request.pintENTC_CodCliente = Number(this.CodigosFiltrosAutocomplete[0].cliente)// 10. pintENTC_CodCliente
        request.pintENTC_CodTransportista = Number(this.CodigosFiltrosAutocomplete[0].transportista)// 11. pintENTC_CodTransportista
        request.pintENTC_CodBroker = null// 12. pintENTC_CodBroker NULL
        request.pintENTC_CodAgente = Number(this.CodigosFiltrosAutocomplete[0].agente)// 13. pintENTC_CodAgente

        request.pintENTC_CodCustomer = Number(this.CodigosFiltrosAutocomplete[0].customer)//14. pintENTC_CodCustomer, NO debe ser su codigo?
        request.pintENTC_CodEjecutivo = Number(this.CodigosFiltrosAutocomplete[0].ejecutivo)// 15. pintENTC_CodEjecutivo
        request.pintCCOT_NumPresupuesto = valuesDatosGenerales["numerocrm"]// 16. pintCCOT_NumPresupuesto
        //request.pdtmCCOT_FecEmi=valuesDatosGenerales["fechaemision"]// 17. pdtmCCOT_FecEmi
        request.pdtmCCOT_FecEmi = valuesDatosGenerales["fechaemision"]//.toISOString().slice(0, 19).replace('T', ' ');// 17. pdtmCCOT_FecEmi
        //request.pdtmCCOT_FecEmi=new Date(formatFechaYYYYMMDD(valuesDatosGenerales["fechaemision"]));  

        request.pdtmCCOT_FecVcto = valuesDatosGenerales["fechavencimiento"]//.toISOString().slice(0, 19).replace('T', ' ')// 18. pdtmCCOT_FecVcto
        request.pbitCCOT_ServicioLogistico = valuesDatosGenerales["serviciologistico"]// 19. pbitCCOT_ServicioLogistico
        request.pbitCCOT_VoBoGateIn = null// 20. pbitCCOT_VoBoGateIn NULL
        request.pbitCCOT_CargaRefrigerada = valuesDatosEmbarque["cargarefrigerada"]// 21. pbitCCOT_CargaRefrigerada
        request.pnumCCOT_Temperatura = valuesDatosEmbarque["temperatura"]// 22. pnumCCOT_Temperatura
        request.pbitCCOT_CargaPeligrosa = valuesDatosEmbarque["cargapeligrosa"]// 23. pbitCCOT_CargaPeligrosa
        request.pbitCCOT_Roundtrip = valuesDatosEmbarque["roundtrip"]// 24. pbitCCOT_Roundtrip
        request.pvchCCOT_Observaciones = valuesEventosTareas["observaciones"]// 25. pvchCCOT_Observaciones ?? de eventos y tareas?
        request.pdtmCCOT_FecCierre = null// 26. pdtmCCOT_FecCierre NULL
        request.psinCCOT_Almacenaje = valuesObservaciones["almacenaje"]// 27. psinCCOT_Almacenaje
        request.psinCCOT_SobreEstadia = valuesObservaciones["sobreestadia"]// 28. psinCCOT_SobreEstadia
        request.psinCCOT_TiempoViaje = valuesObservaciones["tiempoviaje"]// 29. psinCCOT_TiempoViaje
        request.psinCCOT_ValidezOferta = valuesObservaciones["validezoferta"]// 30. psinCCOT_ValidezOferta
        request.pchrTIPO_TabINC = "INC"// 31. pchrTIPO_TabINC
        request.pchrTIPO_CodINC = valuesDatosGenerales["incoterm"]// 32. pchrTIPO_CodINC
        request.pchrTIPO_TabImo = "IMO"// 33. pchrTIPO_TabImo
        request.pchrTIPO_CodImo = valuesDatosEmbarque["imoclass"]// 34. pchrTIPO_CodImo
        request.pvchCCOT_ImoUN = valuesDatosEmbarque["imoun"]// 35. pvchCCOT_ImoUN
        request.pintCONT_Codigo = valuesServicioTarifa["numerocontrato"]// 36. pintCONT_Codigo 
        request.pchrCONS_TabRGM = "RGM";//37. pchrCONS_TabRGM
        request.pchrCONS_CodRGM = valuesDatosGenerales["regimen"];//38. pchrCONS_CodRGM
        request.pchrCONS_TabVia = "VIA";//39. pchrCONS_TabVia
        request.pchrCONS_CodVia = valuesDatosGenerales["via"];//40. pchrCONS_CodVia       
        request.pchrCONS_TabFLE = "FLE";// 41. pchrCONS_TabFLE
        request.pchrCONS_CodFLE = valuesServicioTarifa["condicion"]; // 42. pchrCONS_CodFLE
        request.pchrCONS_TabEST = "OVE";// 43. pchrCONS_TabEST
        request.pchrCONS_CodEST = valuesDatosGenerales["estado"];// 44. pchrCONS_CodEST
        request.pintPUER_CodDestino = Number(this.CodigosFiltrosAutocomplete[0].puertoDestino) // 45. pintPUER_CodDestino
        request.pintPUER_CodTrasbordo = Number(this.CodigosFiltrosAutocomplete[0].puertoTransbordo)// 46. pintPUER_CodTrasbordo
        request.pintPUER_CodOrigen = Number(this.CodigosFiltrosAutocomplete[0].puertoOrigen)// 47. pintPUER_CodOrigen
        request.pvchCCOT_UsrAprueba = null// 48. pvchCCOT_UsrAprueba n
        request.pdtmCCOT_FecAprueba = valuesDatosGenerales["fechaaprobacion"]//.toISOString().slice(0, 19).replace('T', ' ')// 49. pdtmCCOT_FecAprueba
        request.pvchCCOT_UsrAceptaRechaza = null// 50. pvchCCOT_UsrAceptaRechaza NULL
        request.pdtmCCOT_FecAceptaRechaza = null// 51. pdtmCCOT_FecAceptaRechaza NULL
        request.pchrTIPO_TabMOT = "EVE"// 52. pchrTIPO_TabMOT tipo e y t
        request.pchrTIPO_CodMOT = valuesEventosTareas["tipoevento"]// 53. pchrTIPO_CodMOT
        request.pintENTC_CodShipper = Number(this.CodigosFiltrosAutocomplete[0].shipper)// 54. pintENTC_CodShipper
        request.pintENTC_CodConsignee = Number(this.CodigosFiltrosAutocomplete[0].consignee)// 55. pintENTC_CodConsignee
        request.pintENTC_CodNotify = Number(this.CodigosFiltrosAutocomplete[0].notify)// 56. pintENTC_CodNotify
        request.pchrCONS_TabTFT = "TFT"// 57. pchrCONS_TabTFT
        request.pchrCONS_CodTFT = valuesServicioTarifa["tipofechatarifa"]// 58. pchrCONS_CodTFT
        request.pdtmCCOT_FecTarifa = valuesServicioTarifa["fechatarifa"]//.toISOString().slice(0, 19).replace('T', ' ')// 59. pdtmCCOT_FecTarifa
        request.pchrCCOT_PagoMBL = valuesServicioTarifa["pcmbl"].trim()// 60. pchrCCOT_PagoMBL pcmbl

        request.pchrCCOT_PagoHBL = valuesServicioTarifa["pchbl"].trim()// 61. pchrCCOT_PagoHBL
        request.pchrTIPO_TabMND = "MND"// 62. pchrTIPO_TabMND
        request.pchrTIPO_CodMND = valuesServicioTarifa["moneda"]// 63. pchrTIPO_CodMND
        request.pvchDOOV_Cntr = valuesNovedades["contenedor"]// 64. pvchDOOV_Cntr contenedor
        request.pchrDOOV_HBL = valuesNovedades["blhijo"]// 65. pchrDOOV_HBL
        request.pchrDOOV_MBL = valuesNovedades["blmaster"]// 66. pchrDOOV_MBL
        request.pvchDDOV_NroBooking = valuesNovedades["numerobooking"]// 67. pvchDDOV_NroBooking
        request.pdtmDDOV_FecRecBooking = null// 68. pdtmDDOV_FecRecBooking NULL

        request.pdtmDDOV_FecEmbarque = valuesDatosGenerales["fechaembarque"]//.toISOString().slice(0, 19).replace('T', ' ')// 69. pdtmDDOV_FecEmbarque
        request.pintENTC_CodDepTemporal = Number(this.CodigosFiltrosAutocomplete[0].depositoTemp)// 70. pintENTC_CodDepTemporal
        request.pvchDOOV_CodReferencia = valuesDatosEmbarque["referencia"]// 71. pvchDOOV_CodReferencia
        request.pintNVIA_Codigo = Number(valuesNovedades["viaje"])// 72. pintNVIA_Codigo
        request.pvchDOOV_Feeder_IMPO = null// 73. pvchDOOV_Feeder_IMPO NULL
        request.pdtmDOOV_FecETDFeeder_IMPO = null// 74. pdtmDOOV_FecETDFeeder_IMPO NULL
        request.pbitDDOV_HBLNieto = valuesNovedades["blnieto"]// 75. pbitDDOV_HBLNieto
        request.pbitCCOT_ServicioTransmision = valuesDatosGenerales["servicio"]// 76. pbitCCOT_ServicioTransmision NULL
        request.pchrTIPO_TabTRF = "TRF"// 77. pchrTIPO_TabTRF llama el trafico
        request.pchrTIPO_CodTRF = valuesServicioTarifa["trafico"]// 78. pchrTIPO_CodTRF

        request.pbitCCOT_EnviaAvisoLlegada = valuesDatosGenerales["avisodellegada"]// 79. pbitCCOT_EnviaAvisoLlegada PONER EN LA WEB


        request.pchrCONS_TabLNG = "LNG"// 80. pchrCONS_TabLNG
        request.pchrCONS_CodLNG = valuesDatosGenerales["lineanegocio"]// 81. pchrCONS_CodLNG

        // request.pvchAUDI_UsrCrea=this.storage.get("usuario")// 82. pvchAUDI_UsrCrea ismael
        request.pvchCCOT_OrdenCompra = valuesDatosGenerales["ordencompra"]// 83. pvchCCOT_OrdenCompra






        this.ordenventaService.CrudOrdenesVenta(request).subscribe(response => {//Aqui se guarda, el msj de abajo se muestra cuando se halla guardado

          this.util.ShowMessage({ titulo: "ManteniminetoORdenVenta", mensaje: "Se guardó correctamente", icono: "OK", tipo: "ok" });
        });
        break;

      default:
        break;
    }
  }
}

