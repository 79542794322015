<div class="estilo-mensaje">
    <p class="titulo">{{data.titulo}}</p>
    <div class="cuerpo">
        <!-- <mat-icon class="icon-cuerpo"
            [ngClass]="{'correcto': data.icono == 'check', 'error': data.icono != 'check'}">{{data.icono}}</mat-icon> -->
        <img src="../../../../assets/Img/warning-mensaje.png" alt="" width="60px" *ngIf="data.tipo == 'warning'">
        <img src="../../../../assets/Img/check-mensaje.png" alt="" width="60px" *ngIf="data.tipo == 'ok'">
        <p class="items-cuerpo">{{ data.message }}</p>
    </div>
    <div class="button-container">
        <button class="button1" mat-raised-button mat-dialog-close>Aceptar</button>
    </div>
    <br>
    <div *ngIf="information" (click)="view()" class="view">Ver detalle</div>
    <br>
    <div class="information" *ngIf="viewDetail">
        <p-scrollPanel [style]="{ width: '100%', height: '100px' }" styleClass="custombar2">
            <p class="detalle">
                {{data.detalle}}
            </p>
        </p-scrollPanel>
    </div>
    <br>
</div>