import { Observable } from "rxjs";
import { ComisionesRequest, GuardarComisionesSLRequest, ImportarExcelRequest, ListadoExcel, ListadoReporteComisionesRequest, ListadoRequestBandejaFlete, ReservaSalaCorreo, requestcierremensual } from "./request/comisiones-request";
import { ComisionesResponse1, ComisionesResponse2, ImportarComisionesResponse, ListadoBandejaComisionesFleteResponse, ListadoComisiones1, ListadoComisiones2, ListadoComisionesSegurasResponse, ListadoReporteComisionesFleteResponse, ListadoReporteComisionesFleteServiciosLocalesResponse, ResponseEnvioCorreo, ResponseListadoComisionFleteExcelInsert, ResponseListadoComisionSLExcelInsert, registrarcierremensualresponse } from "./response/comisiones-response";
import { ComisionesServiciosRequest, GuardarComisionesServicioLocalesRequest } from "src/app/comisiones-servicios/domain/request/comisiones-servicios-request";
import { ComisionesDetalladoResponse, ComisionesResumenResponse, GuardarComisionesServicioLocalesResponse } from "src/app/comisiones-servicios/domain/response/comisiones-servicios-response";
import { ActualizarComisionesBrokerIRequest, DescargarArchivoRequest, DescargarArchivosRequest, GuardarComisionesBrokerRequest, ListarClientesBrokerRequest, ListarComisionesBrokerIRequest, ListarComisionesBrokerXmlRequest, ModificarClienteBrokerRequest } from "src/app/comision-broker/domain/request/Comisiones-Broker-Request";
import { ListarComisionesBrokerIResponse, ActualizarComisionesBrokerIResponse, ListarComisionesBrokerXmlResponse, GuardarComisionesBrokerResponse, ListadoComisionesBrokerResponse, GuardarArchivoResponse, DescargarArchivosResponse, ListarClientesBrokerResponse, ModificarClienteBrokerResponse } from "src/app/comision-broker/domain/response/Comisiones-Broker-response";
import { ComisionesSegurasRequest } from "src/app/dashboard-comisiones-seguros/domain/request/request";
import { ComisionesSegurasResponse } from "src/app/dashboard-comisiones-seguros/domain/response/response";
import { ConfirmarPagoComprobateRequest } from "src/app/comision-broker-hbl-pagadas/domain/request/pago-comprobante-request";
import { ConfirmarPagoComprobanteResponse } from "src/app/comision-broker-hbl-pagadas/domain/response/pago-comprobante-response";
import { ConfirmarPagoHblRequest } from "src/app/reporte-comision-broker-hbl/domain/request/confirmar-pago-hbl-request";
import { ConfirmarPagoHblResponse } from "src/app/reporte-comision-broker-hbl/domain/response/confirmar-pago-hbl-response";
import { ActualizarBrokerXmlRequest } from "src/app/reporte-comision-broker/presentacion/domain/request/reporte-broker-bandeja-request";
import { ActualizarBrokerXmlResponse } from "src/app/reporte-comision-broker/presentacion/domain/response/reporte-broker-bandeja-response";
import { ActualizarStatementAvisoRequest, ImportarStatementLineasRequest, ListarObservacionesStatementRequest, ListarStatementAvisoRequest, ListarStatementLineasRequest, ListarTiposRequest, ObservacionesStatementRequest } from "src/app/statement-carrier/domain/request/statement-carrier-request";
import { ActualizarStatementAvisoResponse, ImportarStatementLineasResponse, ListarObservacionesStatementResponse, ListarStatementAvisoResponse, ListarStatementLineasResponse, ListarTiposResponse, ObservacionesStatementResponse } from "src/app/statement-carrier/domain/response/statement-carrier-response";
import { ReporteGerenciaComercialChinaRequest } from "src/app/reporte-gc-china/domain/request/request-reporte-gchina";
import { ReporteGerenciaComercialChinaResponse } from "src/app/reporte-gc-china/domain/response/response-reporte-gcchina";

export abstract class ComisionesRepository {
    abstract listaComisiones1(fd: ComisionesRequest): Observable<ComisionesResponse1>;
    abstract listaComisiones2(fd: ComisionesRequest): Observable<ComisionesResponse2>;
    abstract listaComisionesServiciosDetallado(fd: ComisionesServiciosRequest): Observable<ComisionesDetalladoResponse>;
    abstract listaComisionesServiciosResumen(fd: ComisionesServiciosRequest): Observable<ComisionesResumenResponse>;
    abstract importacionflete(fd: ImportarExcelRequest): Observable<ImportarComisionesResponse>;
    abstract GuardarComisionesServiciosLocales(fd: GuardarComisionesServicioLocalesRequest): Observable<GuardarComisionesServicioLocalesResponse>;
    abstract ListarReporteComisionesFlete(fd: ListadoReporteComisionesRequest): Observable<ListadoReporteComisionesFleteResponse>;
    abstract ListarReporteComisionesServiciosLocales(fd: ListadoReporteComisionesRequest): Observable<ListadoReporteComisionesFleteServiciosLocalesResponse>;
    abstract ListarComisionesBrokerI(fd: ListarComisionesBrokerIRequest): Observable<ListarComisionesBrokerIResponse>;
    abstract ActualizarComisionesBrokerI(fd: ActualizarComisionesBrokerIRequest): Observable<ActualizarComisionesBrokerIResponse>;
    abstract ListarComisionesBrokerXml(fd: ListarComisionesBrokerXmlRequest): Observable<ListarComisionesBrokerXmlResponse>;
    abstract GuardarComisionesSeguras(fd: ComisionesSegurasRequest): Observable<ComisionesSegurasResponse>;
    abstract GuardarComisionesBroker(fd: GuardarComisionesBrokerRequest): Observable<GuardarComisionesBrokerResponse>;
    abstract ListadoComisionesBroker(fd: ListarComisionesBrokerIRequest): Observable<ListadoComisionesBrokerResponse>;
    abstract GuardarArchivos(fd: FormData): Observable<GuardarArchivoResponse>;
    abstract DescargarArchivos(fd: DescargarArchivosRequest): Observable<DescargarArchivosResponse>;
    abstract DescargarArchivo(fd: DescargarArchivoRequest): Observable<DescargarArchivosResponse>;
    abstract listaComisionesSeguras(fd: ListadoReporteComisionesRequest): Observable<ListadoComisionesSegurasResponse>;
    abstract ConfirmarComprobanteBroker(fd: ConfirmarPagoComprobateRequest): Observable<ConfirmarPagoComprobanteResponse>;
    abstract ConfirmarComprobanteHbl(fd: ConfirmarPagoHblRequest): Observable<ConfirmarPagoHblResponse>;
    abstract ActualizarBrokerXml(fd: ActualizarBrokerXmlRequest): Observable<ActualizarBrokerXmlResponse>;
    abstract ListarClientesBroker(fd: ListarClientesBrokerRequest): Observable<ListarClientesBrokerResponse>;
    abstract ModificarClienteBroker(fd: ModificarClienteBrokerRequest): Observable<ModificarClienteBrokerResponse>;
    abstract ImportarStatementLineas(fd: ImportarStatementLineasRequest): Observable<ImportarStatementLineasResponse>;
    abstract ListarStatementLineas(fd: ListarStatementLineasRequest): Observable<ListarStatementLineasResponse>;
    abstract ListarStatementAviso(fd: ListarStatementAvisoRequest): Observable<ListarStatementAvisoResponse>;
    abstract ActualizarStatementAviso(fd: ActualizarStatementAvisoRequest): Observable<ActualizarStatementAvisoResponse>;
    abstract ReporteGerenciaComercialChina(fd: ReporteGerenciaComercialChinaRequest): Observable<ReporteGerenciaComercialChinaResponse>;
    abstract ObservacionesStatement(fd: ObservacionesStatementRequest): Observable<ObservacionesStatementResponse>;
    abstract ListarObservacionesStatement(fd: ListarObservacionesStatementRequest): Observable<ListarObservacionesStatementResponse>;
    abstract ListarTipos(fd: ListarTiposRequest): Observable<ListarTiposResponse>;
    abstract ListarComisionesBandejaFlete(prolrequest: ListadoRequestBandejaFlete): Observable<ListadoBandejaComisionesFleteResponse> ;
    abstract confirmarpagohbl(prolrequest: ListadoRequestBandejaFlete): Observable<ListadoBandejaComisionesFleteResponse> ;
    abstract confimarcierremensual(prolrequest: requestcierremensual): Observable<registrarcierremensualresponse> ;
    abstract ListarComisionesFleteExcelInsert(prolrequest: ListadoRequestBandejaFlete): Observable<ResponseListadoComisionFleteExcelInsert> ;
    abstract ListarComisionesSLExcelInsert(prolrequest: ListadoRequestBandejaFlete): Observable<ResponseListadoComisionSLExcelInsert> ;
    abstract GuardarComisionesSL(fd: GuardarComisionesSLRequest): Observable<GuardarComisionesServicioLocalesResponse>;
    abstract EnviarCorreo(prolrequest: ReservaSalaCorreo): Observable<ResponseEnvioCorreo> ;
    

}