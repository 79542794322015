import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InsertServicioRefferRequest } from '../../../domain/reffer-request';
import { ComercialRepository } from 'src/app/repositorio/comercial.repository';
import { StorageService } from 'src/app/services/storage.service';
import { UtilService } from 'src/app/services/util.service';
import { Parametros } from 'src/app/services/domain/services-domain';

@Component({
  selector: 'app-nuevo-servicio-reffer',
  templateUrl: './nuevo-servicio-reffer.component.html',
  styleUrls: ['./nuevo-servicio-reffer.component.css']
})

export class NuevoServicioRefferComponent {

  constructor(
    private readonly reference: MatDialogRef<NuevoServicioRefferComponent>,
    private readonly comercial: ComercialRepository,
    private readonly storage: StorageService,
    private readonly util: UtilService,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) { }

  groupListaNavieras: any[] = []
  groupListaDestinos: any[] = []
  group: FormGroup
  inicializarFormulario() {
    this.group = new FormGroup({
      nombre: new FormControl(null, null),
      origen: new FormControl(null, null),
      destino: new FormControl(null, null),
      naviera: new FormControl(null, null),
      transito: new FormControl(null, null)
    })
  }

  ListaNaviera: any[] = [
    { codigo: 1052, nombre: 'CMA CGM PERU S.A.C.' },
    { codigo: 51, nombre: 'HAPAG-LLOYD ( PERU ) S.A.C.' },
    { codigo: 5636, nombre: 'WAN HAI LINES PERU S.A.C.' },
    { codigo: 1021, nombre: 'SEABOARD DEL PERU S.A.' },
    { codigo: 16, nombre: 'COSCO SHIPPING LINES (PERU) SA' },
    { codigo: 13104, nombre: 'EVERGREEN SHIPPING AGENCY (PERU) S.A.C.' },
    { codigo: 72, nombre: 'MEDITERRANEAN SHIPPING COMPANY DEL PERU SAC' },
    { codigo: 10244, nombre: 'OCEAN NETWORK EXPRESS (PERU) S.A.C.' },
    { codigo: 16341, nombre: 'Yang Ming Marine Transport Corporation' },
    { codigo: 16354, nombre: 'ZIM' }

  ]

  transaccion: string = ''
  dataEdit: any
  parametros: Parametros[]
  AllPorts: any[]
  async ngOnInit(): Promise<void> {
    this.inicializarFormulario()

    this.parametros = await this.util.getParametros()
    // console.log(this.parametros)
    if (this.parametros) {
      this.AllPorts = this.parametros.slice().filter(r => r.tipo === 'PUERTO' && r.via === '001')
    }


    console.log(this.AllPorts)
    this.dataEdit = this.data != undefined ? JSON.parse(this.data) : null

    if (this.dataEdit) {
      setTimeout(() => {
        this.group.get('naviera').setValue(this.ListaNaviera.find(option => option.codigo === this.dataEdit.codnaviera))
        this.group.get('origen').setValue(this.AllPorts.find(option => option.codigo === this.dataEdit.codorigen.toString()))
        this.group.get('destino').setValue(this.AllPorts.find(option => option.codigo === this.dataEdit.coddestino.toString()))
        this.group.get('nombre').setValue(this.dataEdit.nombre)
        this.group.get('transito').setValue(this.dataEdit.transito)
      }, 0);
      this.transaccion = "EDIT"
    } else {
      this.transaccion = "NUEVO"
    }
  }

  ListaPuertos: any[] = []
  agregar() {
    const values = this.group.value

    const item = {
      codigo_nave: values.naviera.codigo,
      nombre_nave: values.naviera.nombre,
      codigo_destino: values.destino.codigo,
      nombre_destino: values.destino.nombre,
      transito: ''
    }

    this.groupListaNavieras.push(item)
  }

  agregarNaviera() {
    const values = this.group.value

    const item = {
      codigo: values.naviera.codigo,
      nombre: values.naviera.nombre
    }

    this.groupListaNavieras.push(item)
    this.group.get('naviera').reset()
  }

  quitar(table: any, item: any) {
    table.splice(item, 1);
  }

  onTransitoChange(event: any, index: number) {
    this.groupListaNavieras[index].transito = event.target.value;
  }

  guardar() {

    if (this.transaccion == 'NUEVO') {
      const values = this.group.value
      const request = <InsertServicioRefferRequest>{}
      request.nombre = values.nombre
      request.origen = values.origen ? values.origen.codigo : 0
      const navieras = []
      this.groupListaNavieras.forEach(r => {
        const item = { naviera: r.codigo_nave, destino: r.codigo_destino, transito: r.transito }
        navieras.push(item)
      })
      request.navieras = navieras
      request.usuario = this.storage.get('usuario')
      request.tipo = 'I'
      request.servicio = 0
      request.servnave = 0
      this.comercial.InsertServicioReffer(request).subscribe(response => {
        if (response.datos.status = 200) {
          this.util.ShowMessage({ mensaje: 'Servicio Registrado.', tipo: 'ok' })
          this.salir()
        }
      }, (errorServicio) => {
        this.util.ShowMessage({ mensaje: 'Error Registro Servicio.', tipo: 'warning' })
      })
    } else {
      const values = this.group.value
      const request = <InsertServicioRefferRequest>{}
      request.nombre = values.nombre
      request.origen = values.origen ? values.origen.codigo : 0
      const puertos = []
      const navieras = []

      const item = { naviera: values.naviera.codigo, destino: values.destino.codigo, transito: values.transito }
      navieras.push(item)

      request.navieras = navieras
      request.usuario = this.storage.get('usuario')
      request.tipo = 'U'
      request.servicio = this.dataEdit.servicio
      request.servnave = this.dataEdit.codigo
      this.comercial.InsertServicioReffer(request).subscribe(response => {
        if (response.datos.status = 200) {
          this.util.ShowMessage({ mensaje: 'Servicio Actualizado.', tipo: 'ok' })
          this.salir()
        }
      }, (errorServicio) => {
        this.util.ShowMessage({ mensaje: 'Error Actualizar Servicio.', tipo: 'warning' })
      })
    }

  }

  salir() {
    this.reference.close()
  }
}
