<!-- <div class="barra_titulo">
    Bandeja Orden de Venta
</div> -->
<mat-menu #menu="matMenu" style="position: absolute; bottom: 10px; right: 10px; z-index: 999;">
    <button *ngFor="let item of list_menu" (click)="operaciones_menu(item.text)" class="items_menu">
        <div><mat-icon>{{item.icon}}</mat-icon></div>
        <div><span>{{item.text}}</span></div>
    </button>

</mat-menu>

<p-accordion [multiple]="true">
    <p-accordionTab header="Datos Generales">
        <form [formGroup]="group_datosgenerales">
            <div class="a-example-full-width-alterno-content-column-2">
                <div class="a-example-full-width-alterno-content-row">
                    <div class="a-example-full-width-alterno-content-2 a-example-full-width-alterno-content-row">
                        <div class="a-example-full-width-alterno-content-4">
                            <span class="p-float-label">
                                <input [ngClass]="{'readonly': readOnlyNumeroDGDG}" pInputText formControlName="numero"
                                    appendTo="body" /><!--id="username"-->
                                <label>Número</label>
                            </span>
                        </div>
                        <div class="a-example-full-width-alterno-content-4">
                            <span class="p-float-label">
                                <input [ngClass]="{'readonly': readOnlyNumeroCotizacionDG}" pInputText
                                    formControlName="numerocotizacion" appendTo="body" />
                                <label>Número Cotización</label>
                            </span>
                        </div>
                        <div class="a-example-full-width-alterno-content-4">
                            <span class="p-float-label ">
                                <p-dropdown [ngClass]="{'readonly': readOnlyEstadoDG}" [options]="ListaTodosEstado"
                                    placeholder="‎ " optionLabel="nombre" formControlName="estado" optionValue="codigo"
                                    appendTo="body" class="p-inputtext-sm">
                                </p-dropdown>
                                <label for="float-label">Estado</label> <!--*ngIf="!readOnlyEstadoDG"-->
                            </span>
                        </div>
                    </div>
                    <div class="a-example-full-width-alterno-content-2 a-example-full-width-alterno-content-row">
                        <div class="card a-example-full-width-alterno-content-4">
                            <span class="p-float-label">
                                <input [ngClass]="{'readonly': readOnlyNoCRMDG}" pInputText formControlName="numerocrm"
                                    appendTo="body" /><!--id="username"-->
                                <label>No. CRM</label>
                            </span>
                        </div>
                        <div class="a-example-full-width-alterno-content-4">
                            <span class="p-float-label">
                                <input [ngClass]="{'readonly': readonlyOrdenCompraDG}" pInputText
                                    formControlName="ordencompra" appendTo="body" /><!--id="username"-->
                                <label>Orden Compra</label>
                            </span>
                        </div>
                        <div class="a-example-full-width-alterno-content-4">
                            <span class="p-float-label ">
                                <p-dropdown [ngClass]="{'readonly': readonlyRegimenDG}" [options]="ListaTodosRegimen"
                                    placeholder="‎ " optionLabel="nombre" formControlName="regimen" optionValue="codigo"
                                    (onChange)="onRegimenChange($event)" appendTo="body" class="p-inputtext-sm">
                                </p-dropdown>
                                <label for="float-label">Regimen</label>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="a-example-full-width-alterno-content-row">
                    <div class="a-example-full-width-alterno-content-2 a-example-full-width-alterno-content-row">
                        <div class="a-example-full-width-alterno-content-4">
                            <span class="p-float-label">
                                <p-dropdown [ngClass]="{'readonly': readonlyViaDG}" [options]="ListaTodosVia"
                                    placeholder="‎ " optionLabel="nombre" formControlName="via" optionValue="codigo"
                                    appendTo="body" class="p-inputtext-sm">
                                </p-dropdown>
                                <label for="float-label">Via</label>
                            </span>
                        </div>
                        <div class="a-example-full-width-alterno-content-4">
                            <span class="p-float-label">
                                <p-dropdown [ngClass]="{'readonly': readonlyLineaNegocioDG}"
                                    [options]="ListaTodosLineaNegocio" placeholder="‎ " optionLabel="nombre"
                                    formControlName="lineanegocio" optionValue="codigo" appendTo="body"
                                    class="p-inputtext-sm">
                                </p-dropdown>
                                <label for="float-label">Linea negocio</label>
                            </span>
                        </div>
                        <div class="a-example-full-width-alterno-content-4">
                            <span class="p-float-label">
                                <p-calendar [ngClass]="{'readonly': readonlyFEmisionDG}" formControlName="fechaemision"
                                    appendTo="body" [showIcon]="true" [showWeek]="false" class="p-inputtext-sm"
                                    dateFormat="dd/mm/yy">
                                </p-calendar>
                                <label for="float-label">F. Emision</label>
                            </span>
                        </div>

                    </div>
                    <div class="a-example-full-width-alterno-content-2 a-example-full-width-alterno-content-row">
                        <div class="a-example-full-width-alterno-content-4">
                            <span class="p-float-label">
                                <p-calendar [ngClass]="{'readonly': readonlyFVencimientoDG}"
                                    formControlName="fechavencimiento" appendTo="body" [showIcon]="true"
                                    [showWeek]="false" class="p-inputtext-sm" dateFormat="dd/mm/yy">
                                </p-calendar>
                                <label for="float-label">F. Vencimiento</label>
                            </span>
                        </div>
                        <div class="a-example-full-width-alterno-content-4">
                            <span class="p-float-label">
                                <p-calendar [ngClass]="{'readonly': readonlyFAprobacionDG}"
                                    formControlName="fechaaprobacion" appendTo="body" [showIcon]="true"
                                    [showWeek]="false" dateFormat="dd/mm/yy">
                                </p-calendar>
                                <label for="float-label">F. Aprobacion</label>
                            </span>
                        </div>
                        <div class="a-example-full-width-alterno-content-4">
                            <span class="p-float-label ">
                                <p-calendar [ngClass]="{'readonly': readonlyFEmbarqueDG}"
                                    formControlName="fechaembarque" appendTo="body" [showIcon]="true" [showWeek]="false"
                                    dateFormat="dd/mm/yy">
                                </p-calendar>
                                <label for="float-label">{{fechaEm_z}}</label>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="a-example-full-width-alterno-content-row">
                    <div class="a-example-full-width-alterno-content-2">
                        <span class="p-float-label ">
                            <p-dropdown [ngClass]="{'readonly': readOnlyClienteDG}" [options]="ListaTodosCliente"
                                placeholder="‎ " optionLabel="nombre" formControlName="cliente" optionValue="nombre"
                                appendTo="body" [filter]="true" [showClear]="true" class="p-inputtext-sm">
                            </p-dropdown>
                            <label for="float-label">Cliente</label>
                        </span>
                    </div>
                    <div class="a-example-full-width-alterno-content-2">
                        <span class="p-float-label a-example-full-width-alterno-content-2">
                            <p-dropdown [ngClass]="{'readonly': readOnlyCustomerDG}" [options]="ListaTodosCustomer"
                                placeholder="‎ " optionLabel="nombre" formControlName="customer" optionValue="nombre"
                                appendTo="body" [filter]="true" [showClear]="true" class="p-inputtext-sm">
                            </p-dropdown>
                            <label for="float-label">Customer</label>
                        </span>
                    </div>
                </div>
                <div class="a-example-full-width-alterno-content-row">
                    <div class="a-example-full-width-alterno-content-2">
                        <span class="p-float-label a-example-full-width-alterno-content-2">
                            <p-dropdown [ngClass]="{'readonly': readOnlyAgenteDG}" [options]="ListaTodosAgentes"
                                placeholder="‎ " optionLabel="nombre" formControlName="agente" optionValue="nombre"
                                appendTo="body" [filter]="true" [showClear]="true" class="p-inputtext-sm">
                            </p-dropdown>
                            <label for="float-label">Agente</label>
                        </span>
                    </div>
                    <div class="a-example-full-width-alterno-content-2">
                        <span class="p-float-label a-example-full-width-alterno-content-2">
                            <p-dropdown [ngClass]="{'readonly': readOnlyEjecutivoDG}" [options]="ListaTodosEjecutivos"
                                placeholder="‎ " optionLabel="nombre" formControlName="ejecutivo" optionValue="nombre"
                                appendTo="body" [filter]="true" [showClear]="true" class="p-inputtext-sm">
                            </p-dropdown>
                            <label for="float-label">Ejecutivo</label>
                        </span>
                    </div>
                </div>
                <div class="a-example-full-width-alterno-content-row">
                    <div class="a-example-full-width-alterno-content-2 a-example-full-width-alterno-content-row">
                        <div class=" a-example-full-width-alterno-content-4">
                            <span class="p-float-label">
                                <p-dropdown [ngClass]="{'readonly': readonlyIncotermDG}" [options]="ListaTodosIncoterm"
                                    placeholder="‎ " optionLabel="nombre" formControlName="incoterm"
                                    optionValue="codigo" appendTo="body" class="p-inputtext-sm">
                                </p-dropdown>
                                <label for="float-label">Incoterm</label>
                            </span>
                        </div>
                    </div>
                    <div class="a-example-full-width-alterno-content-2 a-example-full-width-alterno-content-row">
                        <div class="a-example-full-width-alterno-content-2 a-example-full-width-alterno-content-row-2">
                            <div class="a-checkbox a-example-full-width-alterno-content-2">
                                <p-checkbox [ngClass]="{'readonly': readonlyServLogisticoDG}"
                                    formControlName="serviciologistico" [binary]="true" inputId="binary"
                                    appendTo="body">
                                </p-checkbox>
                                <label>Servicio Logistico</label>
                            </div>
                            <div class="a-checkbox a-example-full-width-alterno-content-2">
                                <p-checkbox [ngClass]="{'readonly': readonlyServicioDG}" formControlName="servicio"
                                    [binary]="true" inputId="binary" appendTo="body">
                                </p-checkbox>
                                <label>Servicio</label>
                            </div>
                        </div>
                        <div class="a-example-full-width-alterno-content-2 a-example-full-width-alterno-content-row-2">
                            <div class="a-checkbox a-example-full-width-alterno-content-2">
                                <p-checkbox [ngClass]="{'readonly': readonlyCotPropiaDG}"
                                    formControlName="cotizacionpropia" [binary]="true" inputId="binary" appendTo="body">
                                </p-checkbox>
                                <label>Cotización Propia</label>
                            </div>
                            <div class="a-checkbox a-example-full-width-alterno-content-2">
                                <p-checkbox [ngClass]="{'readonly': readonlyAvisoLl_zDG}"
                                    formControlName="avisodellegada" [binary]="true" inputId="binary" appendTo="body">
                                </p-checkbox>
                                <label>{{avisoLl_z}}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </p-accordionTab>

    <p-accordionTab header="Servicios Y Tarifa">

        <form [formGroup]="group_serviciostarifa" class="a-example-full-width-alterno-content-column-2">
            <div class="titulo_secciones">
                Datos de contrato
            </div>
            <div class="a-example-full-width-alterno-content-column-2">
                <div class="a-example-full-width-alterno-content-row">
                    <div class="a-example-full-width-alterno-content-2">
                        <span class="p-float-label">
                            <p-dropdown [ngClass]="{'readonly': readonlyTransportisaSTST}"
                                [options]="ListaTodosTransportista" placeholder="‎ " optionLabel="nombre"
                                formControlName="transportista" optionValue="nombre" appendTo="body" [filter]="true"
                                [showClear]="true" class="p-inputtext-sm">
                            </p-dropdown>
                            <label for="float-label">Transportista</label>
                        </span>
                    </div>



                    <div class="a-example-full-width-alterno-content-3">
                        <span class="p-float-label ">
                            <p-dropdown [ngClass]="{'readonly': readonlydTraficoST}" [options]="ListaTodosTrafico"
                                placeholder="‎ " optionLabel="nombre" formControlName="trafico" optionValue="codigo"
                                appendTo="body" class="p-inputtext-sm">
                            </p-dropdown>
                            <label for="float-label">Trafico</label>
                        </span>
                    </div>
                    <div class="a-example-full-width-alterno-content-3">
                        <span class="p-float-label">
                            <p-dropdown [ngClass]="{'readonly': readonlyPuertoOrigenST}"
                                [options]="ListaTodosPuertoOrigen" placeholder="‎ " optionLabel="nombre"
                                formControlName="puertoorigen" optionValue="nombre" appendTo="body" [filter]="true"
                                [showClear]="true" class="p-inputtext-sm">
                            </p-dropdown>
                            <label for="float-label">Puerto Origen</label>
                        </span>
                    </div>
                </div>
                <div class="a-example-full-width-alterno-content-row">
                    <div class="a-example-full-width-alterno-content-3">
                        <span class="p-float-label">
                            <p-dropdown [ngClass]="{'readonly': readonlyPuertoDestinoST}"
                                [options]="ListaTodosPuertoDestino" placeholder="‎ " optionLabel="nombre"
                                formControlName="puertodestino" optionValue="nombre" appendTo="body" [filter]="true"
                                [showClear]="true" class="p-inputtext-sm">
                            </p-dropdown>
                            <label for="float-label">PuertoDestino</label>
                        </span>
                    </div>

                    <div class="a-example-full-width-alterno-content-3">
                        <span class="p-float-label">
                            <p-dropdown [ngClass]="{'readonly': readonlyPuertoTransbordoST}"
                                [options]="ListaTodosPuertoTransbordo" placeholder="‎ " optionLabel="nombre"
                                formControlName="puertotransbordo" optionValue="nombre" appendTo="body" [filter]="true"
                                [showClear]="true" class="p-inputtext-sm">
                            </p-dropdown>
                            <label for="float-label">Puerto Transbordo</label>
                        </span>
                    </div>

                    <div class="a-example-full-width-alterno-content-3">
                        <span class="p-float-label">
                            <p-dropdown [ngClass]="{'readonly': readonlyTipoFechaTarifaST}"
                                [options]="ListaTodosTipoFechaTarifa" placeholder="‎ " optionLabel="nombre"
                                formControlName="tipofechatarifa" optionValue="codigo" appendTo="body"
                                class="p-inputtext-sm">
                            </p-dropdown>
                            <label for="float-label">Tipo Fecha Tarifa</label>
                        </span>
                    </div>

                    <div class="a-example-full-width-alterno-content-3">
                        <span class="p-float-label">
                            <p-calendar [ngClass]="{'readonly': readonlyFechaTarifaST}" formControlName="fechatarifa"
                                appendTo="body" [showIcon]="true" [showWeek]="false" class="p-inputtext-sm"
                                dateFormat="dd/mm/yy">
                            </p-calendar>
                            <label for="float-label">F. Tarifa</label>
                        </span>

                    </div>
                </div>
                <div class="a-example-full-width-alterno-content-row">
                    <div class="a-example-full-width-alterno-content-3">
                        <span class="p-float-label">
                            <p-dropdown [ngClass]="{'readonly': readonlyMonedaST}" [options]="ListaTodosMoneda"
                                placeholder="‎ " optionLabel="nombre" formControlName="moneda" optionValue="codigo"
                                appendTo="body" class="p-inputtext-sm">
                            </p-dropdown>
                            <label for="float-label">Moneda</label>
                        </span>
                    </div>

                    <div class="a-example-full-width-alterno-content-3">
                        <span class="p-float-label">
                            <p-dropdown [ngClass]="{'readonly': readonlyCondicionST}" [options]="ListaTodosCondicion"
                                placeholder="‎ " optionLabel="nombre" formControlName="condicion" optionValue="codigo"
                                appendTo="body" class="p-inputtext-sm">
                            </p-dropdown>
                            <label for="float-label">Condición</label>
                        </span>
                    </div>

                    <div class="card flex justify-content-center a-example-full-width-alterno-content-3">
                        <span class="p-float-label">
                            <input [ngClass]="{'readonly': readonlyNumeroContratoST}" pInputText
                                formControlName="numerocontrato" appendTo="body" /><!--id="username"-->
                            <label>Número Contrato</label> <!--[(ngModel)]="value" htmlFor="username"-->
                        </span>
                    </div>

                    <div class="card flex justify-content-center a-example-full-width-alterno-content-3">
                        <span class="p-float-label">
                            <input [ngClass]="{'readonly': readonlyAdicionalST}" pInputText formControlName="adicional"
                                appendTo="body" /><!--id="username"-->
                            <label>adicional</label> <!--[(ngModel)]="value" htmlFor="username"-->
                        </span>
                    </div>

                </div>
            </div>


            <div class="titulo_secciones">
                Flete
            </div>
            <div class="a-example-full-width-alterno-content-row">
                <div class="a-example-full-width-alterno-content-0_5">
                    <!--class="a-example-full-width-alterno-content-2" -->
                    <app-datatable [dataTable]="serviciosytarifas_dataflete" [metadataTable]="metadatableFlete"
                        [rowEdit]="true" [addRow]="true" [style]="estilos" [key]="'dcot_item'">
                    </app-datatable>

                    <!-- <app-db-table [dataTable]="serviciosytarifas_dataflete" [metadataTable]="metadatableFlete" [key]="'dcot_item'"></app-db-table> -->
                </div>
                <div class="a-example-full-width-alterno-content-4">
                    <div class="a-example-full-width-alterno-content-column-2">
                        <div class="a-example-full-width-alterno-content-row">
                            <div class="a-example-full-width-alterno-content-2">
                                <span class="p-float-label">
                                    <p-dropdown [ngClass]="{'readonly': readonlyPCMblST}" [options]="ListaTodosPCMBL"
                                        placeholder="‎ " optionLabel="nombre" formControlName="pcmbl"
                                        optionValue="codigo" appendTo="body" class="p-inputtext-sm">
                                    </p-dropdown>
                                    <label for="float-label">P/C (MBL)</label>
                                </span>
                            </div>

                            <div class="a-example-full-width-alterno-content-2">
                                <span class="p-float-label">
                                    <p-dropdown [ngClass]="{'readonly': readonlyPCHblST}" [options]="ListaTodosPCHBL"
                                        placeholder="‎ " optionLabel="nombre" formControlName="pcmbl"
                                        optionValue="codigo" appendTo="body" class="p-inputtext-sm">
                                    </p-dropdown>
                                    <label for="float-label">P/C (HBL)</label>
                                </span>
                            </div>
                        </div>
                        <div class="a-example-full-width-alterno-content-row">
                            <div class="a-example-full-width-alterno-content-2">
                                <span class="p-float-label">
                                    <p-inputNumber [ngClass]="{'readonly': readOnlyFleteTotalST}"
                                        formControlName="fletetotal" mode="decimal" [showButtons]="true"
                                        [useGrouping]="false" [min]="0" [inputStyle]="{'width':'100%'}">
                                    </p-inputNumber>
                                    <label for="float-label">Flete Total</label>
                                </span>
                            </div>

                            <div class="a-example-full-width-alterno-content-2">
                                <span class="p-float-label">
                                    <p-inputNumber [ngClass]="{'readonly': readonlyDiasLibresSobreeestadiaST}"
                                        formControlName="diaslibres" mode="decimal" [showButtons]="true"
                                        [useGrouping]="false" [min]="0" [inputStyle]="{'width':'100%'}">
                                    </p-inputNumber>
                                    <label for="float-label">Dias Libres Sobreestadia</label>
                                </span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <!-- <div class="titulo_secciones">
                Servicios
            </div> -->
            <div class="a-example-full-width-alterno-content">
                <app-datatable [dataTable]="serviciosytarifas_dataservicios" [metadataTable]="metadatableServicio"
                    [addRow]="true" [rowEdit]="true" [style]="estilos" [title]="'Servicios'" [key]="'scot_item'">
                </app-datatable>
            </div>

            <!-- <div class="titulo_secciones">
                Seguro
            </div> -->
            <div class="a-example-full-width-alterno-content">
                <app-datatable [dataTable]="serviciosytarifas_dataseguro" [metadataTable]="metadatableSeguro"
                    [filter]="true" [addRow]="true" [rowEdit]="true" [title]="'Seguro'">
                </app-datatable>
            </div>
        </form>

    </p-accordionTab>

    <p-accordionTab header="Datos Embarque">
        <form [formGroup]="group_datosembarque">
            <div class="titulo_secciones">
                Datos de contrato / Datos adicionales
            </div>

            <div class="a-example-full-width-alterno-content-column">
                <!-- <div class="formularios_aling_sub_general"> -->
                <!-- <fieldset class="example-full-width-alterno3"> -->
                <p-fieldset legend="Datos de contrato" class="a-example-full-width-alterno-content">
                    <div class="a-example-full-width-alterno-content-column">
                        <div class="a-example-full-width-alterno-content-row">
                            <div class="a-checkbox a-example-full-width-alterno-content-3">
                                <p-checkbox [ngClass]="{'readonly': readonlyCargaRefrigeradaDE}"
                                    formControlName="cargarefrigerada" [binary]="true" inputId="binary" appendTo="body">
                                </p-checkbox>
                                <label>Carga Refrigerada</label>
                            </div>

                            <div class="a-example-full-width-alterno-content-3">
                                <span class="p-float-label">
                                    <p-inputNumber [ngClass]="{'readonly': readOnlyTemperaturaDE}"
                                        formControlName="temperatura" mode="decimal" [showButtons]="true"
                                        [useGrouping]="false" [inputStyle]="{'width':'100%'}">
                                    </p-inputNumber>
                                    <label for="float-label">Temperatura (C°)</label>
                                </span>
                            </div>

                            <div class="a-checkbox a-example-full-width-alterno-content-3">
                                <p-checkbox [ngClass]="{'readonly': readonlyCargaPeligrosaDE}"
                                    formControlName="cargapeligrosa" [binary]="true" inputId="binary" appendTo="body"
                                    (change)="onCargaPeligrosaChange($event)">
                                </p-checkbox>
                                <label>Carga Peligrosa</label>
                            </div>

                            <div class="a-example-full-width-alterno-content-3">
                                <span class="p-float-label  ">
                                    <p-dropdown [ngClass]="{'readonly': readonlyImoClassDE}"
                                        [options]="ListaTodosImoClass" placeholder="‎ " optionLabel="nombre"
                                        formControlName="imoclass" optionValue="codigo" appendTo="body"
                                        class="p-inputtext-sm">
                                    </p-dropdown>
                                    <label for="float-label">IMO Class</label>
                                </span>
                            </div>

                            <div class="a-checkbox a-example-full-width-alterno-content-3">
                                <p-checkbox [ngClass]="{'readonly': readonlyRoundTripDE}" formControlName="roundtrip"
                                    [binary]="true" inputId="binary" appendTo="body">
                                </p-checkbox>
                                <label>RoundTrip</label>
                            </div>

                            <div class="card flex justify-content-center a-example-full-width-alterno-content-3">
                                <span class="p-float-label">
                                    <input [ngClass]="{'readonly': readOnlyImoUnDE}" pInputText formControlName="imoun"
                                        appendTo="body" />
                                    <label>IMO UN</label>
                                </span>
                            </div>
                        </div>




                    </div>
                </p-fieldset>
                <!-- <fieldset class="example-full-width-alterno3"> -->
                <p-fieldset legend="Datos adicionales" class="a-example-full-width-alterno-content ">
                    <div class="a-example-full-width-alterno-content-column a-example-full-width-alterno-content">
                        <!-- <div class="formularios_aling_general"> -->
                        <div class="a-example-full-width-alterno-content-row">
                            <div class="a-example-full-width-alterno-content">
                                <span class="p-float-label">
                                    <p-dropdown [ngClass]="{'readonly': readonlyShipperDE}"
                                        [options]="ListaTodosShipper" placeholder="‎ " optionLabel="nombre"
                                        formControlName="shipper" optionValue="nombre" appendTo="body" [filter]="true"
                                        [showClear]="true" class="p-inputtext-sm">
                                    </p-dropdown>
                                    <label for="float-label">Shipper</label>
                                </span>
                            </div>
                        </div>
                        <div class="a-example-full-width-alterno-content-row">
                            <div class="a-example-full-width-alterno-content">
                                <span class="p-float-label">
                                    <p-dropdown [ngClass]="{'readonly': readonlyConsigneeDE}"
                                        [options]="ListaTodosConsignee" placeholder="‎ " optionLabel="nombre"
                                        formControlName="consignee" optionValue="nombre" appendTo="body" [filter]="true"
                                        [showClear]="true" class="p-inputtext-sm">
                                    </p-dropdown>
                                    <label for="float-label">Consignee</label>
                                </span>
                            </div>


                        </div>
                        <div class="a-example-full-width-alterno-content-row">
                            <div class="a-example-full-width-alterno-content">
                                <span class="p-float-label ">
                                    <p-dropdown [ngClass]="{'readonly': readonlyNotifyDE}" [options]="ListaTodosNotify"
                                        placeholder="‎ " optionLabel="nombre" formControlName="notify"
                                        optionValue="nombre" appendTo="body" [filter]="true" [showClear]="true"
                                        class="p-inputtext-sm">
                                    </p-dropdown>
                                    <label for="float-label">Notify</label>
                                </span>
                            </div>



                        </div>
                        <div class="a-example-full-width-alterno-content-row">
                            <div class="card flex justify-content-center a-example-full-width-alterno-content">
                                <span class="p-float-label">
                                    <input [ngClass]="{'readonly': readonlyReferenciaDE}" pInputText
                                        formControlName="referencia" appendTo="body" />
                                    <label>Referencia</label>
                                </span>
                            </div>
                        </div>
                        <!-- </div> -->
                    </div>

                    <!-- </fieldset> -->
                </p-fieldset>
                <!-- </div> -->
            </div>
            <div class="titulo_secciones">
                Flete
            </div>
            <div class="a-example-full-width-alterno-content">
                <app-datatable [dataTable]="datosembarque_dataflete" [metadataTable]="metadatableFleteEmbarque"
                    [filter]="true" [addRow]="true" [rowEdit]="true" [key]="'scot_exonerado'">
                </app-datatable>
            </div>
        </form>
    </p-accordionTab>
    <p-accordionTab header="Observaciones">


        <form [formGroup]="group_observaciones">
            <div class="titulo_secciones">
                Datos de observaciones
            </div>
            <div class="a-example-full-width-alterno-content-column">
                <div class="a-example-full-width-alterno-content">
                    <div class="a-example-full-width-alterno-content-row">

                        <div class="a-example-full-width-alterno-content-2">
                            <span class="p-float-label">
                                <p-inputNumber [ngClass]="{'readonly': readonlyAlmacenajeObs}"
                                    formControlName="almacenaje" mode="decimal" [showButtons]="true"
                                    [useGrouping]="false" [inputStyle]="{'width':'100%'}">
                                </p-inputNumber>
                                <label for="float-label">Almacenaje (días)</label>
                            </span>
                        </div>

                        <div class="a-example-full-width-alterno-content-2">
                            <span class="p-float-label">
                                <p-inputNumber [ngClass]="{'readonly': readonlyTiempoViajeObs}"
                                    formControlName="tiempoviaje" mode="decimal" [showButtons]="true"
                                    [useGrouping]="false" [inputStyle]="{'width':'100%'}">
                                </p-inputNumber>
                                <label for="float-label">Tiempo viaje (dias)</label>
                            </span>
                        </div>
                        <div class="a-example-full-width-alterno-content-2">
                            <span class="p-float-label ">
                                <p-inputNumber [ngClass]="{'readonly': readonlySobreestadiaObs}"
                                    formControlName="sobreestadia" mode="decimal" [showButtons]="true"
                                    [useGrouping]="false" [inputStyle]="{'width':'100%'}">
                                </p-inputNumber>
                                <label for="float-label">Sobreestadia (días)</label>
                            </span>
                        </div>

                        <div class="a-example-full-width-alterno-content-2">
                            <span class="p-float-label ">
                                <p-inputNumber [ngClass]="{'readonly': readonlyValidezOfertaObs}"
                                    formControlName="validezoferta" mode="decimal" [showButtons]="true"
                                    [useGrouping]="false" [inputStyle]="{'width':'100%'}">
                                </p-inputNumber>
                                <label for="float-label">Validez oferta (dias)</label>
                            </span>
                        </div>
                    </div>


                </div>
                <div class="a-example-full-width-alterno-content">
                    <div class="a-example-full-width-alterno-content-row">
                        <span class="p-float-label a-example-full-width-alterno-content-row">
                            <textarea [ngClass]="{'readonly': readonlyInstruccionesEmbarqueObs}" id="float-input"
                                rows="5" cols="30" pInputTextarea formControlName="instrucciones"
                                style="width: 100%;"></textarea>
                            <label for="float-label">Instrucciones de embarque</label>
                        </span>
                    </div>
                </div>
            </div>


        </form>
    </p-accordionTab>
    <p-accordionTab header="Viaje (Itinerario)">

        <div class="titulo_secciones">
            Itinerarios
        </div>
        <div class="a-example-full-width-alterno-content">
            <app-datatable [dataTable]="viajeitinerario_dataitinerario" [metadataTable]="metadatableItinerario"
                [filter]="true" [addRow]="true" [rowEdit]="true" [key]="'ovnv_item'">
            </app-datatable>
        </div>

        <div class="titulo_secciones">
            Detalle / Nave viaje
        </div>
        <div class="a-example-full-width-alterno-content">
            <app-datatable [dataTable]="viajeitinerario_datanave" [metadataTable]="metadatableDataNave" [filter]="true"
                [addRow]="true" [rowEdit]="true" [key]="'nvia_codigo'">
            </app-datatable>
        </div>
    </p-accordionTab>

    <p-accordionTab header="Novedades">

        <form [formGroup]="group_novedades">
            <div class="titulo_secciones">
                Novedades
            </div>
            <div class="a-example-full-width-alterno-content-column">
                <!-- <div class="formularios_aling_general">-------------------- -->
                <div class="a-example-full-width-alterno-content-row">
                    <!-- <p-fieldset legend="Datos booking" class="a-example-full-width-alterno-content-3">


                        <div class="formularios_aling_general">
                            <div class="card flex justify-content-center a-example-full-width-alterno-content">
                                <span class="p-float-label">
                                    <input [ngClass]="{'readonly': readonlyNroBookingNov}" pInputText
                                        formControlName="numerobooking" appendTo="body" />
                                    <label>N° booking</label>
                                </span>
                            </div>

                        </div>

                    </p-fieldset> -->

                    <fieldset class="a-fielset a-example-full-width-alterno-content">

                        <div class="a-example-full-width-alterno-content-row">
                            <div class="a-example-full-width-alterno-content-3">
                                <span class="p-float-label">
                                    <input [ngClass]="{'readonly': readonlyNroBookingNov}" pInputText
                                        formControlName="numerobooking" appendTo="body" />
                                    <label>N° booking</label>
                                </span>
                            </div>

                            <div class="a-example-full-width-alterno-content-3">
                                <span class="p-float-label">
                                    <input [ngClass]="{'readonly': readOnlyViajeNov}" pInputText formControlName="viaje"
                                        appendTo="body" />
                                    <label>Viaje</label>
                                </span>
                            </div>

                            <div class="a-example-full-width-alterno-content-3">
                                <p-calendar [ngClass]="{'readonly': readonlyFZarpeNov}" formControlName="zarpe"
                                    appendTo="body" [showIcon]="true" [showWeek]="false" dateFormat="dd/mm/yy">
                                </p-calendar>
                                <label for="float-label">F. Zarpe</label>
                            </div>


                            <div class="p-float-label a-example-full-width-alterno-content-3">
                                <p-calendar [ngClass]="{'readonly': readonlyFEtaEtdNov}" formControlName="etaetd"
                                    appendTo="body" [showIcon]="true" [showWeek]="false" dateFormat="dd/mm/yy">
                                </p-calendar>
                                <label for="float-label">F. ETA</label>
                            </div>

                            <div class="card flex justify-content-center a-example-full-width-alterno-content-3">
                                <span class="p-float-label">
                                    <input [ngClass]="{'readonly': readOnlyNaveNov}" pInputText formControlName="nave"
                                        appendTo="body" />
                                    <label>Nave</label>
                                </span>
                            </div>

                        </div>
                        <legend class="a-label-fielset">Datos Viaje</legend>
                    </fieldset>

                    <!-- <p-fieldset legend="Datos viaje" class="a-example-full-width-alterno-content-0_2">

                        <div class="a-example-full-width-alterno-content-row">

                            <div class="card flex justify-content-center a-example-full-width-alterno-content-4">
                                <span class="p-float-label">
                                    <input [ngClass]="{'readonly': readOnlyViajeNov}" pInputText formControlName="viaje"
                                        appendTo="body" />
                                    <label>Viaje</label>
                                </span>
                            </div>

                            <div class="p-float-label a-example-full-width-alterno-content-4">
                                <p-calendar [ngClass]="{'readonly': readonlyFZarpeNov}" formControlName="zarpe"
                                    appendTo="body" [showIcon]="true" [showWeek]="false" dateFormat="dd/mm/yy">
                                </p-calendar>
                                <label for="float-label">F. Zarpe</label>
                            </div>


                            <div class="p-float-label a-example-full-width-alterno-content-4">
                                <p-calendar [ngClass]="{'readonly': readonlyFEtaEtdNov}" formControlName="etaetd"
                                    appendTo="body" [showIcon]="true" [showWeek]="false" dateFormat="dd/mm/yy">
                                </p-calendar>
                                <label for="float-label">F. ETA</label>
                            </div>

                            <div class="card flex justify-content-center a-example-full-width-alterno-content-4">
                                <span class="p-float-label">
                                    <input [ngClass]="{'readonly': readOnlyNaveNov}" pInputText formControlName="nave"
                                        appendTo="body" />
                                    <label>Nave</label>
                                </span>
                            </div>

                        </div>

                    </p-fieldset> -->
                </div>
                <div class="a-example-full-width-alterno-content-row">
                    <p-fieldset legend="Datos documento" class="a-example-full-width-alterno-content">

                        <div class="a-example-full-width-alterno-content-row">

                            <div class="a-example-full-width-alterno-content-3">
                                <span class="p-float-label">
                                    <p-dropdown [ngClass]="{'readonly': readonlyDepositoNov}"
                                        [options]="ListaTodosDeposito" placeholder="‎ " optionLabel="nombre"
                                        formControlName="deposito" optionValue="nombre" appendTo="body" [filter]="true"
                                        [showClear]="true" class="p-inputtext-sm">
                                    </p-dropdown>
                                    <label for="float-label">Depósito</label>
                                </span>
                            </div>

                            <div class="a-checkbox a-example-full-width-alterno-content-3">
                                <p-checkbox [ngClass]="{'readonly': readonlyBlNietoNov}" formControlName="blnieto"
                                    [binary]="true" inputId="binary" appendTo="body">
                                </p-checkbox>
                                <label>BL Nieto</label>
                            </div>

                            <div class="card flex justify-content-center a-example-full-width-alterno-content-3">
                                <span class="p-float-label">
                                    <input [ngClass]="{'readonly': readonlyBlHijoNov}" pInputText
                                        formControlName="blhijo" appendTo="body" />
                                    <label>BL Hijo</label>
                                </span>
                            </div>

                            <div class="card flex justify-content-center a-example-full-width-alterno-content-3">
                                <span class="p-float-label">
                                    <input [ngClass]="{'readonly': readonlyBlMasterNov}" pInputText
                                        formControlName="blmaster" appendTo="body" />
                                    <label>BL Master</label>
                                </span>
                            </div>
                            <div class="card flex justify-content-center a-example-full-width-alterno-content-3">
                                <span class="p-float-label">
                                    <input [ngClass]="{'readonly': readonlyContenedorNov}" pInputText
                                        formControlName="contenedor" appendTo="body" />
                                    <label>Contenedor</label>
                                </span>
                            </div>

                        </div>


                    </p-fieldset>

                </div>
            </div>

        </form>

    </p-accordionTab>
    <p-accordionTab header="Archivos">

        <form [formGroup]="group_archivos">
            <div class="titulo_secciones">
                Datos archivo
            </div>
            <div class="a-example-full-width-alterno-content">
                <p-fileUpload name="demo[]" url="https://www.primefaces.org/cdn/api/upload.php"
                    (onUpload)="onUpload($event)" [multiple]="false" accept="image/*"><!--maxFileSize="1000000"-->
                    <ng-template pTemplate="content">
                        <ul *ngIf="uploadedFiles.length">
                            <li *ngFor="let file of uploadedFiles">{{ file.name }} - {{ file.size }} bytes</li>
                        </ul>
                    </ng-template>
                </p-fileUpload>
            </div>
            <div class="titulo_secciones">
                Listado archivos
            </div>
            <div class="a-example-full-width-alterno-content">
                <div class="a-example-full-width-alterno-content">
                    <app-datatable [dataTable]="archivos_dataarchivos" [metadataTable]="metadatableDataArchivos"
                        [filter]="true" [addRow]="true" [rowEdit]="true">
                    </app-datatable>
                </div>
            </div>
        </form>
    </p-accordionTab>

    <p-accordionTab header="Eventos / Tarea">

        <form [formGroup]="group_eventostareas">
            <div class="titulo_secciones">
                Datos evento / tarea
            </div>
            <div class="a-example-full-width-alterno-content">
                <div class="a-example-full-width-alterno-content-column">
                    <div class="a-example-full-width-alterno-content-row">

                        <div class="a-example-full-width-alterno-content-4">
                            <span class="p-float-label">
                                <p-dropdown [ngClass]="{'readonly': readonlyTipoEventoTareaET}"
                                    [options]="ListaTodosEventoTarea" placeholder="‎ " optionLabel="nombre"
                                    formControlName="tipoevento" optionValue="nombre" appendTo="body" [filter]="true"
                                    [showClear]="true" class="p-inputtext-sm">
                                </p-dropdown>
                                <label for="float-label">Tipo evento/tarea</label>
                            </span>
                        </div>
                        <div class="p-float-label a-example-full-width-alterno-content-4">
                            <p-calendar [ngClass]="{'readonly': readonlyFechaYHoraET}" formControlName="fechahora"
                                appendTo="body" [showIcon]="true" [showWeek]="false" [showTime]="true"
                                dateFormat="dd/mm/yy">
                            </p-calendar>
                            <label for="float-label">Fecha y hora</label>
                        </div>

                        <div class="radiobutton flex a-example-full-width-alterno-content-4"
                            [ngClass]="{'readonly': readonlyEstadoET}">
                            <div class="radiobutton">
                                <p-radioButton inputId="category1" [value]="'1'"
                                    formControlName="estado"></p-radioButton>
                                <label for="category1" class="ml-2">Pendiente</label>
                            </div>
                            <div class="radiobutton">
                                <p-radioButton inputId="category2" [value]="'2'"
                                    formControlName="estado"></p-radioButton>
                                <label for="category2" class="ml-2">Cumplida</label>
                            </div>

                        </div>
                        <div class="a-example-full-width-alterno-content-4">
                            <span class="p-float-label">
                                <p-dropdown [ngClass]="{'readonly': readonlyModuloET}" [options]="ListTodosModulo"
                                    placeholder="‎ " optionLabel="nombre" formControlName="modulo" optionValue="codigo"
                                    appendTo="body" class="p-inputtext-sm">
                                </p-dropdown>
                                <label for="float-label">Modulo</label>
                            </span>
                        </div>



                        <div class="a-example-full-width-alterno-content-4">
                            <span class="p-float-label">
                                <input [ngClass]="{'readonly': readonlyUsuarioET}" pInputText formControlName="usuario"
                                    appendTo="body" />
                                <label>Usuario</label>
                            </span>
                        </div>



                    </div>


                    <div class="a-example-full-width-alterno-content-row">
                        <span class="p-float-label a-example-full-width-alterno-content">
                            <textarea [ngClass]="{'readonly': readonlyObservacionesET}" id="float-input" rows="5"
                                cols="30" pInputTextarea formControlName="observaciones"
                                style="width: 100%;"></textarea>
                            <label for="float-label">Observaciones</label>
                        </span>
                    </div>

                </div>
            </div>


            <div class="titulo_secciones">
                Eventos Tareas
            </div>

            <div class="a-example-full-width-alterno-content">
                <app-datatable [dataTable]="eventostareas_dataeventos" [metadataTable]="metadatableDataEventos"
                    [filter]="true" [addRow]="true" [rowDialog]="true" [key]="'even_item'">
                </app-datatable>
            </div>
        </form>
    </p-accordionTab>
    <p-accordionTab header="Servicios Locales">

        <form [formGroup]="group_servicioslocales">
            <div class="titulo_secciones">
                Datos servicios locales
            </div>
            <div class="a-example-full-width-alterno-content-column">
                <div class="a-example-full-width-alterno-content-row">
                    <div class="card flex justify-content-center a-example-full-width-alterno-content-3">
                        <span class="p-float-label">
                            <input [ngClass]="{'readonly': readOnlyCodigoOperacionDSL}" pInputText
                                formControlName="codigooperacion" appendTo="body" />
                            <label>Código operación</label>
                        </span>
                    </div>
                    <div class="card flex justify-content-center a-example-full-width-alterno-content-3">
                        <span class="p-float-label ">
                            <input [ngClass]="{'readonly': readOnlyNumeroOpeacionDSL}" pInputText
                                formControlName="numerooperacion" appendTo="body" />
                            <label>Numero operación</label>
                        </span>
                    </div>
                    <div class="p-float-label a-example-full-width-alterno-content-3">
                        <p-calendar [ngClass]="{'readonly': readonlyFechaEmisionDSL}" formControlName="fechaemision"
                            appendTo="body" [showIcon]="true" [showWeek]="false" dateFormat="dd/mm/yy">
                        </p-calendar>
                        <label for="float-label">Fecha emisión</label>
                    </div>
                    <div class="card flex justify-content-center a-example-full-width-alterno-content-3">
                        <span class="p-float-label">
                            <input [ngClass]="{'readonly': readOnlyClienteDSL}" pInputText formControlName="cliente"
                                appendTo="body" />
                            <label>Cliente</label>
                        </span>
                    </div>
                    <div class="card flex justify-content-center a-example-full-width-alterno-content-3">
                        <span class="p-float-label">
                            <input [ngClass]="{'readonly': readOnlyTransportistaAMDSL}" pInputText
                                formControlName="transportistaam" appendTo="body" />
                            <label>Transportista (A/M)</label>
                        </span>
                    </div>
                    <div class="card flex justify-content-center a-example-full-width-alterno-content-3">
                        <span class="p-float-label">
                            <input [ngClass]="{'readonly': readOnlyAlmacenDSL}" pInputText formControlName="almacen"
                                appendTo="body" />
                            <label>Almacén</label>
                        </span>
                    </div>
                </div>

                <div class="a-example-full-width-alterno-content-row">
                    <div class="card flex justify-content-center a-example-full-width-alterno-content-3">
                        <span class="p-float-label">
                            <input [ngClass]="{'readonly': readOnlyAgenciaMaritimaDSL}" pInputText
                                formControlName="agencia" appendTo="body" />
                            <label>Agencia marítima</label>
                        </span>
                    </div>
                    <div class="card flex justify-content-center a-example-full-width-alterno-content-3">
                        <span class="p-float-label">
                            <input [ngClass]="{'readonly': readOnlyTerminalPortuarioDSL}" pInputText
                                formControlName="terminal" appendTo="body" />
                            <label>Terminal portuario</label>
                        </span>
                    </div>
                    <div class="card flex justify-content-center a-example-full-width-alterno-content-3">
                        <span class="p-float-label">
                            <input [ngClass]="{'readonly': readOnlyCondicionEmbarqueDSL}" pInputText
                                formControlName="condicion" appendTo="body" />
                            <label>Condición embarque</label>
                        </span>
                    </div>
                    <div class="card flex justify-content-center a-example-full-width-alterno-content-3">
                        <span class="p-float-label">
                            <input [ngClass]="{'readonly': readonlyTransportistaDSL}" pInputText
                                formControlName="transportista" appendTo="body" />
                            <label>Transportista</label>
                        </span>
                    </div>

                    <div class="InputSwitch a-example-full-width-alterno-content-3">
                        <p-inputSwitch [ngClass]="{'readonly': readonlySADADSL}" formControlName="sada" />SADA
                    </div>

                    <div class="InputSwitch a-example-full-width-alterno-content-3">
                        <p-inputSwitch [ngClass]="{'readonly': readonlyIMODSL}" formControlName="imo" />IMO
                    </div>
                </div>

                <div class="a-example-full-width-alterno-content-row">
                    <div class="a-example-full-width-alterno-content-3">
                        <span class="p-float-label">
                            <p-dropdown [ngClass]="{'readonly': readonlyTarifaDSL}" [options]="ListaTodosTarifa"
                                placeholder="‎ " optionLabel="nombre" formControlName="tarifa" optionValue="codigo"
                                appendTo="body" class="p-inputtext-sm">
                            </p-dropdown>
                            <label for="float-label">Tarifa</label>
                        </span>
                    </div>
                    <div class="card flex justify-content-center a-example-full-width-alterno-content-3">
                        <span class="p-float-label">
                            <input [ngClass]="{'readonly': readonlyTipoDSL}" pInputText formControlName="tipo"
                                appendTo="body" />
                            <label>Tipo</label>
                        </span>
                    </div>
                    <div class="p-float-label a-example-full-width-alterno-content-3">
                        <p-calendar [ngClass]="{'readonly': readonlyVigenciaDesdeDSL}" formControlName="vigenciadesde"
                            appendTo="body" [showIcon]="true" [showWeek]="false" dateFormat="dd/mm/yy">
                        </p-calendar>
                        <label for="float-label">Vigencia desde</label>
                    </div>

                    <div class="p-float-label a-example-full-width-alterno-content-3">
                        <p-calendar [ngClass]="{'readonly': readonlyVigenciaHastaDSL}" formControlName="vigenciahasta"
                            appendTo="body" [showIcon]="true" [showWeek]="false" dateFormat="dd/mm/yy">
                        </p-calendar>
                        <label for="float-label">Vigencia hasta</label>
                    </div>

                </div>

                <div class="a-example-full-width-alterno-content-row">
                    <p-tabView class="a-example-full-width-alterno-content"> <!--[scrollable]="true"-->
                        <p-tabPanel header="Descripcion">
                            <!-- <h1 formControlName="descripcion" [ngClass]="{'readonly': readonlyDescripcionDSL}">2321321</h1> -->


                            <textarea [ngClass]="{'readonly': readonlyDescripcionDSL}" id="float-input" rows="5"
                                cols="30" pInputTextarea formControlName="descripcion" style="width: 100%;"></textarea>

                        </p-tabPanel>
                        <p-tabPanel header="Observaciones">
                            <!-- <textarea [ngClass]="{'readonly': readonlyObservacionesDSL}" id="float-input" rows="5"
                                cols="30" pInputTextarea formControlName="observaciones"
                                style="width: 100%;"></textarea> -->
                            <div class="a-example-full-width-alterno-content">
                                <app-datatable [dataTable]="servicioslocales_dataObservaciones"
                                    [metadataTable]="metadatableDataObservaciones" [filter]="true" [addRow]="true"
                                    [rowEdit]="true">
                                </app-datatable>
                            </div>

                        </p-tabPanel>
                    </p-tabView>


                </div>
                <div class="a-example-full-width-alterno-content-row">
                    <div class="a-example-full-width-alterno-content">
                        <app-datatable [dataTable]="servicioslocales_dataservicios"
                            [metadataTable]="metadatableDataServicios" [filter]="true" [addRow]="true" [rowEdit]="true">
                        </app-datatable>
                    </div>
                </div>
            </div>
            <!-- <div class="formularios_aling_general"> -->


            <div class="titulo_secciones">

            </div>
            <div class="a-example-full-width-alterno-content">
                <app-datatable [dataTable]="servicioslocales_datadetalle" [metadataTable]="metadatableDataDetalle"
                    [filter]="true" [addRow]="true" [rowEdit]="true">
                </app-datatable>
            </div>
        </form>
    </p-accordionTab>
</p-accordion>





<button mat-icon-button [matMenuTriggerFor]="menu2" class="boton_menu">
    <mat-icon>more_vert</mat-icon>
</button>
<mat-menu #menu2="matMenu">
    <button *ngFor="let item of list_menu" (click)="operaciones_menu(item.text)" class="items_menu">
        <div><mat-icon>{{item.icon}}</mat-icon></div>
        <div><span>{{item.text}}</span></div>
    </button>
</mat-menu>

<div style="height: 60px;"></div>


<style>
    ::ng-deep p-dropdown.readonly .p-dropdown-trigger,
    ::ng-deep p-dropdown.readonly .p-dropdown-label {


        position: relative;
        pointer-events: none;
        opacity: 0.5;
        user-select: none;
        background-color: #f0f0f0;
        /* content: none;  */
        /* color: black;   
        font-weight: 600; */
    }

    /* ::ng-deep p-dropdown.readonly .p-dropdown-label{
        position: relative;
        background-color: #f0f0f0;
    }

    ::ng-deep p-dropdown.readonly .p-dropdown-trigger{
        background-color: #f0f0f0;
    } */


    .readonly {
        position: relative;
        pointer-events: none;
        opacity: 0.5;
        user-select: none;
        background-color: #f0f0f0;
    }




    .checkbox {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .radiobutton {
        display: flex;
        justify-content: space-around;
        align-items: center;

    }

    .checkbox>label {
        padding-left: 5px;
    }

    .radiobutton>label {
        padding-left: 5px;
    }


    .InputSwitch {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .InputSwitch>p-inputSwitch {
        margin-right: 5px;
    }

    p-tabView>p-tabPanel {
        width: 500px;
    }

    input {
        width: 100%;
        /* height: 47px; */
    }

    :host ::ng-deep .p-dropdown {
        width: 100%;
        /* height: 47px; */
    }

    :host ::ng-deep .p-calendar {
        width: 100%;
        /* height: 47px; */
    }

    :host ::ng-deep .p-inputnumber {
        width: 100%;
    }

    :host ::ng-deep p-inputnumber,
    .p-inputnumber {
        width: 100%;
    }

    :host ::ng-deep .p-fieldset {
        padding: 0px !important;
    }

    :host ::ng-deep .p-fieldset-legend {
        padding: 0px !important;
        background-color: transparent;
        border: none;
        padding: 5px;
        margin-left: 10px;
    }

    :host ::ng-deep .p-tabview .p-tabview-panels {
        padding: 5px;
    }

    :host ::ng-deep .p-tabview .p-tabview-nav li .p-tabview-nav-link {
        padding: 7px;
    }

    :host ::ng-deep .p-inputtext-sm .p-inputtext {
        width: 100;
    }

    ::ng-deep .p-overlay {
        width: 100 !important;
    }



    /* :host ::ng-deep .p-dropdown-panel .p-dropdown-items{
        width: 100vw !important;
        
    } */
    /* overflow-x: auto; 
        white-space: nowrap; */



    /* .formularios_aling{
        min-height: 47px;
        max-height: 47px;
    } */



    /* Ocultar el label por defecto */
    /* .p-float-label label {
    display: none; 
}


.p-dropdown.p-float-label .p-dropdown-label.p-dropdown-label-active + label {
    display: block; 
} */
</style>