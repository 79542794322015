import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DatosCompartidosService {
  private tablaDatos: any[] = [];

  constructor() { }

  setTablaDatos(datos: any[]) {
    this.tablaDatos = datos;
  }

  getTablaDatos() {
    return this.tablaDatos;
  }
}
