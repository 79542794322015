<div class="fab-container">
    <button mat-fab class="button1" matTooltip="Salir" matTooltipPosition="left" (click)="salir()">
        <mat-icon>exit_to_app</mat-icon>
    </button>
    <!-- <button mat-raised-button class="button1" (click)="verdato()">Ver Dato</button> -->
<!-- <button mat-raised-button class="button1" (click)="refresh()">Refrescar data</button> -->
    <button mat-fab class="button1" matTooltip="Guardar" matTooltipPosition="left" (click)="transaccionGantt()">
        <mat-icon>save</mat-icon>
    </button>
    <!-- <button mat-raised-button class="button1" (click)="listarRecursosGantt()">Ver</button> -->
</div>
<br><br>
<div #gantt_here class='gantt-chart'></div>
<footer>
    <div class="contenido-pie">
      <p></p>
    </div>
</footer>

