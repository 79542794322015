import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GestionProyectosRepository } from '../gestion-proyectos/domain/gestionProyectos-repository';
import { ActualizarEstadoRequest, ActualizarProyectoRequest, ActualizarTareaRequest, AdjuntarArchivoRequest, AprobarProyectoRequest, InsertarNuevoProyectoRequest, InsertarTareaRequest, ListarProyectosRequest, ListarRecursoGanttRequest, ListarRecursoRequest, ListarTareaRequest, ListarTareasGanttRequest, quitarRecursoRequest, TransaccionTareaGanttRequest } from '../gestion-proyectos/domain/request/gestionProyectos-request';
import { ActualizarEstadoResponse, ActualizarProyectoResponse, ActualizarTareaResponse, AdjuntarArchivoResponse, AprobarProyectoResponse, InsertarNuevoProyectoResponse, InsertarTareaResponse, ListarProyectosResponse, ListarRecursoResponse, ListarRecursosGanttResponse, ListarTareaResponse, ListarTareasGanttResponse, quitarRecursoResponse } from '../gestion-proyectos/domain/response/gestionProyectos-response';
import { listarAreaRequest } from '../soporte/domain/request/soporte_request';
import { ListarAreaResponse } from '../soporte/domain/response/soporte_response';

@Injectable()
export class GestionProyectosService extends GestionProyectosRepository {

  constructor(private readonly http: HttpClient

  ) {
    super();
  }

  insertarNuevoProyecto(prolrequest: InsertarNuevoProyectoRequest): Observable<InsertarNuevoProyectoResponse> {
    return this.http.post<InsertarNuevoProyectoResponse>(`${environment.PATH_API}/proyectos/InsertarNuevoProyecto/`, prolrequest);
  }
  listarProyectos(prolrequest: ListarProyectosRequest): Observable<ListarProyectosResponse> {
    return this.http.post<ListarProyectosResponse>(`${environment.PATH_API}/proyectos/ListarProyectos/`, prolrequest);
  }
  listarAreaPorUsuario(prolrequest: listarAreaRequest): Observable<ListarAreaResponse> {
    return this.http.post<ListarAreaResponse>(`${environment.PATH_API}/soporte/ListarAreaPorUsuario/`, prolrequest);
  }
  aprobarProyecto(prolrequest: AprobarProyectoRequest): Observable<AprobarProyectoResponse> {
    return this.http.post<AprobarProyectoResponse>(`${environment.PATH_API}/proyectos/AprobarProyecto/`, prolrequest);
  }
  listarTarea(prolrequest: ListarTareaRequest): Observable<ListarTareaResponse> {
    return this.http.post<ListarTareaResponse>(`${environment.PATH_API}/proyectos/ListarTarea/`, prolrequest);
  }
  insertarTarea(prolrequest: InsertarTareaRequest): Observable<InsertarTareaResponse> {
    return this.http.post<InsertarTareaResponse>(`${environment.PATH_API}/proyectos/InsertarNuevaTarea/`, prolrequest);
  }
  actualizarTarea(prolrequest: ActualizarTareaRequest): Observable<ActualizarTareaResponse> {
    return this.http.post<ActualizarTareaResponse>(`${environment.PATH_API}/proyectos/ActualizarTarea/`, prolrequest);
  }
  actualizarEstado(prolrequest: ActualizarEstadoRequest): Observable<ActualizarEstadoResponse> {
    return this.http.post<ActualizarEstadoResponse>(`${environment.PATH_API}/proyectos/ActualizarEstado/`, prolrequest);
  }
  listarRecurso(prolrequest: ListarRecursoRequest): Observable<ListarRecursoResponse> {
    return this.http.post<ListarRecursoResponse>(`${environment.PATH_API}/proyectos/ListarRecurso/`, prolrequest);
  }
  listarTareasGantt(prolrequest: ListarTareasGanttRequest): Observable<ListarTareasGanttResponse> {
    return this.http.post<ListarTareasGanttResponse>(`${environment.PATH_API}/proyectos/ListarTareasGantt/`, prolrequest);
  }
  TransaccionTareaGantt(prolrequest: TransaccionTareaGanttRequest): Observable<ActualizarTareaResponse> {
    return this.http.post<ActualizarTareaResponse>(`${environment.PATH_API}/proyectos/TransaccionTareaGantt/`, prolrequest);
  }
  adjuntarArchivo(prolrequest: AdjuntarArchivoRequest): Observable<AdjuntarArchivoResponse> {
    return this.http.post<AdjuntarArchivoResponse>(`${environment.PATH_API}/proyectos/AdjuntarArchivo/`, prolrequest);
  }
  actualizarProyecto(prolrequest: ActualizarProyectoRequest): Observable<ActualizarProyectoResponse> {
    return this.http.post<ActualizarProyectoResponse>(`${environment.PATH_API}/proyectos/ActualizarProyecto/`, prolrequest);
  }
  listarRecursosGantt(prolrequest: ListarRecursoGanttRequest): Observable<ListarRecursosGanttResponse> {
    return this.http.post<ListarRecursosGanttResponse>(`${environment.PATH_API}/proyectos/ListarRecursosGantt/`, prolrequest);
  }
  quitarRecurso(prolrequest: quitarRecursoRequest): Observable<quitarRecursoResponse> {
    return this.http.post<quitarRecursoResponse>(`${environment.PATH_API}/proyectos/QuitarRecurso/`, prolrequest);
  }
}