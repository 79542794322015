
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PageLoginComponent } from "./core/presentacion/page-login/page-login.component";
import { PageNewProformaComponent } from "./proforma/presentacion/page-new-proforma/page-new-proforma.component";
import { RequerimientomantComponent } from "./requerimiento/presentacion/components/requerimientomant/requerimientomant.component";
import { ClienteComponent } from "./cliente/presentacion/components/cliente/cliente.component";
import { VisitaclienteComponent } from "./visitascliente/presentacion/components/visitacliente/visitacliente.component";
import { GestionvisitasComponent } from "./gestionvisitas/presentacion/components/gestionvisitas/gestionvisitas.component";
import { PageNewPromocionesAPEC } from './promociones_apec/presentacion/page-new-proforma/page-new-proforma.component';
import { VizorEmergenteComponent } from './ocr_pdf_2/presentacion/vizorEmergente/vizorEmergente.component';
import { BandejaTareasComponent } from './gestion-proyectos/presentacion/bandeja-tareas/bandeja-tareas.component';
import { NuevaReservaComponent } from './reservas/reservas/presentacion/nueva-reserva/nueva-reserva.component';
import { NuevoServicioRefferComponent } from './comercial/reffer/r-servicios/presentacion/nuevo-servicio-reffer/nuevo-servicio-reffer.component';
import { MantenimientoOrdenVentaComponent } from './comercial/orden-venta/presentacion/mantenimiento-orden-venta/mantenimiento-orden-venta.component';
import { NuevoItinerarioRefferComponent } from "./comercial/reffer/itinerarios/presentacion/nuevo-itinerario-reffer/nuevo-itinerario-reffer.component";
import { VisualizarGanttComponent } from "./gestion-proyectos/presentacion/visualizar-gantt/visualizar-gantt.component";
// import { CambiarPassComponent } from "./cambiar-pass/presentacion/cambiar-pass.component";
// import {LoginComponent} from "./core/presentacion/components/login/login.component"
const routes: Routes = [
  { path: '', component: PageLoginComponent },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: "gestionpartes",
    loadChildren: () => import("./gestionpartes/gestionpartes.module").then(m => m.GestionpartesModule)

  },

  {
    path: "Menu",
    loadChildren: () => import("./menus/mante-menu.module").then(m => m.ManteMenuModule)
  },
  {
    path: "Roles",
    loadChildren: () => import("./roles/mante-roles.module").then(m => m.ManteRolesModule)
  },
  {
    path: "Proforma",
    loadChildren: () => import("./proforma/proforma.module").then(m => m.ProformaModule)
  },
  {
    path: "ProyeccionCuotas",
    loadChildren: () => import("./proyeccion-cuotas/reporte.module").then(m => m.ProyeccionCuotasModule) //REPORTES
  },
  {
    path: "EfectividadVentas",
    loadChildren: () => import("./efectividad-ventas/efectividad-ventas.module").then(m => m.ReporteEfectividadVModule) //EFECTIVIDAD DE VENTAS
  },
  {
    path: "EfectividadVisita",
    loadChildren: () => import("./efectividad-visitas/efectividad-visitas.module").then(m => m.EfectividadVisitaModule) //EFECTIVIDAD DE VENTAS
  },
  {
    path: "AvanceVentas",
    loadChildren: () => import("./reporte-avanceV/avance-ventas.module").then(m => m.AvanceVentasModule) //EFECTIVIDAD DE VENTAS
  },
  {
    path: "CotizacionesCerradas",
    loadChildren: () => import("./reporte-cotizaciones-cerradas_v2/r-coti-cerrada.module").then(m => m.RCotiCerradaModule) //EFECTIVIDAD DE VENTAS
  },
  {
    path: "dashboardrentable",
    loadChildren: () => import("./dashboard-comercial-rentabilidad/comercial-rentabilidad.module").then(m => m.DashBoardRentabilidadComercialModule) //RENTABILIDAD COMERCIAL
  },
  {
    path: "ConciliadoOrigen",
    loadChildren: () => import("./dashboard-provicion-origen/dashboard-provicion-origen.module").then(m => m.DashBoardProvicionOrigenModule) //RENTABILIDAD COMERCIAL
  },
  {
    path: "Comisiones",
    loadChildren: () => import("./comisiones/comisiones.module").then(m => m.ComisionesModule) //RENTABILIDAD COMERCIAL
  },
  {
    path: "ClientesProfit",
    loadChildren: () => import("./reporte-cliente-profit/reporte-cliente-profit.module").then(m => m.ClienteProfitModule) //REPORTE CLIENTE PROFIT
  },
  {
    path: "ovxversionar",
    loadChildren: () => import("./ovPorVersionar/ovPorVersionar.module").then(m => m.ovPorVersionarModule) //REPORTE CLIENTE PROFIT
  },
  {
    path: "fasesxov",
    loadChildren: () => import("./fasesPorOv/fasesPorOv.module").then(m => m.FasesPorOvModule) //REPORTE CLIENTE PROFIT
  },
  {
    path: "ResumenMensualComercial",
    loadChildren: () => import("./ResumenMenusalComercial/resumenMenusualComercial.module").then(m => m.ResumenMensualComercialModule) //REPORTE CLIENTE PROFIT
  },
  {
    path: "controlFletamento",
    loadChildren: () => import("./control-fletamento/control-fletamento.module").then(m => m.ControlFletamentoModule) //EFECTIVIDAD DE VENTAS
  },
  {
    path: "ReporteContenedorAlmacen",
    loadChildren: () => import("./reporte-ctnr-almacen/reporte-ctnr-almacen.module").then(m => m.ReporteCtnrAlmacenModule) //EFECTIVIDAD DE VENTAS
  },
  {
    path: 'NuevaProforma',
    component: PageNewProformaComponent,
  },
  {
    path: 'NuevoRequerimiento',
    component: RequerimientomantComponent
  },
  {
    path: 'NuevoCliente',
    component: ClienteComponent
  },
  {
    path: 'vizorEmergente',
    component: VizorEmergenteComponent
  },
  //PROMOCIOENS APEC
  {
    path: 'NuevaPromocionesAPEC',
    component: PageNewPromocionesAPEC,
  },
  // {
  //    path: 'Temporal',
  //   component: RCotiCerradaComponent
  // },
  {
    path: 'NuevaProgramacion',
    component: VisitaclienteComponent
  },

  {
    path: 'NuevaVisita',
    component: GestionvisitasComponent
  },
  {
    path: "dashboardrentableexcomar",
    loadChildren: () => import("./dashboard-gestion-excomar/gestion-excomar.module").then(m => m.GestionExcomarModule)
  },
  {
    path: "dashboardrentableexcomar",
    loadChildren: () => import("./dashboard-gestion-excomar/gestion-excomar.module").then(m => m.GestionExcomarModule)
  },

  {
    path: "rentabilitaxlineanegocio",
    loadChildren: () => import("./reporte-rentabilidad-lnegocio/reporte-rentabilidad-lnegocio.module").then(m => m.ReporteRentabilidadLnegocioModule)
  },
  {
    path: "mantenimientoServicios",
    loadChildren: () => import("./mantenimiento_servicios/mantenimiento_servicio.module").then(m => m.MantenimientosServicioModule)
  },
  //MantenimientoServicioRoutingModule

  {

    path: "Requerimiento",
    loadChildren: () => import("./requerimiento/requerimiento.module").then(m => m.RequerimientoModule)

  },
  {

    path: "MantClientes",
    loadChildren: () => import("./cliente/cliente.module").then(m => m.ClienteModule)

  },
  {

    path: "Visitas",
    loadChildren: () => import("./visitascliente/visitascliente.module").then(m => m.VisitasclienteModule)

  },

  {

    path: "GestionVisitas",
    loadChildren: () => import("./gestionvisitas/gestionvisitas.module").then(m => m.GestionvisitasModule)

  },
  {
    path: "clientesefectivos",
    loadChildren: () => import("./clientes-efectivos/cliente-efectivo.module").then(m => m.ClientesEfectivosModule)
  },
  {
    path: "SemaforoVigencia",
    loadChildren: () => import("./bandeja-alerta-documento/alerta-documento.module").then(m => m.AlertaDocumentoModule)
  },
  // { path: 'emisor', component: LoginComponent
  // },
  // { path: 'receptor', component: CambiarPassComponent
  // },
  {
    path: "CotizacionesAvanzadas",
    loadChildren: () => import("./cotizaciones_resumentes/pre_res.module").then(m => m.PreseResuModule)
  },
  //dashboardventas
  {
    path: "",
    loadChildren: () => import("./dashboardVentas/dashboard-ventas.module").then(m => m.DashBoardVentasModule)
  },
  {
    path: "SeguimientoRuta",
    loadChildren: () => import("./seguimientoRuta/seguimiento_ruta.module").then(m => m.SeguimientoRutaModule)
  },
  {
    path: "dashboardPrincing",
    loadChildren: () => import("./dashboard-pricing/dashboard-pricing.module").then(m => m.DashBoardPricingModule)
  },//seguimientoRuta
  {
    path: "seguimientoRuta",
    loadChildren: () => import("./seguimientoRuta/seguimiento_ruta.module").then(m => m.SeguimientoRutaModule)
  },

  {
    path: "ClientesServLocales",
    loadChildren: () => import("./reporteservicioslocales/reporteservicioslocales.module").then(m => m.ReporteservicioslocalesModule)
  },
  {
    path: "importexcomar",
    loadChildren: () => import("./excomar/excomar.module").then(m => m.BandExcomarModule)
  },
  {
    path: "ImportarComisiones",
    loadChildren: () => import("./importar-comisiones/importar-comisiones.module").then(m => m.ImportarComisionesModule)
  },
  {
    path: "",
    loadChildren: () => import("./comisiones-servicios/comisiones-servicios.module").then(m => m.ComisionesServiciosModule)
  },
  {
    path: "importarstatement",
    loadChildren: () => import("./statement-carrier/statement-carrier.module").then(m => m.StatementCarrierModule)
  },
  {
    path: "rentxejecutivo",
    loadChildren: () => import("./reporte-rentaxejecutivo/reporte-rentaxejecutivo.module").then(m => m.ReporteRentaxejecutivoModule)
  },
  {
    path: "bandejastatement",
    loadChildren: () => import("./bandeja-statement/bandeja-statement.module").then(m => m.BandejaStatementModule)
  },
  {
    path: "opebandejastatement",
    loadChildren: () => import("./bandeja-statement/bandeja-statement.module").then(m => m.BandejaStatementModule)
  },
  {
    path: "reportegcchina",
    loadChildren: () => import("./reporte-gc-china/reporte-gc-china.module").then(m => m.ReporteGcChinaModule)
  },
  {
    //traking
    path: "traking",
    loadChildren: () => import("./tracking/tracking.module").then(m => m.TrackingModule)

  },

  {
    path: "dashboardventas",
    loadChildren: () => import("./dashboard-teus-cerrados/dashboard-teus-cerrados.module").then(m => m.DashboardTeusCerradosModule)
  },
  {
    //gestioncomercialtv
    path: "gestioncomercialtv",
    loadChildren: () => import("./dashboard-pricing-comercial/dashboard-pricing-comercial.module").then(m => m.DashboardPricingComercialModule)
  },
  {
    //semaforoporlinea
    path: "semaforoporlinea",
    loadChildren: () => import("./reporte-uno/reporte-uno.module").then(m => m.ReporteUnoModule)
  },
  {
    //semaforoporlinea
    path: "comisionesseguro",
    loadChildren: () => import("./dashboard-comisiones-seguros/dashboard-comisiones-seguros.module").then(m => m.DashboardComisionesSegurosModule)
  },

  {
    path: "ProvisionOrigen",
    loadChildren: () => import("./dashboard-provicion/dashboard-provicion.module").then(m => m.DashBoardProvicionModule)
  },
  //traking -- para pruebas
  {
    //rptserviciolocales
    path: "rptserviciolocales",
    loadChildren: () => import("./reporte-servicios-locales/reporte-servicios-locales.module").then(m => m.ReporteServiciosLocalesModule)
  },
  {
    path: "comisionbr",
    loadChildren: () => import("./comision-broker/comision-broker.module").then(m => m.ComisionBrokerModule)
  },

  {
    path: "comisionbrr",
    loadChildren: () => import("./reporte-comision-broker/reporte-comision-broker.module").then(m => m.ReporteComisionBrokerModule)
  },
  {
    path: "comisionbrphbl",
    loadChildren: () => import("./reporte-comision-broker-hbl/reporte-comision-broker-hbl.module").then(m => m.ReporteComisionBrokerHblModule)
  },
  {
    path: "comisionbrestadopago",
    loadChildren: () => import("./comision-broker-hbl-pagadas/comision-broker-hbl-pagadas.module").then(m => m.ComisionBrokerHblPagadasModule)
  },
  {
    path: "validacionBL",
    loadChildren: () => import("./ocr_pdf_2/ocrPdf2.module").then(m => m.ocrPdf2Module)
  },
  {
    path: "ContenedorTerminar",
    loadChildren: () => import("./contenedor_por_terminar/contenedorTerminar.module").then(m => m.ContenedorTerminarModule)
  },
  {
    path: "reporteclientecontacto",
    loadChildren: () => import("./reporte-cliente-contacto/reporte-cliente-contacto.module").then(m => m.ReporteClienteContactoModule)
  },
  {
    path: "gestionsli",
    loadChildren: () => import("./dashboard-gestion-sli/dashboard-gestion-sli.module").then(m => m.DashboardGestionSliModule)
  },
  {
    path: "PromocionesAPEC",
    loadChildren: () => import("./promociones_apec/proforma.module").then(m => m.PromocionesApecModule)
  },
  {
    path: "reporteacos",
    loadChildren: () => import("./reporte-acos/reporte-acos.module").then(m => m.ReporteAcosModule)
  },

  {
    path: "comisionbandeja",
    loadChildren: () => import("./comisiones/comisiones.module").then(m => m.ComisionesModule)
  },

  {
    path: "bandejaSoporte",
    loadChildren: () => import("./soporte/soporte.module").then(m => m.SoporteModule)
  },

  {
    path: "",
    loadChildren: () => import("./reporte-ventas/reporte-ventas.module").then(m => m.ReporteVentasModule)
  },
  // {
  //   path: "vizorEmergente",
  //   loadChildren: () => import("./reporte-ventas/reporte-ventas.module").then(m => m.ReporteVentasModule)
  // },

  {
    path: "reporteventas",
    loadChildren: () => import("./reporte-venta/reporte-venta.module").then(m => m.ReporteVentaModule)
  },

  {
    path: "gestionSalas",
    loadChildren: () => import("./matenimiento_salas/mante-salas.module").then(m => m.salasModule)
  },
  {
    path: "mantenimientoSalas",
    loadChildren: () => import("./administracion_salas/adm-salas.module").then(m => m.mantSalasModule)
  },
  {
    path: "dashboardsoporte",
    loadChildren: () => import("./dashboard-soporte/dashboard-soporte.module").then(m => m.DashboardSoporteModule)
  },
  {
    path: "ciclodeembarque",
    loadChildren: () => import("./reporte-ciclo-embarque/reporte-ciclo-embarque.module").then(m => m.ReporteCicloEmbarqueModule)
  },
  {
    path: "clientespreferenciales",
    loadChildren: () => import("./mantenimientos/mantenimientos.module").then(m => m.MantenimientosModule)
  },

  {
    path: "bandejacobranza",
    loadChildren: () => import("./finanzas/cobranzas/cobranzas.module").then(m => m.CobranzasModule)
  },
  {
    path: "gestionProyectos",
    loadChildren: () => import("./gestion-proyectos/gestion-proyectos.module").then(m => m.gestionProyectosModule)
  },
  {
    path: 'bandejaTareas',
    component: BandejaTareasComponent,
  },

  {
    path: "",
    loadChildren: () => import("./perfil/perfil.module").then(m => m.PerfilModule) //EFECTIVIDAD DE VENTAS
  },
  {
    path: "reserva",
    loadChildren: () => import("./reservas/reservas/reservas.module").then(m => m.ReservasModule) //EFECTIVIDAD DE VENTAS
  },

  {
    path: "BandejaClientesR",
    loadChildren: () => import("./comercial/reffer/aprobacion-clientes/aprobacion-clientes.module").then(m => m.AprobacionClientesModule) //EFECTIVIDAD DE VENTAS
  },
  {
    path: "BandejaItinerarios",
    loadChildren: () => import("./perfil/perfil.module").then(m => m.PerfilModule) //EFECTIVIDAD DE VENTAS
  },
  {
    path: 'NuevaReserva',
    component: NuevaReservaComponent
  },
  {
    path: "itinerarios",
    loadChildren: () => import("./comercial/reffer/itinerarios/itinerarios.module").then(m => m.ItinerariosModule) //EFECTIVIDAD DE VENTAS
  },
  // {
  //   path: "servicios",
  //   // loadChildren: () => import("./comercial/reffer/r-servicios/r-servicios.module").then(m => m.RServiciosModule) //EFECTIVIDAD DE VENTAS
  //   loadChildren: () => import("./comercial-orden-venta/comercial-orden-venta.module").then(m => m.ComercialOrdenVentaModule) //EFECTIVIDAD DE VENTAS
  // },
  {
    path: "servicios",
    loadChildren: () => import("./comercial/reffer/r-servicios/r-servicios.module").then(m => m.RServiciosModule) //EFECTIVIDAD DE VENTAS

  },
  {
    path: 'comercialordenventa',
    loadChildren: () => import("./comercial/orden-venta/orden-venta.module").then(m => m.OrdenVentaModule)
  },
  {
    path: "maintenance",
    loadChildren: () => import("./maintenance/maintenance.module").then(m => m.MaintenanceModule) //EFECTIVIDAD DE VENTAS
  },
  {
    path: "tracking-shipment",
    loadChildren: () => import("./comercial/tracking-shipment/tracking-shipment.module").then(m => m.TrackingShipmentModule) //EFECTIVIDAD DE VENTAS
  },
  {
    path: "reports",
    loadChildren: () => import("./reportes/reportes.module").then(m => m.ReportesModule) //EFECTIVIDAD DE VENTAS
  },
  {
    path: "cliente-años",
    loadChildren: () => import("./reportes/componentes/reporte-clientes-anio/reporte-clientes-anio.module").then(m => m.ReporteClientesAnioModule) //EFECTIVIDAD DE VENTAS
  },
  {
    path: 'NuevoServicio',
    component: NuevoServicioRefferComponent
  },
  {
    path: 'NuevoItinerario',
    component: NuevoItinerarioRefferComponent
  },
  {
    path: 'NuevaOrdenVenta',
    component: MantenimientoOrdenVentaComponent,
  },

  {

    path:'visualizarGantt',
    component : VisualizarGanttComponent,
  }



  // {
  //   //traking
  //   path: "comercialordenventa",
  //   loadChildren: () => import("./comercial-orden-venta/comercial-orden-venta.module").then(m => m.ComercialOrdenVentaModule)
  // }


  /* {
     path: 'NuevaOrdenVenta',
     component: MantenimientoOrdenVentaComponent,
   },*/
  //no estan subiendo los cambios
];


//@ts-ignore
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})


export class AppRoutingModule {

}
