export interface NuevoPerfilClienteRequest {
    ruc: string
    empresa: string
    direccion: string
    telefono: string
    correo: string[]
    facturacion: string
    shipper: NuevoPerfilShipper[]
    commodity: any[]
}

export interface NuevoPerfilShipper {
    ruc: string
    empresa: string
    direccion: string
}

export interface ListarNuevosClientesRequest {
    nombre: string
    fecha: string
}

export interface UpdateNuevosClienteRequest {
    codigo: number
    usuario: string
    tipo: string
}

export interface ListarDropdownRequest {
    tipo: string
    cliente: string
    shipper: string
}

export interface NuevaReservaRequest {
    shipper
    origen
    destino
    via
    naviera
    semana
    nave
    commodity
    neto
    temperatura
    ventilacion
    humedad
    consignatario
    remarks
    flete
    reseteotemp
    atmcontrolada
    coldtreatment
    pagadero
    restemperatura
    cantidad
    tecnologia
    o2
    co2
    estado
    tipotransaccion
    audi_usrcrea
    sli
    skids
    contenedores
    booking
    emisionbl
    tipo
    codigo
    itinerario
}

export interface ListarNuevasReservaRequest {
    anio: number
    semana: number
    tipo: string
    usuario: string
}

export interface InsertServicioRefferRequest {
    nombre: string
    origen: number
    navieras: any[]
    usuario: string
    tipo: string
    servicio: number
    servnave: number
}

export interface ListarServiciosRefferRequest {
    codigo: number
}

export interface InsertItinerarioRefferRequest {
    codigo: number
    anio: number
    semana: number
    naviera: number
    origen: number
    destino: number
    transito: number
    etd: Date
    zarpe: Date
    arribo: Date
    plazo: string
    nave: number
    usuario: string
    tipo: string
    itinerario: number
    estado: string
    viaje: string
}

export interface ListarItinerariosRefferRequest {
    origen
    destino
    semana
}

export interface updateReservaRefferRequest {
    reserva: number
    tipo: string
    usuario: string
    comentario: string
}

export interface updatePasswordRefferRequest {
    antiguo: string
    nuevo: string
    codigo: number
}

export interface BuscarNavesItinerarioRequest {
    origen: number
    destino: number
    naviera: number
    semana: number
}

export interface ListarNavesRequest {
    pintENTC_CodTransportista: number
}

export interface operacionesNaveRequest {
    nombre: string
    usuario: string
    via: string
    matricula: string
    emisora: string
    negocio: string
    tipo: string
}

export interface transbordoReservaRequest {
    reserva: number
    puerto: number
    eta: Date
    trans_eta: Date
    trans_etd: Date
    nave: number
    viaje: string
    eta_final: Date
    usuario: string
}

export interface transbordoTrackingRequest {
    reserva: number
}

export const ListaSemana: any[] = [
    { codigo: 1 },
    { codigo: 2 },
    { codigo: 3 },
    { codigo: 4 },
    { codigo: 5 },
    { codigo: 6 },
    { codigo: 7 },
    { codigo: 8 },
    { codigo: 9 },
    { codigo: 10 },
    { codigo: 11 },
    { codigo: 12 },
    { codigo: 13 },
    { codigo: 14 },
    { codigo: 15 },
    { codigo: 16 },
    { codigo: 17 },
    { codigo: 18 },
    { codigo: 19 },
    { codigo: 20 },
    { codigo: 21 },
    { codigo: 22 },
    { codigo: 23 },
    { codigo: 24 },
    { codigo: 25 },
    { codigo: 26 },
    { codigo: 27 },
    { codigo: 28 },
    { codigo: 29 },
    { codigo: 30 },
    { codigo: 31 },
    { codigo: 32 },
    { codigo: 33 },
    { codigo: 34 },
    { codigo: 35 },
    { codigo: 36 },
    { codigo: 37 },
    { codigo: 38 },
    { codigo: 39 },
    { codigo: 40 },
    { codigo: 41 },
    { codigo: 42 },
    { codigo: 43 },
    { codigo: 44 },
    { codigo: 45 },
    { codigo: 46 },
    { codigo: 47 },
    { codigo: 48 },
    { codigo: 49 },
    { codigo: 50 },
    { codigo: 51 },
    { codigo: 52 },
    { codigo: 53 }
]
